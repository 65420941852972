var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-lighter rounded-md py-3 px-4"
  }, [_vm.$slots.title ? _c('h5', {
    staticClass: "mb-1"
  }, [_vm._t("title")], 2) : _vm._e(), _c('p', {
    staticClass: "white-space mb-0",
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }