import moment from 'moment';

const halfHourlyConsumption = {
  accountId: '655fcf07b7d392001404536f',
  totalConsumption: 62261.399999999994,
  granularity: 'halfhourly',
  startDate: '2023-04-01T00:00:00.000Z',
  endDate: '2023-04-30T23:59:59.999Z',
  data: [
    {
      date: '2023-04-01T00:00:00.000Z',
      consumption: 26.3
    },
    {
      date: '2023-04-01T00:30:00.000Z',
      consumption: 26.6
    },
    {
      date: '2023-04-01T01:00:00.000Z',
      consumption: 28.3
    },
    {
      date: '2023-04-01T01:30:00.000Z',
      consumption: 26.5
    },
    {
      date: '2023-04-01T02:00:00.000Z',
      consumption: 26.5
    },
    {
      date: '2023-04-01T02:30:00.000Z',
      consumption: 29.4
    },
    {
      date: '2023-04-01T03:00:00.000Z',
      consumption: 28.7
    },
    {
      date: '2023-04-01T03:30:00.000Z',
      consumption: 27
    },
    {
      date: '2023-04-01T04:00:00.000Z',
      consumption: 27.2
    },
    {
      date: '2023-04-01T04:30:00.000Z',
      consumption: 26.8
    },
    {
      date: '2023-04-01T05:00:00.000Z',
      consumption: 23.7
    },
    {
      date: '2023-04-01T05:30:00.000Z',
      consumption: 27.3
    },
    {
      date: '2023-04-01T06:00:00.000Z',
      consumption: 27.5
    },
    {
      date: '2023-04-01T06:30:00.000Z',
      consumption: 25.9
    },
    {
      date: '2023-04-01T07:00:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-01T07:30:00.000Z',
      consumption: 25.6
    },
    {
      date: '2023-04-01T08:00:00.000Z',
      consumption: 27.4
    },
    {
      date: '2023-04-01T08:30:00.000Z',
      consumption: 25.6
    },
    {
      date: '2023-04-01T09:00:00.000Z',
      consumption: 29.8
    },
    {
      date: '2023-04-01T09:30:00.000Z',
      consumption: 28
    },
    {
      date: '2023-04-01T10:00:00.000Z',
      consumption: 26.4
    },
    {
      date: '2023-04-01T10:30:00.000Z',
      consumption: 26.7
    },
    {
      date: '2023-04-01T11:00:00.000Z',
      consumption: 29
    },
    {
      date: '2023-04-01T11:30:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-01T12:00:00.000Z',
      consumption: 26.7
    },
    {
      date: '2023-04-01T12:30:00.000Z',
      consumption: 29.8
    },
    {
      date: '2023-04-01T13:00:00.000Z',
      consumption: 27.2
    },
    {
      date: '2023-04-01T13:30:00.000Z',
      consumption: 29
    },
    {
      date: '2023-04-01T14:00:00.000Z',
      consumption: 28.3
    },
    {
      date: '2023-04-01T14:30:00.000Z',
      consumption: 28.2
    },
    {
      date: '2023-04-01T15:00:00.000Z',
      consumption: 26.9
    },
    {
      date: '2023-04-01T15:30:00.000Z',
      consumption: 29.1
    },
    {
      date: '2023-04-01T16:00:00.000Z',
      consumption: 28.4
    },
    {
      date: '2023-04-01T16:30:00.000Z',
      consumption: 23.6
    },
    {
      date: '2023-04-01T17:00:00.000Z',
      consumption: 24.5
    },
    {
      date: '2023-04-01T17:30:00.000Z',
      consumption: 30.4
    },
    {
      date: '2023-04-01T18:00:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-01T18:30:00.000Z',
      consumption: 24.3
    },
    {
      date: '2023-04-01T19:00:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-01T19:30:00.000Z',
      consumption: 25.7
    },
    {
      date: '2023-04-01T20:00:00.000Z',
      consumption: 25.8
    },
    {
      date: '2023-04-01T20:30:00.000Z',
      consumption: 22.8
    },
    {
      date: '2023-04-01T21:00:00.000Z',
      consumption: 28.2
    },
    {
      date: '2023-04-01T21:30:00.000Z',
      consumption: 24.9
    },
    {
      date: '2023-04-01T22:00:00.000Z',
      consumption: 24.7
    },
    {
      date: '2023-04-01T22:30:00.000Z',
      consumption: 27.1
    },
    {
      date: '2023-04-01T23:00:00.000Z',
      consumption: 25.2
    },
    {
      date: '2023-04-01T23:30:00.000Z',
      consumption: 26.9
    },
    {
      date: '2023-04-02T00:00:00.000Z',
      consumption: 26.8
    },
    {
      date: '2023-04-02T00:30:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-02T01:00:00.000Z',
      consumption: 24.2
    },
    {
      date: '2023-04-02T01:30:00.000Z',
      consumption: 25.4
    },
    {
      date: '2023-04-02T02:00:00.000Z',
      consumption: 25.3
    },
    {
      date: '2023-04-02T02:30:00.000Z',
      consumption: 25.5
    },
    {
      date: '2023-04-02T03:00:00.000Z',
      consumption: 26.4
    },
    {
      date: '2023-04-02T03:30:00.000Z',
      consumption: 24.6
    },
    {
      date: '2023-04-02T04:00:00.000Z',
      consumption: 26
    },
    {
      date: '2023-04-02T04:30:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-02T05:00:00.000Z',
      consumption: 25.1
    },
    {
      date: '2023-04-02T05:30:00.000Z',
      consumption: 27.7
    },
    {
      date: '2023-04-02T06:00:00.000Z',
      consumption: 24
    },
    {
      date: '2023-04-02T06:30:00.000Z',
      consumption: 25.6
    },
    {
      date: '2023-04-02T07:00:00.000Z',
      consumption: 25.4
    },
    {
      date: '2023-04-02T07:30:00.000Z',
      consumption: 24.6
    },
    {
      date: '2023-04-02T08:00:00.000Z',
      consumption: 23.8
    },
    {
      date: '2023-04-02T08:30:00.000Z',
      consumption: 27.8
    },
    {
      date: '2023-04-02T09:00:00.000Z',
      consumption: 25.6
    },
    {
      date: '2023-04-02T09:30:00.000Z',
      consumption: 25.5
    },
    {
      date: '2023-04-02T10:00:00.000Z',
      consumption: 26.4
    },
    {
      date: '2023-04-02T10:30:00.000Z',
      consumption: 27.5
    },
    {
      date: '2023-04-02T11:00:00.000Z',
      consumption: 26.7
    },
    {
      date: '2023-04-02T11:30:00.000Z',
      consumption: 29.6
    },
    {
      date: '2023-04-02T12:00:00.000Z',
      consumption: 31.7
    },
    {
      date: '2023-04-02T12:30:00.000Z',
      consumption: 26.9
    },
    {
      date: '2023-04-02T13:00:00.000Z',
      consumption: 27.6
    },
    {
      date: '2023-04-02T13:30:00.000Z',
      consumption: 34.9
    },
    {
      date: '2023-04-02T14:00:00.000Z',
      consumption: 31.4
    },
    {
      date: '2023-04-02T14:30:00.000Z',
      consumption: 28.1
    },
    {
      date: '2023-04-02T15:00:00.000Z',
      consumption: 30.4
    },
    {
      date: '2023-04-02T15:30:00.000Z',
      consumption: 33.6
    },
    {
      date: '2023-04-02T16:00:00.000Z',
      consumption: 28.7
    },
    {
      date: '2023-04-02T16:30:00.000Z',
      consumption: 28.5
    },
    {
      date: '2023-04-02T17:00:00.000Z',
      consumption: 32.1
    },
    {
      date: '2023-04-02T17:30:00.000Z',
      consumption: 30.7
    },
    {
      date: '2023-04-02T18:00:00.000Z',
      consumption: 26
    },
    {
      date: '2023-04-02T18:30:00.000Z',
      consumption: 29.1
    },
    {
      date: '2023-04-02T19:00:00.000Z',
      consumption: 28.8
    },
    {
      date: '2023-04-02T19:30:00.000Z',
      consumption: 26.7
    },
    {
      date: '2023-04-02T20:00:00.000Z',
      consumption: 33.4
    },
    {
      date: '2023-04-02T20:30:00.000Z',
      consumption: 29.1
    },
    {
      date: '2023-04-02T21:00:00.000Z',
      consumption: 26.9
    },
    {
      date: '2023-04-02T21:30:00.000Z',
      consumption: 27.5
    },
    {
      date: '2023-04-02T22:00:00.000Z',
      consumption: 28.2
    },
    {
      date: '2023-04-02T22:30:00.000Z',
      consumption: 26.7
    },
    {
      date: '2023-04-02T23:00:00.000Z',
      consumption: 28.4
    },
    {
      date: '2023-04-02T23:30:00.000Z',
      consumption: 28
    },
    {
      date: '2023-04-03T00:00:00.000Z',
      consumption: 28.1
    },
    {
      date: '2023-04-03T00:30:00.000Z',
      consumption: 57.5
    },
    {
      date: '2023-04-03T01:00:00.000Z',
      consumption: 51.1
    },
    {
      date: '2023-04-03T01:30:00.000Z',
      consumption: 44
    },
    {
      date: '2023-04-03T02:00:00.000Z',
      consumption: 44
    },
    {
      date: '2023-04-03T02:30:00.000Z',
      consumption: 40.9
    },
    {
      date: '2023-04-03T03:00:00.000Z',
      consumption: 46
    },
    {
      date: '2023-04-03T03:30:00.000Z',
      consumption: 43.9
    },
    {
      date: '2023-04-03T04:00:00.000Z',
      consumption: 40.2
    },
    {
      date: '2023-04-03T04:30:00.000Z',
      consumption: 93.3
    },
    {
      date: '2023-04-03T05:00:00.000Z',
      consumption: 91.2
    },
    {
      date: '2023-04-03T05:30:00.000Z',
      consumption: 55.4
    },
    {
      date: '2023-04-03T06:00:00.000Z',
      consumption: 62.5
    },
    {
      date: '2023-04-03T06:30:00.000Z',
      consumption: 102.1
    },
    {
      date: '2023-04-03T07:00:00.000Z',
      consumption: 115.9
    },
    {
      date: '2023-04-03T07:30:00.000Z',
      consumption: 115.2
    },
    {
      date: '2023-04-03T08:00:00.000Z',
      consumption: 116.1
    },
    {
      date: '2023-04-03T08:30:00.000Z',
      consumption: 101.4
    },
    {
      date: '2023-04-03T09:00:00.000Z',
      consumption: 97.2
    },
    {
      date: '2023-04-03T09:30:00.000Z',
      consumption: 92
    },
    {
      date: '2023-04-03T10:00:00.000Z',
      consumption: 99.3
    },
    {
      date: '2023-04-03T10:30:00.000Z',
      consumption: 97.7
    },
    {
      date: '2023-04-03T11:00:00.000Z',
      consumption: 85.2
    },
    {
      date: '2023-04-03T11:30:00.000Z',
      consumption: 103.9
    },
    {
      date: '2023-04-03T12:00:00.000Z',
      consumption: 95.9
    },
    {
      date: '2023-04-03T12:30:00.000Z',
      consumption: 95.5
    },
    {
      date: '2023-04-03T13:00:00.000Z',
      consumption: 101.9
    },
    {
      date: '2023-04-03T13:30:00.000Z',
      consumption: 83.2
    },
    {
      date: '2023-04-03T14:00:00.000Z',
      consumption: 88.8
    },
    {
      date: '2023-04-03T14:30:00.000Z',
      consumption: 92.4
    },
    {
      date: '2023-04-03T15:00:00.000Z',
      consumption: 80.3
    },
    {
      date: '2023-04-03T15:30:00.000Z',
      consumption: 77.4
    },
    {
      date: '2023-04-03T16:00:00.000Z',
      consumption: 78.9
    },
    {
      date: '2023-04-03T16:30:00.000Z',
      consumption: 42.1
    },
    {
      date: '2023-04-03T17:00:00.000Z',
      consumption: 39.6
    },
    {
      date: '2023-04-03T17:30:00.000Z',
      consumption: 35.6
    },
    {
      date: '2023-04-03T18:00:00.000Z',
      consumption: 38.6
    },
    {
      date: '2023-04-03T18:30:00.000Z',
      consumption: 34.8
    },
    {
      date: '2023-04-03T19:00:00.000Z',
      consumption: 34.8
    },
    {
      date: '2023-04-03T19:30:00.000Z',
      consumption: 34.7
    },
    {
      date: '2023-04-03T20:00:00.000Z',
      consumption: 28.2
    },
    {
      date: '2023-04-03T20:30:00.000Z',
      consumption: 26.3
    },
    {
      date: '2023-04-03T21:00:00.000Z',
      consumption: 28.7
    },
    {
      date: '2023-04-03T21:30:00.000Z',
      consumption: 27.8
    },
    {
      date: '2023-04-03T22:00:00.000Z',
      consumption: 27.1
    },
    {
      date: '2023-04-03T22:30:00.000Z',
      consumption: 28.1
    },
    {
      date: '2023-04-03T23:00:00.000Z',
      consumption: 28.8
    },
    {
      date: '2023-04-03T23:30:00.000Z',
      consumption: 29.1
    },
    {
      date: '2023-04-04T00:00:00.000Z',
      consumption: 25.3
    },
    {
      date: '2023-04-04T00:30:00.000Z',
      consumption: 58.6
    },
    {
      date: '2023-04-04T01:00:00.000Z',
      consumption: 46.7
    },
    {
      date: '2023-04-04T01:30:00.000Z',
      consumption: 47.2
    },
    {
      date: '2023-04-04T02:00:00.000Z',
      consumption: 45
    },
    {
      date: '2023-04-04T02:30:00.000Z',
      consumption: 45.5
    },
    {
      date: '2023-04-04T03:00:00.000Z',
      consumption: 48.2
    },
    {
      date: '2023-04-04T03:30:00.000Z',
      consumption: 42.5
    },
    {
      date: '2023-04-04T04:00:00.000Z',
      consumption: 45.8
    },
    {
      date: '2023-04-04T04:30:00.000Z',
      consumption: 86.2
    },
    {
      date: '2023-04-04T05:00:00.000Z',
      consumption: 45.7
    },
    {
      date: '2023-04-04T05:30:00.000Z',
      consumption: 49.5
    },
    {
      date: '2023-04-04T06:00:00.000Z',
      consumption: 69.8
    },
    {
      date: '2023-04-04T06:30:00.000Z',
      consumption: 94.9
    },
    {
      date: '2023-04-04T07:00:00.000Z',
      consumption: 97.2
    },
    {
      date: '2023-04-04T07:30:00.000Z',
      consumption: 104.3
    },
    {
      date: '2023-04-04T08:00:00.000Z',
      consumption: 88.3
    },
    {
      date: '2023-04-04T08:30:00.000Z',
      consumption: 102
    },
    {
      date: '2023-04-04T09:00:00.000Z',
      consumption: 87
    },
    {
      date: '2023-04-04T09:30:00.000Z',
      consumption: 89
    },
    {
      date: '2023-04-04T10:00:00.000Z',
      consumption: 93.6
    },
    {
      date: '2023-04-04T10:30:00.000Z',
      consumption: 83.8
    },
    {
      date: '2023-04-04T11:00:00.000Z',
      consumption: 85.4
    },
    {
      date: '2023-04-04T11:30:00.000Z',
      consumption: 91.9
    },
    {
      date: '2023-04-04T12:00:00.000Z',
      consumption: 77.7
    },
    {
      date: '2023-04-04T12:30:00.000Z',
      consumption: 88.5
    },
    {
      date: '2023-04-04T13:00:00.000Z',
      consumption: 82.2
    },
    {
      date: '2023-04-04T13:30:00.000Z',
      consumption: 76.6
    },
    {
      date: '2023-04-04T14:00:00.000Z',
      consumption: 87.5
    },
    {
      date: '2023-04-04T14:30:00.000Z',
      consumption: 78.5
    },
    {
      date: '2023-04-04T15:00:00.000Z',
      consumption: 79.1
    },
    {
      date: '2023-04-04T15:30:00.000Z',
      consumption: 72.9
    },
    {
      date: '2023-04-04T16:00:00.000Z',
      consumption: 79.6
    },
    {
      date: '2023-04-04T16:30:00.000Z',
      consumption: 40.3
    },
    {
      date: '2023-04-04T17:00:00.000Z',
      consumption: 36.3
    },
    {
      date: '2023-04-04T17:30:00.000Z',
      consumption: 36.7
    },
    {
      date: '2023-04-04T18:00:00.000Z',
      consumption: 38.4
    },
    {
      date: '2023-04-04T18:30:00.000Z',
      consumption: 32.2
    },
    {
      date: '2023-04-04T19:00:00.000Z',
      consumption: 30.7
    },
    {
      date: '2023-04-04T19:30:00.000Z',
      consumption: 26.1
    },
    {
      date: '2023-04-04T20:00:00.000Z',
      consumption: 26.1
    },
    {
      date: '2023-04-04T20:30:00.000Z',
      consumption: 29.1
    },
    {
      date: '2023-04-04T21:00:00.000Z',
      consumption: 24.5
    },
    {
      date: '2023-04-04T21:30:00.000Z',
      consumption: 23.3
    },
    {
      date: '2023-04-04T22:00:00.000Z',
      consumption: 24.7
    },
    {
      date: '2023-04-04T22:30:00.000Z',
      consumption: 27
    },
    {
      date: '2023-04-04T23:00:00.000Z',
      consumption: 24.8
    },
    {
      date: '2023-04-04T23:30:00.000Z',
      consumption: 24.5
    },
    {
      date: '2023-04-05T00:00:00.000Z',
      consumption: 26.8
    },
    {
      date: '2023-04-05T00:30:00.000Z',
      consumption: 37.9
    },
    {
      date: '2023-04-05T01:00:00.000Z',
      consumption: 35.2
    },
    {
      date: '2023-04-05T01:30:00.000Z',
      consumption: 32.7
    },
    {
      date: '2023-04-05T02:00:00.000Z',
      consumption: 34
    },
    {
      date: '2023-04-05T02:30:00.000Z',
      consumption: 39.3
    },
    {
      date: '2023-04-05T03:00:00.000Z',
      consumption: 38
    },
    {
      date: '2023-04-05T03:30:00.000Z',
      consumption: 35.7
    },
    {
      date: '2023-04-05T04:00:00.000Z',
      consumption: 33.9
    },
    {
      date: '2023-04-05T04:30:00.000Z',
      consumption: 83.3
    },
    {
      date: '2023-04-05T05:00:00.000Z',
      consumption: 39
    },
    {
      date: '2023-04-05T05:30:00.000Z',
      consumption: 46.1
    },
    {
      date: '2023-04-05T06:00:00.000Z',
      consumption: 57.2
    },
    {
      date: '2023-04-05T06:30:00.000Z',
      consumption: 82.5
    },
    {
      date: '2023-04-05T07:00:00.000Z',
      consumption: 82.8
    },
    {
      date: '2023-04-05T07:30:00.000Z',
      consumption: 96.7
    },
    {
      date: '2023-04-05T08:00:00.000Z',
      consumption: 98.7
    },
    {
      date: '2023-04-05T08:30:00.000Z',
      consumption: 93.2
    },
    {
      date: '2023-04-05T09:00:00.000Z',
      consumption: 88.1
    },
    {
      date: '2023-04-05T09:30:00.000Z',
      consumption: 97
    },
    {
      date: '2023-04-05T10:00:00.000Z',
      consumption: 87.4
    },
    {
      date: '2023-04-05T10:30:00.000Z',
      consumption: 92.1
    },
    {
      date: '2023-04-05T11:00:00.000Z',
      consumption: 93.9
    },
    {
      date: '2023-04-05T11:30:00.000Z',
      consumption: 85.3
    },
    {
      date: '2023-04-05T12:00:00.000Z',
      consumption: 102.3
    },
    {
      date: '2023-04-05T12:30:00.000Z',
      consumption: 98
    },
    {
      date: '2023-04-05T13:00:00.000Z',
      consumption: 103.9
    },
    {
      date: '2023-04-05T13:30:00.000Z',
      consumption: 90.6
    },
    {
      date: '2023-04-05T14:00:00.000Z',
      consumption: 83.4
    },
    {
      date: '2023-04-05T14:30:00.000Z',
      consumption: 94.1
    },
    {
      date: '2023-04-05T15:00:00.000Z',
      consumption: 86
    },
    {
      date: '2023-04-05T15:30:00.000Z',
      consumption: 84.8
    },
    {
      date: '2023-04-05T16:00:00.000Z',
      consumption: 72.4
    },
    {
      date: '2023-04-05T16:30:00.000Z',
      consumption: 41.2
    },
    {
      date: '2023-04-05T17:00:00.000Z',
      consumption: 38.9
    },
    {
      date: '2023-04-05T17:30:00.000Z',
      consumption: 38.7
    },
    {
      date: '2023-04-05T18:00:00.000Z',
      consumption: 34.3
    },
    {
      date: '2023-04-05T18:30:00.000Z',
      consumption: 34.3
    },
    {
      date: '2023-04-05T19:00:00.000Z',
      consumption: 32.8
    },
    {
      date: '2023-04-05T19:30:00.000Z',
      consumption: 33.1
    },
    {
      date: '2023-04-05T20:00:00.000Z',
      consumption: 28
    },
    {
      date: '2023-04-05T20:30:00.000Z',
      consumption: 28.4
    },
    {
      date: '2023-04-05T21:00:00.000Z',
      consumption: 25.1
    },
    {
      date: '2023-04-05T21:30:00.000Z',
      consumption: 28.6
    },
    {
      date: '2023-04-05T22:00:00.000Z',
      consumption: 27.1
    },
    {
      date: '2023-04-05T22:30:00.000Z',
      consumption: 27.5
    },
    {
      date: '2023-04-05T23:00:00.000Z',
      consumption: 28.8
    },
    {
      date: '2023-04-05T23:30:00.000Z',
      consumption: 29.8
    },
    {
      date: '2023-04-06T00:00:00.000Z',
      consumption: 26
    },
    {
      date: '2023-04-06T00:30:00.000Z',
      consumption: 39.9
    },
    {
      date: '2023-04-06T01:00:00.000Z',
      consumption: 38.9
    },
    {
      date: '2023-04-06T01:30:00.000Z',
      consumption: 32.2
    },
    {
      date: '2023-04-06T02:00:00.000Z',
      consumption: 36.5
    },
    {
      date: '2023-04-06T02:30:00.000Z',
      consumption: 35.8
    },
    {
      date: '2023-04-06T03:00:00.000Z',
      consumption: 37.8
    },
    {
      date: '2023-04-06T03:30:00.000Z',
      consumption: 36.8
    },
    {
      date: '2023-04-06T04:00:00.000Z',
      consumption: 38.6
    },
    {
      date: '2023-04-06T04:30:00.000Z',
      consumption: 81.2
    },
    {
      date: '2023-04-06T05:00:00.000Z',
      consumption: 37.5
    },
    {
      date: '2023-04-06T05:30:00.000Z',
      consumption: 41.2
    },
    {
      date: '2023-04-06T06:00:00.000Z',
      consumption: 66.8
    },
    {
      date: '2023-04-06T06:30:00.000Z',
      consumption: 89.7
    },
    {
      date: '2023-04-06T07:00:00.000Z',
      consumption: 102.3
    },
    {
      date: '2023-04-06T07:30:00.000Z',
      consumption: 86.5
    },
    {
      date: '2023-04-06T08:00:00.000Z',
      consumption: 92.7
    },
    {
      date: '2023-04-06T08:30:00.000Z',
      consumption: 103.7
    },
    {
      date: '2023-04-06T09:00:00.000Z',
      consumption: 87.5
    },
    {
      date: '2023-04-06T09:30:00.000Z',
      consumption: 101.4
    },
    {
      date: '2023-04-06T10:00:00.000Z',
      consumption: 90.4
    },
    {
      date: '2023-04-06T10:30:00.000Z',
      consumption: 90.8
    },
    {
      date: '2023-04-06T11:00:00.000Z',
      consumption: 113.3
    },
    {
      date: '2023-04-06T11:30:00.000Z',
      consumption: 83.4
    },
    {
      date: '2023-04-06T12:00:00.000Z',
      consumption: 88.5
    },
    {
      date: '2023-04-06T12:30:00.000Z',
      consumption: 96.4
    },
    {
      date: '2023-04-06T13:00:00.000Z',
      consumption: 90.2
    },
    {
      date: '2023-04-06T13:30:00.000Z',
      consumption: 80.8
    },
    {
      date: '2023-04-06T14:00:00.000Z',
      consumption: 88.4
    },
    {
      date: '2023-04-06T14:30:00.000Z',
      consumption: 77.5
    },
    {
      date: '2023-04-06T15:00:00.000Z',
      consumption: 78
    },
    {
      date: '2023-04-06T15:30:00.000Z',
      consumption: 70.7
    },
    {
      date: '2023-04-06T16:00:00.000Z',
      consumption: 70.7
    },
    {
      date: '2023-04-06T16:30:00.000Z',
      consumption: 35.2
    },
    {
      date: '2023-04-06T17:00:00.000Z',
      consumption: 30
    },
    {
      date: '2023-04-06T17:30:00.000Z',
      consumption: 30.7
    },
    {
      date: '2023-04-06T18:00:00.000Z',
      consumption: 34.8
    },
    {
      date: '2023-04-06T18:30:00.000Z',
      consumption: 26.2
    },
    {
      date: '2023-04-06T19:00:00.000Z',
      consumption: 27.6
    },
    {
      date: '2023-04-06T19:30:00.000Z',
      consumption: 25.3
    },
    {
      date: '2023-04-06T20:00:00.000Z',
      consumption: 24
    },
    {
      date: '2023-04-06T20:30:00.000Z',
      consumption: 25.9
    },
    {
      date: '2023-04-06T21:00:00.000Z',
      consumption: 21.4
    },
    {
      date: '2023-04-06T21:30:00.000Z',
      consumption: 23.8
    },
    {
      date: '2023-04-06T22:00:00.000Z',
      consumption: 23.8
    },
    {
      date: '2023-04-06T22:30:00.000Z',
      consumption: 24.9
    },
    {
      date: '2023-04-06T23:00:00.000Z',
      consumption: 22.9
    },
    {
      date: '2023-04-06T23:30:00.000Z',
      consumption: 22.3
    },
    {
      date: '2023-04-07T00:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-07T00:30:00.000Z',
      consumption: 22.3
    },
    {
      date: '2023-04-07T01:00:00.000Z',
      consumption: 22.3
    },
    {
      date: '2023-04-07T01:30:00.000Z',
      consumption: 24.8
    },
    {
      date: '2023-04-07T02:00:00.000Z',
      consumption: 22.3
    },
    {
      date: '2023-04-07T02:30:00.000Z',
      consumption: 23.1
    },
    {
      date: '2023-04-07T03:00:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-07T03:30:00.000Z',
      consumption: 23.5
    },
    {
      date: '2023-04-07T04:00:00.000Z',
      consumption: 24.5
    },
    {
      date: '2023-04-07T04:30:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-07T05:00:00.000Z',
      consumption: 22.3
    },
    {
      date: '2023-04-07T05:30:00.000Z',
      consumption: 27.8
    },
    {
      date: '2023-04-07T06:00:00.000Z',
      consumption: 23.8
    },
    {
      date: '2023-04-07T06:30:00.000Z',
      consumption: 25.9
    },
    {
      date: '2023-04-07T07:00:00.000Z',
      consumption: 26.6
    },
    {
      date: '2023-04-07T07:30:00.000Z',
      consumption: 27.1
    },
    {
      date: '2023-04-07T08:00:00.000Z',
      consumption: 25.9
    },
    {
      date: '2023-04-07T08:30:00.000Z',
      consumption: 27.7
    },
    {
      date: '2023-04-07T09:00:00.000Z',
      consumption: 28.2
    },
    {
      date: '2023-04-07T09:30:00.000Z',
      consumption: 26.6
    },
    {
      date: '2023-04-07T10:00:00.000Z',
      consumption: 28.6
    },
    {
      date: '2023-04-07T10:30:00.000Z',
      consumption: 28
    },
    {
      date: '2023-04-07T11:00:00.000Z',
      consumption: 27.7
    },
    {
      date: '2023-04-07T11:30:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-07T12:00:00.000Z',
      consumption: 29.9
    },
    {
      date: '2023-04-07T12:30:00.000Z',
      consumption: 27
    },
    {
      date: '2023-04-07T13:00:00.000Z',
      consumption: 25.5
    },
    {
      date: '2023-04-07T13:30:00.000Z',
      consumption: 27.1
    },
    {
      date: '2023-04-07T14:00:00.000Z',
      consumption: 27.6
    },
    {
      date: '2023-04-07T14:30:00.000Z',
      consumption: 25.6
    },
    {
      date: '2023-04-07T15:00:00.000Z',
      consumption: 26.3
    },
    {
      date: '2023-04-07T15:30:00.000Z',
      consumption: 27.7
    },
    {
      date: '2023-04-07T16:00:00.000Z',
      consumption: 27
    },
    {
      date: '2023-04-07T16:30:00.000Z',
      consumption: 24.4
    },
    {
      date: '2023-04-07T17:00:00.000Z',
      consumption: 27.2
    },
    {
      date: '2023-04-07T17:30:00.000Z',
      consumption: 23.6
    },
    {
      date: '2023-04-07T18:00:00.000Z',
      consumption: 22.7
    },
    {
      date: '2023-04-07T18:30:00.000Z',
      consumption: 25.6
    },
    {
      date: '2023-04-07T19:00:00.000Z',
      consumption: 22.7
    },
    {
      date: '2023-04-07T19:30:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-07T20:00:00.000Z',
      consumption: 23.7
    },
    {
      date: '2023-04-07T20:30:00.000Z',
      consumption: 23.8
    },
    {
      date: '2023-04-07T21:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-07T21:30:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-07T22:00:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-07T22:30:00.000Z',
      consumption: 26.4
    },
    {
      date: '2023-04-07T23:00:00.000Z',
      consumption: 20.6
    },
    {
      date: '2023-04-07T23:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-08T00:00:00.000Z',
      consumption: 23.6
    },
    {
      date: '2023-04-08T00:30:00.000Z',
      consumption: 25.4
    },
    {
      date: '2023-04-08T01:00:00.000Z',
      consumption: 20.8
    },
    {
      date: '2023-04-08T01:30:00.000Z',
      consumption: 21.4
    },
    {
      date: '2023-04-08T02:00:00.000Z',
      consumption: 23.4
    },
    {
      date: '2023-04-08T02:30:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-08T03:00:00.000Z',
      consumption: 24.3
    },
    {
      date: '2023-04-08T03:30:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-08T04:00:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-08T04:30:00.000Z',
      consumption: 24.6
    },
    {
      date: '2023-04-08T05:00:00.000Z',
      consumption: 26
    },
    {
      date: '2023-04-08T05:30:00.000Z',
      consumption: 24.1
    },
    {
      date: '2023-04-08T06:00:00.000Z',
      consumption: 23.6
    },
    {
      date: '2023-04-08T06:30:00.000Z',
      consumption: 25.1
    },
    {
      date: '2023-04-08T07:00:00.000Z',
      consumption: 27.7
    },
    {
      date: '2023-04-08T07:30:00.000Z',
      consumption: 22.6
    },
    {
      date: '2023-04-08T08:00:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-08T08:30:00.000Z',
      consumption: 23.9
    },
    {
      date: '2023-04-08T09:00:00.000Z',
      consumption: 22.3
    },
    {
      date: '2023-04-08T09:30:00.000Z',
      consumption: 25.2
    },
    {
      date: '2023-04-08T10:00:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-08T10:30:00.000Z',
      consumption: 23.7
    },
    {
      date: '2023-04-08T11:00:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-08T11:30:00.000Z',
      consumption: 24.9
    },
    {
      date: '2023-04-08T12:00:00.000Z',
      consumption: 23.5
    },
    {
      date: '2023-04-08T12:30:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-08T13:00:00.000Z',
      consumption: 22.7
    },
    {
      date: '2023-04-08T13:30:00.000Z',
      consumption: 26.4
    },
    {
      date: '2023-04-08T14:00:00.000Z',
      consumption: 22.9
    },
    {
      date: '2023-04-08T14:30:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-08T15:00:00.000Z',
      consumption: 25.6
    },
    {
      date: '2023-04-08T15:30:00.000Z',
      consumption: 23.9
    },
    {
      date: '2023-04-08T16:00:00.000Z',
      consumption: 20.6
    },
    {
      date: '2023-04-08T16:30:00.000Z',
      consumption: 24.7
    },
    {
      date: '2023-04-08T17:00:00.000Z',
      consumption: 23.2
    },
    {
      date: '2023-04-08T17:30:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-08T18:00:00.000Z',
      consumption: 20.3
    },
    {
      date: '2023-04-08T18:30:00.000Z',
      consumption: 25.1
    },
    {
      date: '2023-04-08T19:00:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-08T19:30:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-08T20:00:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-08T20:30:00.000Z',
      consumption: 24.8
    },
    {
      date: '2023-04-08T21:00:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-08T21:30:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-08T22:00:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-08T22:30:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-08T23:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-08T23:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-09T00:00:00.000Z',
      consumption: 23.2
    },
    {
      date: '2023-04-09T00:30:00.000Z',
      consumption: 22.8
    },
    {
      date: '2023-04-09T01:00:00.000Z',
      consumption: 24.3
    },
    {
      date: '2023-04-09T01:30:00.000Z',
      consumption: 22.7
    },
    {
      date: '2023-04-09T02:00:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-09T02:30:00.000Z',
      consumption: 22
    },
    {
      date: '2023-04-09T03:00:00.000Z',
      consumption: 24.7
    },
    {
      date: '2023-04-09T03:30:00.000Z',
      consumption: 21.4
    },
    {
      date: '2023-04-09T04:00:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-09T04:30:00.000Z',
      consumption: 21.5
    },
    {
      date: '2023-04-09T05:00:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-09T05:30:00.000Z',
      consumption: 24.8
    },
    {
      date: '2023-04-09T06:00:00.000Z',
      consumption: 23.4
    },
    {
      date: '2023-04-09T06:30:00.000Z',
      consumption: 22.7
    },
    {
      date: '2023-04-09T07:00:00.000Z',
      consumption: 26
    },
    {
      date: '2023-04-09T07:30:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-09T08:00:00.000Z',
      consumption: 21.5
    },
    {
      date: '2023-04-09T08:30:00.000Z',
      consumption: 22.8
    },
    {
      date: '2023-04-09T09:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-09T09:30:00.000Z',
      consumption: 23.5
    },
    {
      date: '2023-04-09T10:00:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-09T10:30:00.000Z',
      consumption: 23.5
    },
    {
      date: '2023-04-09T11:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-09T11:30:00.000Z',
      consumption: 22.8
    },
    {
      date: '2023-04-09T12:00:00.000Z',
      consumption: 25.5
    },
    {
      date: '2023-04-09T12:30:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-09T13:00:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-09T13:30:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-09T14:00:00.000Z',
      consumption: 25.5
    },
    {
      date: '2023-04-09T14:30:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-09T15:00:00.000Z',
      consumption: 21.5
    },
    {
      date: '2023-04-09T15:30:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-09T16:00:00.000Z',
      consumption: 24.7
    },
    {
      date: '2023-04-09T16:30:00.000Z',
      consumption: 20
    },
    {
      date: '2023-04-09T17:00:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-09T17:30:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-09T18:00:00.000Z',
      consumption: 21.4
    },
    {
      date: '2023-04-09T18:30:00.000Z',
      consumption: 23.9
    },
    {
      date: '2023-04-09T19:00:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-09T19:30:00.000Z',
      consumption: 21.5
    },
    {
      date: '2023-04-09T20:00:00.000Z',
      consumption: 22.4
    },
    {
      date: '2023-04-09T20:30:00.000Z',
      consumption: 25.9
    },
    {
      date: '2023-04-09T21:00:00.000Z',
      consumption: 20.3
    },
    {
      date: '2023-04-09T21:30:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-09T22:00:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-09T22:30:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-09T23:00:00.000Z',
      consumption: 23.2
    },
    {
      date: '2023-04-09T23:30:00.000Z',
      consumption: 20.2
    },
    {
      date: '2023-04-10T00:00:00.000Z',
      consumption: 23.4
    },
    {
      date: '2023-04-10T00:30:00.000Z',
      consumption: 20.9
    },
    {
      date: '2023-04-10T01:00:00.000Z',
      consumption: 24.3
    },
    {
      date: '2023-04-10T01:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-10T02:00:00.000Z',
      consumption: 22
    },
    {
      date: '2023-04-10T02:30:00.000Z',
      consumption: 20.8
    },
    {
      date: '2023-04-10T03:00:00.000Z',
      consumption: 23.2
    },
    {
      date: '2023-04-10T03:30:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-10T04:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-10T04:30:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-10T05:00:00.000Z',
      consumption: 23.3
    },
    {
      date: '2023-04-10T05:30:00.000Z',
      consumption: 25.8
    },
    {
      date: '2023-04-10T06:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-10T06:30:00.000Z',
      consumption: 24.9
    },
    {
      date: '2023-04-10T07:00:00.000Z',
      consumption: 22.6
    },
    {
      date: '2023-04-10T07:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-10T08:00:00.000Z',
      consumption: 24.8
    },
    {
      date: '2023-04-10T08:30:00.000Z',
      consumption: 23.2
    },
    {
      date: '2023-04-10T09:00:00.000Z',
      consumption: 22.7
    },
    {
      date: '2023-04-10T09:30:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-10T10:00:00.000Z',
      consumption: 24.1
    },
    {
      date: '2023-04-10T10:30:00.000Z',
      consumption: 26.1
    },
    {
      date: '2023-04-10T11:00:00.000Z',
      consumption: 23.1
    },
    {
      date: '2023-04-10T11:30:00.000Z',
      consumption: 22
    },
    {
      date: '2023-04-10T12:00:00.000Z',
      consumption: 23.8
    },
    {
      date: '2023-04-10T12:30:00.000Z',
      consumption: 26
    },
    {
      date: '2023-04-10T13:00:00.000Z',
      consumption: 22.4
    },
    {
      date: '2023-04-10T13:30:00.000Z',
      consumption: 23
    },
    {
      date: '2023-04-10T14:00:00.000Z',
      consumption: 24.2
    },
    {
      date: '2023-04-10T14:30:00.000Z',
      consumption: 22.6
    },
    {
      date: '2023-04-10T15:00:00.000Z',
      consumption: 26.2
    },
    {
      date: '2023-04-10T15:30:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-10T16:00:00.000Z',
      consumption: 23.6
    },
    {
      date: '2023-04-10T16:30:00.000Z',
      consumption: 22.8
    },
    {
      date: '2023-04-10T17:00:00.000Z',
      consumption: 27.1
    },
    {
      date: '2023-04-10T17:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-10T18:00:00.000Z',
      consumption: 22.6
    },
    {
      date: '2023-04-10T18:30:00.000Z',
      consumption: 23.6
    },
    {
      date: '2023-04-10T19:00:00.000Z',
      consumption: 23
    },
    {
      date: '2023-04-10T19:30:00.000Z',
      consumption: 25.5
    },
    {
      date: '2023-04-10T20:00:00.000Z',
      consumption: 22
    },
    {
      date: '2023-04-10T20:30:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-10T21:00:00.000Z',
      consumption: 21.4
    },
    {
      date: '2023-04-10T21:30:00.000Z',
      consumption: 24.5
    },
    {
      date: '2023-04-10T22:00:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-10T22:30:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-10T23:00:00.000Z',
      consumption: 22.7
    },
    {
      date: '2023-04-10T23:30:00.000Z',
      consumption: 23.6
    },
    {
      date: '2023-04-11T00:00:00.000Z',
      consumption: 23.2
    },
    {
      date: '2023-04-11T00:30:00.000Z',
      consumption: 56.2
    },
    {
      date: '2023-04-11T01:00:00.000Z',
      consumption: 50.1
    },
    {
      date: '2023-04-11T01:30:00.000Z',
      consumption: 40.2
    },
    {
      date: '2023-04-11T02:00:00.000Z',
      consumption: 40.8
    },
    {
      date: '2023-04-11T02:30:00.000Z',
      consumption: 42.2
    },
    {
      date: '2023-04-11T03:00:00.000Z',
      consumption: 43.3
    },
    {
      date: '2023-04-11T03:30:00.000Z',
      consumption: 41.5
    },
    {
      date: '2023-04-11T04:00:00.000Z',
      consumption: 39.4
    },
    {
      date: '2023-04-11T04:30:00.000Z',
      consumption: 90.9
    },
    {
      date: '2023-04-11T05:00:00.000Z',
      consumption: 86.7
    },
    {
      date: '2023-04-11T05:30:00.000Z',
      consumption: 78.7
    },
    {
      date: '2023-04-11T06:00:00.000Z',
      consumption: 60.2
    },
    {
      date: '2023-04-11T06:30:00.000Z',
      consumption: 97.7
    },
    {
      date: '2023-04-11T07:00:00.000Z',
      consumption: 98.8
    },
    {
      date: '2023-04-11T07:30:00.000Z',
      consumption: 119.9
    },
    {
      date: '2023-04-11T08:00:00.000Z',
      consumption: 98
    },
    {
      date: '2023-04-11T08:30:00.000Z',
      consumption: 112.6
    },
    {
      date: '2023-04-11T09:00:00.000Z',
      consumption: 89.4
    },
    {
      date: '2023-04-11T09:30:00.000Z',
      consumption: 100.6
    },
    {
      date: '2023-04-11T10:00:00.000Z',
      consumption: 96.7
    },
    {
      date: '2023-04-11T10:30:00.000Z',
      consumption: 94.8
    },
    {
      date: '2023-04-11T11:00:00.000Z',
      consumption: 85.6
    },
    {
      date: '2023-04-11T11:30:00.000Z',
      consumption: 82.8
    },
    {
      date: '2023-04-11T12:00:00.000Z',
      consumption: 100.3
    },
    {
      date: '2023-04-11T12:30:00.000Z',
      consumption: 79.2
    },
    {
      date: '2023-04-11T13:00:00.000Z',
      consumption: 79.6
    },
    {
      date: '2023-04-11T13:30:00.000Z',
      consumption: 88
    },
    {
      date: '2023-04-11T14:00:00.000Z',
      consumption: 77.1
    },
    {
      date: '2023-04-11T14:30:00.000Z',
      consumption: 86
    },
    {
      date: '2023-04-11T15:00:00.000Z',
      consumption: 92.1
    },
    {
      date: '2023-04-11T15:30:00.000Z',
      consumption: 68.2
    },
    {
      date: '2023-04-11T16:00:00.000Z',
      consumption: 72
    },
    {
      date: '2023-04-11T16:30:00.000Z',
      consumption: 38.3
    },
    {
      date: '2023-04-11T17:00:00.000Z',
      consumption: 39.6
    },
    {
      date: '2023-04-11T17:30:00.000Z',
      consumption: 29.2
    },
    {
      date: '2023-04-11T18:00:00.000Z',
      consumption: 28.7
    },
    {
      date: '2023-04-11T18:30:00.000Z',
      consumption: 28.4
    },
    {
      date: '2023-04-11T19:00:00.000Z',
      consumption: 30.3
    },
    {
      date: '2023-04-11T19:30:00.000Z',
      consumption: 26.2
    },
    {
      date: '2023-04-11T20:00:00.000Z',
      consumption: 26.7
    },
    {
      date: '2023-04-11T20:30:00.000Z',
      consumption: 23.7
    },
    {
      date: '2023-04-11T21:00:00.000Z',
      consumption: 25.5
    },
    {
      date: '2023-04-11T21:30:00.000Z',
      consumption: 20.7
    },
    {
      date: '2023-04-11T22:00:00.000Z',
      consumption: 23.3
    },
    {
      date: '2023-04-11T22:30:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-11T23:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-11T23:30:00.000Z',
      consumption: 25.8
    },
    {
      date: '2023-04-12T00:00:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-12T00:30:00.000Z',
      consumption: 41.6
    },
    {
      date: '2023-04-12T01:00:00.000Z',
      consumption: 42.3
    },
    {
      date: '2023-04-12T01:30:00.000Z',
      consumption: 40.7
    },
    {
      date: '2023-04-12T02:00:00.000Z',
      consumption: 35.1
    },
    {
      date: '2023-04-12T02:30:00.000Z',
      consumption: 37
    },
    {
      date: '2023-04-12T03:00:00.000Z',
      consumption: 42.4
    },
    {
      date: '2023-04-12T03:30:00.000Z',
      consumption: 48.1
    },
    {
      date: '2023-04-12T04:00:00.000Z',
      consumption: 40.3
    },
    {
      date: '2023-04-12T04:30:00.000Z',
      consumption: 91.6
    },
    {
      date: '2023-04-12T05:00:00.000Z',
      consumption: 43.1
    },
    {
      date: '2023-04-12T05:30:00.000Z',
      consumption: 49.8
    },
    {
      date: '2023-04-12T06:00:00.000Z',
      consumption: 66
    },
    {
      date: '2023-04-12T06:30:00.000Z',
      consumption: 96.8
    },
    {
      date: '2023-04-12T07:00:00.000Z',
      consumption: 104.9
    },
    {
      date: '2023-04-12T07:30:00.000Z',
      consumption: 109
    },
    {
      date: '2023-04-12T08:00:00.000Z',
      consumption: 101.8
    },
    {
      date: '2023-04-12T08:30:00.000Z',
      consumption: 97.8
    },
    {
      date: '2023-04-12T09:00:00.000Z',
      consumption: 91.1
    },
    {
      date: '2023-04-12T09:30:00.000Z',
      consumption: 109.3
    },
    {
      date: '2023-04-12T10:00:00.000Z',
      consumption: 92.3
    },
    {
      date: '2023-04-12T10:30:00.000Z',
      consumption: 97.5
    },
    {
      date: '2023-04-12T11:00:00.000Z',
      consumption: 99.7
    },
    {
      date: '2023-04-12T11:30:00.000Z',
      consumption: 89.2
    },
    {
      date: '2023-04-12T12:00:00.000Z',
      consumption: 93
    },
    {
      date: '2023-04-12T12:30:00.000Z',
      consumption: 86.8
    },
    {
      date: '2023-04-12T13:00:00.000Z',
      consumption: 105.3
    },
    {
      date: '2023-04-12T13:30:00.000Z',
      consumption: 86.5
    },
    {
      date: '2023-04-12T14:00:00.000Z',
      consumption: 86.9
    },
    {
      date: '2023-04-12T14:30:00.000Z',
      consumption: 96.8
    },
    {
      date: '2023-04-12T15:00:00.000Z',
      consumption: 85.1
    },
    {
      date: '2023-04-12T15:30:00.000Z',
      consumption: 77
    },
    {
      date: '2023-04-12T16:00:00.000Z',
      consumption: 72.3
    },
    {
      date: '2023-04-12T16:30:00.000Z',
      consumption: 34.4
    },
    {
      date: '2023-04-12T17:00:00.000Z',
      consumption: 32.4
    },
    {
      date: '2023-04-12T17:30:00.000Z',
      consumption: 28.9
    },
    {
      date: '2023-04-12T18:00:00.000Z',
      consumption: 25.1
    },
    {
      date: '2023-04-12T18:30:00.000Z',
      consumption: 29.3
    },
    {
      date: '2023-04-12T19:00:00.000Z',
      consumption: 28.9
    },
    {
      date: '2023-04-12T19:30:00.000Z',
      consumption: 28.5
    },
    {
      date: '2023-04-12T20:00:00.000Z',
      consumption: 26.2
    },
    {
      date: '2023-04-12T20:30:00.000Z',
      consumption: 23.3
    },
    {
      date: '2023-04-12T21:00:00.000Z',
      consumption: 26.4
    },
    {
      date: '2023-04-12T21:30:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-12T22:00:00.000Z',
      consumption: 23.2
    },
    {
      date: '2023-04-12T22:30:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-12T23:00:00.000Z',
      consumption: 26.6
    },
    {
      date: '2023-04-12T23:30:00.000Z',
      consumption: 22.3
    },
    {
      date: '2023-04-13T00:00:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-13T00:30:00.000Z',
      consumption: 48.4
    },
    {
      date: '2023-04-13T01:00:00.000Z',
      consumption: 45.3
    },
    {
      date: '2023-04-13T01:30:00.000Z',
      consumption: 43.5
    },
    {
      date: '2023-04-13T02:00:00.000Z',
      consumption: 42
    },
    {
      date: '2023-04-13T02:30:00.000Z',
      consumption: 47.5
    },
    {
      date: '2023-04-13T03:00:00.000Z',
      consumption: 49
    },
    {
      date: '2023-04-13T03:30:00.000Z',
      consumption: 45.5
    },
    {
      date: '2023-04-13T04:00:00.000Z',
      consumption: 40.4
    },
    {
      date: '2023-04-13T04:30:00.000Z',
      consumption: 86.2
    },
    {
      date: '2023-04-13T05:00:00.000Z',
      consumption: 42.4
    },
    {
      date: '2023-04-13T05:30:00.000Z',
      consumption: 48
    },
    {
      date: '2023-04-13T06:00:00.000Z',
      consumption: 65.5
    },
    {
      date: '2023-04-13T06:30:00.000Z',
      consumption: 101.3
    },
    {
      date: '2023-04-13T07:00:00.000Z',
      consumption: 100.9
    },
    {
      date: '2023-04-13T07:30:00.000Z',
      consumption: 114
    },
    {
      date: '2023-04-13T08:00:00.000Z',
      consumption: 108
    },
    {
      date: '2023-04-13T08:30:00.000Z',
      consumption: 108.6
    },
    {
      date: '2023-04-13T09:00:00.000Z',
      consumption: 105.5
    },
    {
      date: '2023-04-13T09:30:00.000Z',
      consumption: 108.9
    },
    {
      date: '2023-04-13T10:00:00.000Z',
      consumption: 109.2
    },
    {
      date: '2023-04-13T10:30:00.000Z',
      consumption: 98.2
    },
    {
      date: '2023-04-13T11:00:00.000Z',
      consumption: 100.4
    },
    {
      date: '2023-04-13T11:30:00.000Z',
      consumption: 87.3
    },
    {
      date: '2023-04-13T12:00:00.000Z',
      consumption: 85.9
    },
    {
      date: '2023-04-13T12:30:00.000Z',
      consumption: 101.4
    },
    {
      date: '2023-04-13T13:00:00.000Z',
      consumption: 92.2
    },
    {
      date: '2023-04-13T13:30:00.000Z',
      consumption: 96.1
    },
    {
      date: '2023-04-13T14:00:00.000Z',
      consumption: 86.9
    },
    {
      date: '2023-04-13T14:30:00.000Z',
      consumption: 85
    },
    {
      date: '2023-04-13T15:00:00.000Z',
      consumption: 89.7
    },
    {
      date: '2023-04-13T15:30:00.000Z',
      consumption: 82
    },
    {
      date: '2023-04-13T16:00:00.000Z',
      consumption: 74.8
    },
    {
      date: '2023-04-13T16:30:00.000Z',
      consumption: 37.1
    },
    {
      date: '2023-04-13T17:00:00.000Z',
      consumption: 32.9
    },
    {
      date: '2023-04-13T17:30:00.000Z',
      consumption: 29.7
    },
    {
      date: '2023-04-13T18:00:00.000Z',
      consumption: 31.6
    },
    {
      date: '2023-04-13T18:30:00.000Z',
      consumption: 27.5
    },
    {
      date: '2023-04-13T19:00:00.000Z',
      consumption: 28.2
    },
    {
      date: '2023-04-13T19:30:00.000Z',
      consumption: 28.1
    },
    {
      date: '2023-04-13T20:00:00.000Z',
      consumption: 25.2
    },
    {
      date: '2023-04-13T20:30:00.000Z',
      consumption: 23.5
    },
    {
      date: '2023-04-13T21:00:00.000Z',
      consumption: 26.8
    },
    {
      date: '2023-04-13T21:30:00.000Z',
      consumption: 21
    },
    {
      date: '2023-04-13T22:00:00.000Z',
      consumption: 24.3
    },
    {
      date: '2023-04-13T22:30:00.000Z',
      consumption: 23.5
    },
    {
      date: '2023-04-13T23:00:00.000Z',
      consumption: 25.2
    },
    {
      date: '2023-04-13T23:30:00.000Z',
      consumption: 23.9
    },
    {
      date: '2023-04-14T00:00:00.000Z',
      consumption: 24.3
    },
    {
      date: '2023-04-14T00:30:00.000Z',
      consumption: 37.2
    },
    {
      date: '2023-04-14T01:00:00.000Z',
      consumption: 44.4
    },
    {
      date: '2023-04-14T01:30:00.000Z',
      consumption: 36.5
    },
    {
      date: '2023-04-14T02:00:00.000Z',
      consumption: 38.1
    },
    {
      date: '2023-04-14T02:30:00.000Z',
      consumption: 42.1
    },
    {
      date: '2023-04-14T03:00:00.000Z',
      consumption: 41.6
    },
    {
      date: '2023-04-14T03:30:00.000Z',
      consumption: 40
    },
    {
      date: '2023-04-14T04:00:00.000Z',
      consumption: 38.3
    },
    {
      date: '2023-04-14T04:30:00.000Z',
      consumption: 86.6
    },
    {
      date: '2023-04-14T05:00:00.000Z',
      consumption: 42.6
    },
    {
      date: '2023-04-14T05:30:00.000Z',
      consumption: 54.5
    },
    {
      date: '2023-04-14T06:00:00.000Z',
      consumption: 71.8
    },
    {
      date: '2023-04-14T06:30:00.000Z',
      consumption: 105.3
    },
    {
      date: '2023-04-14T07:00:00.000Z',
      consumption: 104.7
    },
    {
      date: '2023-04-14T07:30:00.000Z',
      consumption: 100.9
    },
    {
      date: '2023-04-14T08:00:00.000Z',
      consumption: 114.4
    },
    {
      date: '2023-04-14T08:30:00.000Z',
      consumption: 87
    },
    {
      date: '2023-04-14T09:00:00.000Z',
      consumption: 85.4
    },
    {
      date: '2023-04-14T09:30:00.000Z',
      consumption: 93.5
    },
    {
      date: '2023-04-14T10:00:00.000Z',
      consumption: 83.4
    },
    {
      date: '2023-04-14T10:30:00.000Z',
      consumption: 95.2
    },
    {
      date: '2023-04-14T11:00:00.000Z',
      consumption: 84.2
    },
    {
      date: '2023-04-14T11:30:00.000Z',
      consumption: 77.3
    },
    {
      date: '2023-04-14T12:00:00.000Z',
      consumption: 85.8
    },
    {
      date: '2023-04-14T12:30:00.000Z',
      consumption: 75.8
    },
    {
      date: '2023-04-14T13:00:00.000Z',
      consumption: 77.8
    },
    {
      date: '2023-04-14T13:30:00.000Z',
      consumption: 87.6
    },
    {
      date: '2023-04-14T14:00:00.000Z',
      consumption: 75.5
    },
    {
      date: '2023-04-14T14:30:00.000Z',
      consumption: 68.6
    },
    {
      date: '2023-04-14T15:00:00.000Z',
      consumption: 85
    },
    {
      date: '2023-04-14T15:30:00.000Z',
      consumption: 68.2
    },
    {
      date: '2023-04-14T16:00:00.000Z',
      consumption: 73.2
    },
    {
      date: '2023-04-14T16:30:00.000Z',
      consumption: 30.5
    },
    {
      date: '2023-04-14T17:00:00.000Z',
      consumption: 34.2
    },
    {
      date: '2023-04-14T17:30:00.000Z',
      consumption: 27.9
    },
    {
      date: '2023-04-14T18:00:00.000Z',
      consumption: 26.8
    },
    {
      date: '2023-04-14T18:30:00.000Z',
      consumption: 24.1
    },
    {
      date: '2023-04-14T19:00:00.000Z',
      consumption: 28.2
    },
    {
      date: '2023-04-14T19:30:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-14T20:00:00.000Z',
      consumption: 21
    },
    {
      date: '2023-04-14T20:30:00.000Z',
      consumption: 24
    },
    {
      date: '2023-04-14T21:00:00.000Z',
      consumption: 24.7
    },
    {
      date: '2023-04-14T21:30:00.000Z',
      consumption: 23.6
    },
    {
      date: '2023-04-14T22:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-14T22:30:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-14T23:00:00.000Z',
      consumption: 21.4
    },
    {
      date: '2023-04-14T23:30:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-15T00:00:00.000Z',
      consumption: 23
    },
    {
      date: '2023-04-15T00:30:00.000Z',
      consumption: 25.2
    },
    {
      date: '2023-04-15T01:00:00.000Z',
      consumption: 24.9
    },
    {
      date: '2023-04-15T01:30:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-15T02:00:00.000Z',
      consumption: 21.4
    },
    {
      date: '2023-04-15T02:30:00.000Z',
      consumption: 26.4
    },
    {
      date: '2023-04-15T03:00:00.000Z',
      consumption: 22.8
    },
    {
      date: '2023-04-15T03:30:00.000Z',
      consumption: 24.4
    },
    {
      date: '2023-04-15T04:00:00.000Z',
      consumption: 25.5
    },
    {
      date: '2023-04-15T04:30:00.000Z',
      consumption: 23
    },
    {
      date: '2023-04-15T05:00:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-15T05:30:00.000Z',
      consumption: 25.9
    },
    {
      date: '2023-04-15T06:00:00.000Z',
      consumption: 24.1
    },
    {
      date: '2023-04-15T06:30:00.000Z',
      consumption: 23.3
    },
    {
      date: '2023-04-15T07:00:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-15T07:30:00.000Z',
      consumption: 25.4
    },
    {
      date: '2023-04-15T08:00:00.000Z',
      consumption: 23
    },
    {
      date: '2023-04-15T08:30:00.000Z',
      consumption: 25.3
    },
    {
      date: '2023-04-15T09:00:00.000Z',
      consumption: 24.8
    },
    {
      date: '2023-04-15T09:30:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-15T10:00:00.000Z',
      consumption: 23
    },
    {
      date: '2023-04-15T10:30:00.000Z',
      consumption: 20.9
    },
    {
      date: '2023-04-15T11:00:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-15T11:30:00.000Z',
      consumption: 21.4
    },
    {
      date: '2023-04-15T12:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-15T12:30:00.000Z',
      consumption: 21.5
    },
    {
      date: '2023-04-15T13:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-15T13:30:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-15T14:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-15T14:30:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-15T15:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-15T15:30:00.000Z',
      consumption: 22.9
    },
    {
      date: '2023-04-15T16:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-15T16:30:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-15T17:00:00.000Z',
      consumption: 23.2
    },
    {
      date: '2023-04-15T17:30:00.000Z',
      consumption: 20.9
    },
    {
      date: '2023-04-15T18:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-15T18:30:00.000Z',
      consumption: 22.4
    },
    {
      date: '2023-04-15T19:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-15T19:30:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-15T20:00:00.000Z',
      consumption: 22
    },
    {
      date: '2023-04-15T20:30:00.000Z',
      consumption: 22.8
    },
    {
      date: '2023-04-15T21:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-15T21:30:00.000Z',
      consumption: 20.3
    },
    {
      date: '2023-04-15T22:00:00.000Z',
      consumption: 19.1
    },
    {
      date: '2023-04-15T22:30:00.000Z',
      consumption: 24
    },
    {
      date: '2023-04-15T23:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-15T23:30:00.000Z',
      consumption: 20.5
    },
    {
      date: '2023-04-16T00:00:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-16T00:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-16T01:00:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-16T01:30:00.000Z',
      consumption: 20.5
    },
    {
      date: '2023-04-16T02:00:00.000Z',
      consumption: 20.7
    },
    {
      date: '2023-04-16T02:30:00.000Z',
      consumption: 24
    },
    {
      date: '2023-04-16T03:00:00.000Z',
      consumption: 19.8
    },
    {
      date: '2023-04-16T03:30:00.000Z',
      consumption: 20.9
    },
    {
      date: '2023-04-16T04:00:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-16T04:30:00.000Z',
      consumption: 20.2
    },
    {
      date: '2023-04-16T05:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-16T05:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-16T06:00:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-16T06:30:00.000Z',
      consumption: 19.9
    },
    {
      date: '2023-04-16T07:00:00.000Z',
      consumption: 23.8
    },
    {
      date: '2023-04-16T07:30:00.000Z',
      consumption: 23.5
    },
    {
      date: '2023-04-16T08:00:00.000Z',
      consumption: 19.7
    },
    {
      date: '2023-04-16T08:30:00.000Z',
      consumption: 19.9
    },
    {
      date: '2023-04-16T09:00:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-16T09:30:00.000Z',
      consumption: 20.7
    },
    {
      date: '2023-04-16T10:00:00.000Z',
      consumption: 19.7
    },
    {
      date: '2023-04-16T10:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-16T11:00:00.000Z',
      consumption: 23.4
    },
    {
      date: '2023-04-16T11:30:00.000Z',
      consumption: 20.6
    },
    {
      date: '2023-04-16T12:00:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-16T12:30:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-16T13:00:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-16T13:30:00.000Z',
      consumption: 22.7
    },
    {
      date: '2023-04-16T14:00:00.000Z',
      consumption: 21.5
    },
    {
      date: '2023-04-16T14:30:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-16T15:00:00.000Z',
      consumption: 23.1
    },
    {
      date: '2023-04-16T15:30:00.000Z',
      consumption: 21
    },
    {
      date: '2023-04-16T16:00:00.000Z',
      consumption: 20.6
    },
    {
      date: '2023-04-16T16:30:00.000Z',
      consumption: 20.9
    },
    {
      date: '2023-04-16T17:00:00.000Z',
      consumption: 25.2
    },
    {
      date: '2023-04-16T17:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-16T18:00:00.000Z',
      consumption: 19.9
    },
    {
      date: '2023-04-16T18:30:00.000Z',
      consumption: 24.3
    },
    {
      date: '2023-04-16T19:00:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-16T19:30:00.000Z',
      consumption: 19.2
    },
    {
      date: '2023-04-16T20:00:00.000Z',
      consumption: 21.4
    },
    {
      date: '2023-04-16T20:30:00.000Z',
      consumption: 21
    },
    {
      date: '2023-04-16T21:00:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-16T21:30:00.000Z',
      consumption: 20.8
    },
    {
      date: '2023-04-16T22:00:00.000Z',
      consumption: 20.9
    },
    {
      date: '2023-04-16T22:30:00.000Z',
      consumption: 19.7
    },
    {
      date: '2023-04-16T23:00:00.000Z',
      consumption: 20.5
    },
    {
      date: '2023-04-16T23:30:00.000Z',
      consumption: 22.7
    },
    {
      date: '2023-04-17T00:00:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-17T00:30:00.000Z',
      consumption: 61.8
    },
    {
      date: '2023-04-17T01:00:00.000Z',
      consumption: 46.1
    },
    {
      date: '2023-04-17T01:30:00.000Z',
      consumption: 41.5
    },
    {
      date: '2023-04-17T02:00:00.000Z',
      consumption: 33.6
    },
    {
      date: '2023-04-17T02:30:00.000Z',
      consumption: 38.6
    },
    {
      date: '2023-04-17T03:00:00.000Z',
      consumption: 36
    },
    {
      date: '2023-04-17T03:30:00.000Z',
      consumption: 39.2
    },
    {
      date: '2023-04-17T04:00:00.000Z',
      consumption: 38.3
    },
    {
      date: '2023-04-17T04:30:00.000Z',
      consumption: 85.5
    },
    {
      date: '2023-04-17T05:00:00.000Z',
      consumption: 73.2
    },
    {
      date: '2023-04-17T05:30:00.000Z',
      consumption: 41.6
    },
    {
      date: '2023-04-17T06:00:00.000Z',
      consumption: 56.1
    },
    {
      date: '2023-04-17T06:30:00.000Z',
      consumption: 102.1
    },
    {
      date: '2023-04-17T07:00:00.000Z',
      consumption: 84.4
    },
    {
      date: '2023-04-17T07:30:00.000Z',
      consumption: 99
    },
    {
      date: '2023-04-17T08:00:00.000Z',
      consumption: 93.6
    },
    {
      date: '2023-04-17T08:30:00.000Z',
      consumption: 96.1
    },
    {
      date: '2023-04-17T09:00:00.000Z',
      consumption: 89.9
    },
    {
      date: '2023-04-17T09:30:00.000Z',
      consumption: 93.2
    },
    {
      date: '2023-04-17T10:00:00.000Z',
      consumption: 90
    },
    {
      date: '2023-04-17T10:30:00.000Z',
      consumption: 79
    },
    {
      date: '2023-04-17T11:00:00.000Z',
      consumption: 89.1
    },
    {
      date: '2023-04-17T11:30:00.000Z',
      consumption: 76.9
    },
    {
      date: '2023-04-17T12:00:00.000Z',
      consumption: 78.8
    },
    {
      date: '2023-04-17T12:30:00.000Z',
      consumption: 87.8
    },
    {
      date: '2023-04-17T13:00:00.000Z',
      consumption: 75.3
    },
    {
      date: '2023-04-17T13:30:00.000Z',
      consumption: 71.5
    },
    {
      date: '2023-04-17T14:00:00.000Z',
      consumption: 87.5
    },
    {
      date: '2023-04-17T14:30:00.000Z',
      consumption: 73.5
    },
    {
      date: '2023-04-17T15:00:00.000Z',
      consumption: 71.5
    },
    {
      date: '2023-04-17T15:30:00.000Z',
      consumption: 67
    },
    {
      date: '2023-04-17T16:00:00.000Z',
      consumption: 62
    },
    {
      date: '2023-04-17T16:30:00.000Z',
      consumption: 32.6
    },
    {
      date: '2023-04-17T17:00:00.000Z',
      consumption: 35.6
    },
    {
      date: '2023-04-17T17:30:00.000Z',
      consumption: 29.8
    },
    {
      date: '2023-04-17T18:00:00.000Z',
      consumption: 29.3
    },
    {
      date: '2023-04-17T18:30:00.000Z',
      consumption: 27.7
    },
    {
      date: '2023-04-17T19:00:00.000Z',
      consumption: 27.1
    },
    {
      date: '2023-04-17T19:30:00.000Z',
      consumption: 21.4
    },
    {
      date: '2023-04-17T20:00:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-17T20:30:00.000Z',
      consumption: 26.1
    },
    {
      date: '2023-04-17T21:00:00.000Z',
      consumption: 22.4
    },
    {
      date: '2023-04-17T21:30:00.000Z',
      consumption: 22
    },
    {
      date: '2023-04-17T22:00:00.000Z',
      consumption: 25.6
    },
    {
      date: '2023-04-17T22:30:00.000Z',
      consumption: 23.3
    },
    {
      date: '2023-04-17T23:00:00.000Z',
      consumption: 23.5
    },
    {
      date: '2023-04-17T23:30:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-18T00:00:00.000Z',
      consumption: 25.6
    },
    {
      date: '2023-04-18T00:30:00.000Z',
      consumption: 34.1
    },
    {
      date: '2023-04-18T01:00:00.000Z',
      consumption: 36.5
    },
    {
      date: '2023-04-18T01:30:00.000Z',
      consumption: 44.9
    },
    {
      date: '2023-04-18T02:00:00.000Z',
      consumption: 34.2
    },
    {
      date: '2023-04-18T02:30:00.000Z',
      consumption: 36.3
    },
    {
      date: '2023-04-18T03:00:00.000Z',
      consumption: 33.1
    },
    {
      date: '2023-04-18T03:30:00.000Z',
      consumption: 34.3
    },
    {
      date: '2023-04-18T04:00:00.000Z',
      consumption: 32.8
    },
    {
      date: '2023-04-18T04:30:00.000Z',
      consumption: 75.2
    },
    {
      date: '2023-04-18T05:00:00.000Z',
      consumption: 39.8
    },
    {
      date: '2023-04-18T05:30:00.000Z',
      consumption: 48
    },
    {
      date: '2023-04-18T06:00:00.000Z',
      consumption: 64.7
    },
    {
      date: '2023-04-18T06:30:00.000Z',
      consumption: 95.6
    },
    {
      date: '2023-04-18T07:00:00.000Z',
      consumption: 108.4
    },
    {
      date: '2023-04-18T07:30:00.000Z',
      consumption: 90.2
    },
    {
      date: '2023-04-18T08:00:00.000Z',
      consumption: 105.2
    },
    {
      date: '2023-04-18T08:30:00.000Z',
      consumption: 89.2
    },
    {
      date: '2023-04-18T09:00:00.000Z',
      consumption: 92.2
    },
    {
      date: '2023-04-18T09:30:00.000Z',
      consumption: 87.3
    },
    {
      date: '2023-04-18T10:00:00.000Z',
      consumption: 83.8
    },
    {
      date: '2023-04-18T10:30:00.000Z',
      consumption: 97.5
    },
    {
      date: '2023-04-18T11:00:00.000Z',
      consumption: 95.3
    },
    {
      date: '2023-04-18T11:30:00.000Z',
      consumption: 103.7
    },
    {
      date: '2023-04-18T12:00:00.000Z',
      consumption: 84.4
    },
    {
      date: '2023-04-18T12:30:00.000Z',
      consumption: 85.1
    },
    {
      date: '2023-04-18T13:00:00.000Z',
      consumption: 93.3
    },
    {
      date: '2023-04-18T13:30:00.000Z',
      consumption: 86.5
    },
    {
      date: '2023-04-18T14:00:00.000Z',
      consumption: 95.3
    },
    {
      date: '2023-04-18T14:30:00.000Z',
      consumption: 85.5
    },
    {
      date: '2023-04-18T15:00:00.000Z',
      consumption: 81.1
    },
    {
      date: '2023-04-18T15:30:00.000Z',
      consumption: 77.1
    },
    {
      date: '2023-04-18T16:00:00.000Z',
      consumption: 72.6
    },
    {
      date: '2023-04-18T16:30:00.000Z',
      consumption: 33.8
    },
    {
      date: '2023-04-18T17:00:00.000Z',
      consumption: 31.1
    },
    {
      date: '2023-04-18T17:30:00.000Z',
      consumption: 30.6
    },
    {
      date: '2023-04-18T18:00:00.000Z',
      consumption: 31
    },
    {
      date: '2023-04-18T18:30:00.000Z',
      consumption: 27
    },
    {
      date: '2023-04-18T19:00:00.000Z',
      consumption: 27.3
    },
    {
      date: '2023-04-18T19:30:00.000Z',
      consumption: 26.2
    },
    {
      date: '2023-04-18T20:00:00.000Z',
      consumption: 23.3
    },
    {
      date: '2023-04-18T20:30:00.000Z',
      consumption: 22.9
    },
    {
      date: '2023-04-18T21:00:00.000Z',
      consumption: 27.3
    },
    {
      date: '2023-04-18T21:30:00.000Z',
      consumption: 23.5
    },
    {
      date: '2023-04-18T22:00:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-18T22:30:00.000Z',
      consumption: 24.4
    },
    {
      date: '2023-04-18T23:00:00.000Z',
      consumption: 23.8
    },
    {
      date: '2023-04-18T23:30:00.000Z',
      consumption: 21.5
    },
    {
      date: '2023-04-19T00:00:00.000Z',
      consumption: 23.4
    },
    {
      date: '2023-04-19T00:30:00.000Z',
      consumption: 42.8
    },
    {
      date: '2023-04-19T01:00:00.000Z',
      consumption: 36
    },
    {
      date: '2023-04-19T01:30:00.000Z',
      consumption: 33
    },
    {
      date: '2023-04-19T02:00:00.000Z',
      consumption: 35.6
    },
    {
      date: '2023-04-19T02:30:00.000Z',
      consumption: 34.1
    },
    {
      date: '2023-04-19T03:00:00.000Z',
      consumption: 31.4
    },
    {
      date: '2023-04-19T03:30:00.000Z',
      consumption: 38.3
    },
    {
      date: '2023-04-19T04:00:00.000Z',
      consumption: 34.6
    },
    {
      date: '2023-04-19T04:30:00.000Z',
      consumption: 76.2
    },
    {
      date: '2023-04-19T05:00:00.000Z',
      consumption: 35
    },
    {
      date: '2023-04-19T05:30:00.000Z',
      consumption: 40.8
    },
    {
      date: '2023-04-19T06:00:00.000Z',
      consumption: 51.9
    },
    {
      date: '2023-04-19T06:30:00.000Z',
      consumption: 102.3
    },
    {
      date: '2023-04-19T07:00:00.000Z',
      consumption: 88.2
    },
    {
      date: '2023-04-19T07:30:00.000Z',
      consumption: 109.1
    },
    {
      date: '2023-04-19T08:00:00.000Z',
      consumption: 120.7
    },
    {
      date: '2023-04-19T08:30:00.000Z',
      consumption: 93.3
    },
    {
      date: '2023-04-19T09:00:00.000Z',
      consumption: 107.2
    },
    {
      date: '2023-04-19T09:30:00.000Z',
      consumption: 98.4
    },
    {
      date: '2023-04-19T10:00:00.000Z',
      consumption: 103.5
    },
    {
      date: '2023-04-19T10:30:00.000Z',
      consumption: 92.5
    },
    {
      date: '2023-04-19T11:00:00.000Z',
      consumption: 90.5
    },
    {
      date: '2023-04-19T11:30:00.000Z',
      consumption: 103.5
    },
    {
      date: '2023-04-19T12:00:00.000Z',
      consumption: 84.9
    },
    {
      date: '2023-04-19T12:30:00.000Z',
      consumption: 89.6
    },
    {
      date: '2023-04-19T13:00:00.000Z',
      consumption: 103.2
    },
    {
      date: '2023-04-19T13:30:00.000Z',
      consumption: 86.8
    },
    {
      date: '2023-04-19T14:00:00.000Z',
      consumption: 92.8
    },
    {
      date: '2023-04-19T14:30:00.000Z',
      consumption: 90.5
    },
    {
      date: '2023-04-19T15:00:00.000Z',
      consumption: 81.9
    },
    {
      date: '2023-04-19T15:30:00.000Z',
      consumption: 81.6
    },
    {
      date: '2023-04-19T16:00:00.000Z',
      consumption: 80.6
    },
    {
      date: '2023-04-19T16:30:00.000Z',
      consumption: 32.5
    },
    {
      date: '2023-04-19T17:00:00.000Z',
      consumption: 33
    },
    {
      date: '2023-04-19T17:30:00.000Z',
      consumption: 30.3
    },
    {
      date: '2023-04-19T18:00:00.000Z',
      consumption: 25.4
    },
    {
      date: '2023-04-19T18:30:00.000Z',
      consumption: 25.7
    },
    {
      date: '2023-04-19T19:00:00.000Z',
      consumption: 24.3
    },
    {
      date: '2023-04-19T19:30:00.000Z',
      consumption: 22.8
    },
    {
      date: '2023-04-19T20:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-19T20:30:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-19T21:00:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-19T21:30:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-19T22:00:00.000Z',
      consumption: 20.5
    },
    {
      date: '2023-04-19T22:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-19T23:00:00.000Z',
      consumption: 19.4
    },
    {
      date: '2023-04-19T23:30:00.000Z',
      consumption: 19.2
    },
    {
      date: '2023-04-20T00:00:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-20T00:30:00.000Z',
      consumption: 37.3
    },
    {
      date: '2023-04-20T01:00:00.000Z',
      consumption: 33.1
    },
    {
      date: '2023-04-20T01:30:00.000Z',
      consumption: 31.2
    },
    {
      date: '2023-04-20T02:00:00.000Z',
      consumption: 29
    },
    {
      date: '2023-04-20T02:30:00.000Z',
      consumption: 30.1
    },
    {
      date: '2023-04-20T03:00:00.000Z',
      consumption: 28.6
    },
    {
      date: '2023-04-20T03:30:00.000Z',
      consumption: 28.4
    },
    {
      date: '2023-04-20T04:00:00.000Z',
      consumption: 33.6
    },
    {
      date: '2023-04-20T04:30:00.000Z',
      consumption: 74.6
    },
    {
      date: '2023-04-20T05:00:00.000Z',
      consumption: 31.6
    },
    {
      date: '2023-04-20T05:30:00.000Z',
      consumption: 35.5
    },
    {
      date: '2023-04-20T06:00:00.000Z',
      consumption: 46.1
    },
    {
      date: '2023-04-20T06:30:00.000Z',
      consumption: 94.7
    },
    {
      date: '2023-04-20T07:00:00.000Z',
      consumption: 91.9
    },
    {
      date: '2023-04-20T07:30:00.000Z',
      consumption: 99.7
    },
    {
      date: '2023-04-20T08:00:00.000Z',
      consumption: 100.7
    },
    {
      date: '2023-04-20T08:30:00.000Z',
      consumption: 106.1
    },
    {
      date: '2023-04-20T09:00:00.000Z',
      consumption: 81.1
    },
    {
      date: '2023-04-20T09:30:00.000Z',
      consumption: 89.2
    },
    {
      date: '2023-04-20T10:00:00.000Z',
      consumption: 93
    },
    {
      date: '2023-04-20T10:30:00.000Z',
      consumption: 83.5
    },
    {
      date: '2023-04-20T11:00:00.000Z',
      consumption: 85.1
    },
    {
      date: '2023-04-20T11:30:00.000Z',
      consumption: 95.5
    },
    {
      date: '2023-04-20T12:00:00.000Z',
      consumption: 81.6
    },
    {
      date: '2023-04-20T12:30:00.000Z',
      consumption: 90.6
    },
    {
      date: '2023-04-20T13:00:00.000Z',
      consumption: 86.4
    },
    {
      date: '2023-04-20T13:30:00.000Z',
      consumption: 79.5
    },
    {
      date: '2023-04-20T14:00:00.000Z',
      consumption: 93.7
    },
    {
      date: '2023-04-20T14:30:00.000Z',
      consumption: 83.3
    },
    {
      date: '2023-04-20T15:00:00.000Z',
      consumption: 85.9
    },
    {
      date: '2023-04-20T15:30:00.000Z',
      consumption: 71.3
    },
    {
      date: '2023-04-20T16:00:00.000Z',
      consumption: 76.9
    },
    {
      date: '2023-04-20T16:30:00.000Z',
      consumption: 35.7
    },
    {
      date: '2023-04-20T17:00:00.000Z',
      consumption: 32.5
    },
    {
      date: '2023-04-20T17:30:00.000Z',
      consumption: 30.1
    },
    {
      date: '2023-04-20T18:00:00.000Z',
      consumption: 30
    },
    {
      date: '2023-04-20T18:30:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-20T19:00:00.000Z',
      consumption: 23.5
    },
    {
      date: '2023-04-20T19:30:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-20T20:00:00.000Z',
      consumption: 20.7
    },
    {
      date: '2023-04-20T20:30:00.000Z',
      consumption: 19.7
    },
    {
      date: '2023-04-20T21:00:00.000Z',
      consumption: 20.6
    },
    {
      date: '2023-04-20T21:30:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-20T22:00:00.000Z',
      consumption: 20.5
    },
    {
      date: '2023-04-20T22:30:00.000Z',
      consumption: 20.1
    },
    {
      date: '2023-04-20T23:00:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-20T23:30:00.000Z',
      consumption: 19.2
    },
    {
      date: '2023-04-21T00:00:00.000Z',
      consumption: 19.3
    },
    {
      date: '2023-04-21T00:30:00.000Z',
      consumption: 36.7
    },
    {
      date: '2023-04-21T01:00:00.000Z',
      consumption: 32.6
    },
    {
      date: '2023-04-21T01:30:00.000Z',
      consumption: 28.8
    },
    {
      date: '2023-04-21T02:00:00.000Z',
      consumption: 26
    },
    {
      date: '2023-04-21T02:30:00.000Z',
      consumption: 32
    },
    {
      date: '2023-04-21T03:00:00.000Z',
      consumption: 27.9
    },
    {
      date: '2023-04-21T03:30:00.000Z',
      consumption: 31.6
    },
    {
      date: '2023-04-21T04:00:00.000Z',
      consumption: 29
    },
    {
      date: '2023-04-21T04:30:00.000Z',
      consumption: 77.6
    },
    {
      date: '2023-04-21T05:00:00.000Z',
      consumption: 29.7
    },
    {
      date: '2023-04-21T05:30:00.000Z',
      consumption: 37.6
    },
    {
      date: '2023-04-21T06:00:00.000Z',
      consumption: 60
    },
    {
      date: '2023-04-21T06:30:00.000Z',
      consumption: 76.9
    },
    {
      date: '2023-04-21T07:00:00.000Z',
      consumption: 80.8
    },
    {
      date: '2023-04-21T07:30:00.000Z',
      consumption: 93.3
    },
    {
      date: '2023-04-21T08:00:00.000Z',
      consumption: 82.6
    },
    {
      date: '2023-04-21T08:30:00.000Z',
      consumption: 78.4
    },
    {
      date: '2023-04-21T09:00:00.000Z',
      consumption: 84.7
    },
    {
      date: '2023-04-21T09:30:00.000Z',
      consumption: 81.7
    },
    {
      date: '2023-04-21T10:00:00.000Z',
      consumption: 81.8
    },
    {
      date: '2023-04-21T10:30:00.000Z',
      consumption: 83.2
    },
    {
      date: '2023-04-21T11:00:00.000Z',
      consumption: 74.9
    },
    {
      date: '2023-04-21T11:30:00.000Z',
      consumption: 85.8
    },
    {
      date: '2023-04-21T12:00:00.000Z',
      consumption: 81.3
    },
    {
      date: '2023-04-21T12:30:00.000Z',
      consumption: 79.3
    },
    {
      date: '2023-04-21T13:00:00.000Z',
      consumption: 92.2
    },
    {
      date: '2023-04-21T13:30:00.000Z',
      consumption: 79
    },
    {
      date: '2023-04-21T14:00:00.000Z',
      consumption: 75.6
    },
    {
      date: '2023-04-21T14:30:00.000Z',
      consumption: 93.1
    },
    {
      date: '2023-04-21T15:00:00.000Z',
      consumption: 71.2
    },
    {
      date: '2023-04-21T15:30:00.000Z',
      consumption: 75.6
    },
    {
      date: '2023-04-21T16:00:00.000Z',
      consumption: 64.6
    },
    {
      date: '2023-04-21T16:30:00.000Z',
      consumption: 30.3
    },
    {
      date: '2023-04-21T17:00:00.000Z',
      consumption: 26.2
    },
    {
      date: '2023-04-21T17:30:00.000Z',
      consumption: 26.1
    },
    {
      date: '2023-04-21T18:00:00.000Z',
      consumption: 22.5
    },
    {
      date: '2023-04-21T18:30:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-21T19:00:00.000Z',
      consumption: 25.9
    },
    {
      date: '2023-04-21T19:30:00.000Z',
      consumption: 25.4
    },
    {
      date: '2023-04-21T20:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-21T20:30:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-21T21:00:00.000Z',
      consumption: 19.2
    },
    {
      date: '2023-04-21T21:30:00.000Z',
      consumption: 18.8
    },
    {
      date: '2023-04-21T22:00:00.000Z',
      consumption: 19.1
    },
    {
      date: '2023-04-21T22:30:00.000Z',
      consumption: 18.3
    },
    {
      date: '2023-04-21T23:00:00.000Z',
      consumption: 19.9
    },
    {
      date: '2023-04-21T23:30:00.000Z',
      consumption: 18.3
    },
    {
      date: '2023-04-22T00:00:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-22T00:30:00.000Z',
      consumption: 17.4
    },
    {
      date: '2023-04-22T01:00:00.000Z',
      consumption: 18.5
    },
    {
      date: '2023-04-22T01:30:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-22T02:00:00.000Z',
      consumption: 18.7
    },
    {
      date: '2023-04-22T02:30:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-22T03:00:00.000Z',
      consumption: 17.5
    },
    {
      date: '2023-04-22T03:30:00.000Z',
      consumption: 18.5
    },
    {
      date: '2023-04-22T04:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-22T04:30:00.000Z',
      consumption: 17.4
    },
    {
      date: '2023-04-22T05:00:00.000Z',
      consumption: 19.8
    },
    {
      date: '2023-04-22T05:30:00.000Z',
      consumption: 20
    },
    {
      date: '2023-04-22T06:00:00.000Z',
      consumption: 18.8
    },
    {
      date: '2023-04-22T06:30:00.000Z',
      consumption: 22.6
    },
    {
      date: '2023-04-22T07:00:00.000Z',
      consumption: 20.9
    },
    {
      date: '2023-04-22T07:30:00.000Z',
      consumption: 21.8
    },
    {
      date: '2023-04-22T08:00:00.000Z',
      consumption: 20.6
    },
    {
      date: '2023-04-22T08:30:00.000Z',
      consumption: 20.3
    },
    {
      date: '2023-04-22T09:00:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-22T09:30:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-22T10:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-22T10:30:00.000Z',
      consumption: 21
    },
    {
      date: '2023-04-22T11:00:00.000Z',
      consumption: 19.9
    },
    {
      date: '2023-04-22T11:30:00.000Z',
      consumption: 20.6
    },
    {
      date: '2023-04-22T12:00:00.000Z',
      consumption: 20.7
    },
    {
      date: '2023-04-22T12:30:00.000Z',
      consumption: 20.2
    },
    {
      date: '2023-04-22T13:00:00.000Z',
      consumption: 20.1
    },
    {
      date: '2023-04-22T13:30:00.000Z',
      consumption: 21
    },
    {
      date: '2023-04-22T14:00:00.000Z',
      consumption: 20.8
    },
    {
      date: '2023-04-22T14:30:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-22T15:00:00.000Z',
      consumption: 19.1
    },
    {
      date: '2023-04-22T15:30:00.000Z',
      consumption: 19.7
    },
    {
      date: '2023-04-22T16:00:00.000Z',
      consumption: 20.5
    },
    {
      date: '2023-04-22T16:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-22T17:00:00.000Z',
      consumption: 20
    },
    {
      date: '2023-04-22T17:30:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-22T18:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-22T18:30:00.000Z',
      consumption: 19
    },
    {
      date: '2023-04-22T19:00:00.000Z',
      consumption: 17.1
    },
    {
      date: '2023-04-22T19:30:00.000Z',
      consumption: 17.7
    },
    {
      date: '2023-04-22T20:00:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-22T20:30:00.000Z',
      consumption: 18.1
    },
    {
      date: '2023-04-22T21:00:00.000Z',
      consumption: 18.6
    },
    {
      date: '2023-04-22T21:30:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-22T22:00:00.000Z',
      consumption: 17.5
    },
    {
      date: '2023-04-22T22:30:00.000Z',
      consumption: 18.6
    },
    {
      date: '2023-04-22T23:00:00.000Z',
      consumption: 17.3
    },
    {
      date: '2023-04-22T23:30:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-23T00:00:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-23T00:30:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-23T01:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-23T01:30:00.000Z',
      consumption: 19.3
    },
    {
      date: '2023-04-23T02:00:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-23T02:30:00.000Z',
      consumption: 17.5
    },
    {
      date: '2023-04-23T03:00:00.000Z',
      consumption: 17.2
    },
    {
      date: '2023-04-23T03:30:00.000Z',
      consumption: 17.7
    },
    {
      date: '2023-04-23T04:00:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-23T04:30:00.000Z',
      consumption: 17.5
    },
    {
      date: '2023-04-23T05:00:00.000Z',
      consumption: 17.4
    },
    {
      date: '2023-04-23T05:30:00.000Z',
      consumption: 17.2
    },
    {
      date: '2023-04-23T06:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-23T06:30:00.000Z',
      consumption: 19.6
    },
    {
      date: '2023-04-23T07:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-23T07:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-23T08:00:00.000Z',
      consumption: 20.5
    },
    {
      date: '2023-04-23T08:30:00.000Z',
      consumption: 20.1
    },
    {
      date: '2023-04-23T09:00:00.000Z',
      consumption: 19.5
    },
    {
      date: '2023-04-23T09:30:00.000Z',
      consumption: 20.8
    },
    {
      date: '2023-04-23T10:00:00.000Z',
      consumption: 19.4
    },
    {
      date: '2023-04-23T10:30:00.000Z',
      consumption: 20.8
    },
    {
      date: '2023-04-23T11:00:00.000Z',
      consumption: 19.5
    },
    {
      date: '2023-04-23T11:30:00.000Z',
      consumption: 20.2
    },
    {
      date: '2023-04-23T12:00:00.000Z',
      consumption: 19.7
    },
    {
      date: '2023-04-23T12:30:00.000Z',
      consumption: 20.3
    },
    {
      date: '2023-04-23T13:00:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-23T13:30:00.000Z',
      consumption: 19.2
    },
    {
      date: '2023-04-23T14:00:00.000Z',
      consumption: 20.7
    },
    {
      date: '2023-04-23T14:30:00.000Z',
      consumption: 20.9
    },
    {
      date: '2023-04-23T15:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-23T15:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-23T16:00:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-23T16:30:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-23T17:00:00.000Z',
      consumption: 19.5
    },
    {
      date: '2023-04-23T17:30:00.000Z',
      consumption: 20
    },
    {
      date: '2023-04-23T18:00:00.000Z',
      consumption: 19.8
    },
    {
      date: '2023-04-23T18:30:00.000Z',
      consumption: 18.9
    },
    {
      date: '2023-04-23T19:00:00.000Z',
      consumption: 19
    },
    {
      date: '2023-04-23T19:30:00.000Z',
      consumption: 20.3
    },
    {
      date: '2023-04-23T20:00:00.000Z',
      consumption: 19.7
    },
    {
      date: '2023-04-23T20:30:00.000Z',
      consumption: 19.8
    },
    {
      date: '2023-04-23T21:00:00.000Z',
      consumption: 18.1
    },
    {
      date: '2023-04-23T21:30:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-23T22:00:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-23T22:30:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-23T23:00:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-23T23:30:00.000Z',
      consumption: 17.7
    },
    {
      date: '2023-04-24T00:00:00.000Z',
      consumption: 17.3
    },
    {
      date: '2023-04-24T00:30:00.000Z',
      consumption: 54.1
    },
    {
      date: '2023-04-24T01:00:00.000Z',
      consumption: 45.2
    },
    {
      date: '2023-04-24T01:30:00.000Z',
      consumption: 44.1
    },
    {
      date: '2023-04-24T02:00:00.000Z',
      consumption: 39.5
    },
    {
      date: '2023-04-24T02:30:00.000Z',
      consumption: 31.3
    },
    {
      date: '2023-04-24T03:00:00.000Z',
      consumption: 35.5
    },
    {
      date: '2023-04-24T03:30:00.000Z',
      consumption: 32.8
    },
    {
      date: '2023-04-24T04:00:00.000Z',
      consumption: 33.8
    },
    {
      date: '2023-04-24T04:30:00.000Z',
      consumption: 85.1
    },
    {
      date: '2023-04-24T05:00:00.000Z',
      consumption: 81.9
    },
    {
      date: '2023-04-24T05:30:00.000Z',
      consumption: 44
    },
    {
      date: '2023-04-24T06:00:00.000Z',
      consumption: 59
    },
    {
      date: '2023-04-24T06:30:00.000Z',
      consumption: 93.8
    },
    {
      date: '2023-04-24T07:00:00.000Z',
      consumption: 94.4
    },
    {
      date: '2023-04-24T07:30:00.000Z',
      consumption: 85.3
    },
    {
      date: '2023-04-24T08:00:00.000Z',
      consumption: 90.2
    },
    {
      date: '2023-04-24T08:30:00.000Z',
      consumption: 88.4
    },
    {
      date: '2023-04-24T09:00:00.000Z',
      consumption: 93.7
    },
    {
      date: '2023-04-24T09:30:00.000Z',
      consumption: 81
    },
    {
      date: '2023-04-24T10:00:00.000Z',
      consumption: 98.5
    },
    {
      date: '2023-04-24T10:30:00.000Z',
      consumption: 78.9
    },
    {
      date: '2023-04-24T11:00:00.000Z',
      consumption: 95.5
    },
    {
      date: '2023-04-24T11:30:00.000Z',
      consumption: 86.3
    },
    {
      date: '2023-04-24T12:00:00.000Z',
      consumption: 88.9
    },
    {
      date: '2023-04-24T12:30:00.000Z',
      consumption: 80.5
    },
    {
      date: '2023-04-24T13:00:00.000Z',
      consumption: 77.5
    },
    {
      date: '2023-04-24T13:30:00.000Z',
      consumption: 88.2
    },
    {
      date: '2023-04-24T14:00:00.000Z',
      consumption: 81.8
    },
    {
      date: '2023-04-24T14:30:00.000Z',
      consumption: 73.4
    },
    {
      date: '2023-04-24T15:00:00.000Z',
      consumption: 89.4
    },
    {
      date: '2023-04-24T15:30:00.000Z',
      consumption: 72.3
    },
    {
      date: '2023-04-24T16:00:00.000Z',
      consumption: 65.7
    },
    {
      date: '2023-04-24T16:30:00.000Z',
      consumption: 34.4
    },
    {
      date: '2023-04-24T17:00:00.000Z',
      consumption: 37
    },
    {
      date: '2023-04-24T17:30:00.000Z',
      consumption: 30.3
    },
    {
      date: '2023-04-24T18:00:00.000Z',
      consumption: 27.5
    },
    {
      date: '2023-04-24T18:30:00.000Z',
      consumption: 26.6
    },
    {
      date: '2023-04-24T19:00:00.000Z',
      consumption: 22.3
    },
    {
      date: '2023-04-24T19:30:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-24T20:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-24T20:30:00.000Z',
      consumption: 23.6
    },
    {
      date: '2023-04-24T21:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-24T21:30:00.000Z',
      consumption: 22.1
    },
    {
      date: '2023-04-24T22:00:00.000Z',
      consumption: 22.3
    },
    {
      date: '2023-04-24T22:30:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-24T23:00:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-24T23:30:00.000Z',
      consumption: 21.2
    },
    {
      date: '2023-04-25T00:00:00.000Z',
      consumption: 23.2
    },
    {
      date: '2023-04-25T00:30:00.000Z',
      consumption: 40.8
    },
    {
      date: '2023-04-25T01:00:00.000Z',
      consumption: 43.2
    },
    {
      date: '2023-04-25T01:30:00.000Z',
      consumption: 43.5
    },
    {
      date: '2023-04-25T02:00:00.000Z',
      consumption: 43
    },
    {
      date: '2023-04-25T02:30:00.000Z',
      consumption: 40.9
    },
    {
      date: '2023-04-25T03:00:00.000Z',
      consumption: 40.7
    },
    {
      date: '2023-04-25T03:30:00.000Z',
      consumption: 46
    },
    {
      date: '2023-04-25T04:00:00.000Z',
      consumption: 40.7
    },
    {
      date: '2023-04-25T04:30:00.000Z',
      consumption: 86.6
    },
    {
      date: '2023-04-25T05:00:00.000Z',
      consumption: 43.5
    },
    {
      date: '2023-04-25T05:30:00.000Z',
      consumption: 49.9
    },
    {
      date: '2023-04-25T06:00:00.000Z',
      consumption: 67.8
    },
    {
      date: '2023-04-25T06:30:00.000Z',
      consumption: 94.6
    },
    {
      date: '2023-04-25T07:00:00.000Z',
      consumption: 113.7
    },
    {
      date: '2023-04-25T07:30:00.000Z',
      consumption: 99.2
    },
    {
      date: '2023-04-25T08:00:00.000Z',
      consumption: 102.7
    },
    {
      date: '2023-04-25T08:30:00.000Z',
      consumption: 90.2
    },
    {
      date: '2023-04-25T09:00:00.000Z',
      consumption: 94.5
    },
    {
      date: '2023-04-25T09:30:00.000Z',
      consumption: 109.9
    },
    {
      date: '2023-04-25T10:00:00.000Z',
      consumption: 94.4
    },
    {
      date: '2023-04-25T10:30:00.000Z',
      consumption: 102.3
    },
    {
      date: '2023-04-25T11:00:00.000Z',
      consumption: 92.1
    },
    {
      date: '2023-04-25T11:30:00.000Z',
      consumption: 99.6
    },
    {
      date: '2023-04-25T12:00:00.000Z',
      consumption: 92.4
    },
    {
      date: '2023-04-25T12:30:00.000Z',
      consumption: 95.5
    },
    {
      date: '2023-04-25T13:00:00.000Z',
      consumption: 96.5
    },
    {
      date: '2023-04-25T13:30:00.000Z',
      consumption: 86
    },
    {
      date: '2023-04-25T14:00:00.000Z',
      consumption: 98.6
    },
    {
      date: '2023-04-25T14:30:00.000Z',
      consumption: 85.8
    },
    {
      date: '2023-04-25T15:00:00.000Z',
      consumption: 88
    },
    {
      date: '2023-04-25T15:30:00.000Z',
      consumption: 83.2
    },
    {
      date: '2023-04-25T16:00:00.000Z',
      consumption: 81
    },
    {
      date: '2023-04-25T16:30:00.000Z',
      consumption: 33.6
    },
    {
      date: '2023-04-25T17:00:00.000Z',
      consumption: 32.5
    },
    {
      date: '2023-04-25T17:30:00.000Z',
      consumption: 30.9
    },
    {
      date: '2023-04-25T18:00:00.000Z',
      consumption: 27.7
    },
    {
      date: '2023-04-25T18:30:00.000Z',
      consumption: 27.4
    },
    {
      date: '2023-04-25T19:00:00.000Z',
      consumption: 24.2
    },
    {
      date: '2023-04-25T19:30:00.000Z',
      consumption: 21
    },
    {
      date: '2023-04-25T20:00:00.000Z',
      consumption: 18.9
    },
    {
      date: '2023-04-25T20:30:00.000Z',
      consumption: 18.2
    },
    {
      date: '2023-04-25T21:00:00.000Z',
      consumption: 18.8
    },
    {
      date: '2023-04-25T21:30:00.000Z',
      consumption: 19
    },
    {
      date: '2023-04-25T22:00:00.000Z',
      consumption: 18.3
    },
    {
      date: '2023-04-25T22:30:00.000Z',
      consumption: 18.1
    },
    {
      date: '2023-04-25T23:00:00.000Z',
      consumption: 18.1
    },
    {
      date: '2023-04-25T23:30:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-26T00:00:00.000Z',
      consumption: 18.1
    },
    {
      date: '2023-04-26T00:30:00.000Z',
      consumption: 39.4
    },
    {
      date: '2023-04-26T01:00:00.000Z',
      consumption: 36
    },
    {
      date: '2023-04-26T01:30:00.000Z',
      consumption: 33.3
    },
    {
      date: '2023-04-26T02:00:00.000Z',
      consumption: 29.4
    },
    {
      date: '2023-04-26T02:30:00.000Z',
      consumption: 32.6
    },
    {
      date: '2023-04-26T03:00:00.000Z',
      consumption: 32.1
    },
    {
      date: '2023-04-26T03:30:00.000Z',
      consumption: 31.3
    },
    {
      date: '2023-04-26T04:00:00.000Z',
      consumption: 33.6
    },
    {
      date: '2023-04-26T04:30:00.000Z',
      consumption: 78.2
    },
    {
      date: '2023-04-26T05:00:00.000Z',
      consumption: 34.8
    },
    {
      date: '2023-04-26T05:30:00.000Z',
      consumption: 41.4
    },
    {
      date: '2023-04-26T06:00:00.000Z',
      consumption: 61.4
    },
    {
      date: '2023-04-26T06:30:00.000Z',
      consumption: 108.4
    },
    {
      date: '2023-04-26T07:00:00.000Z',
      consumption: 102.3
    },
    {
      date: '2023-04-26T07:30:00.000Z',
      consumption: 95
    },
    {
      date: '2023-04-26T08:00:00.000Z',
      consumption: 88.7
    },
    {
      date: '2023-04-26T08:30:00.000Z',
      consumption: 102.5
    },
    {
      date: '2023-04-26T09:00:00.000Z',
      consumption: 85.3
    },
    {
      date: '2023-04-26T09:30:00.000Z',
      consumption: 99.5
    },
    {
      date: '2023-04-26T10:00:00.000Z',
      consumption: 93.4
    },
    {
      date: '2023-04-26T10:30:00.000Z',
      consumption: 83.8
    },
    {
      date: '2023-04-26T11:00:00.000Z',
      consumption: 97.8
    },
    {
      date: '2023-04-26T11:30:00.000Z',
      consumption: 91
    },
    {
      date: '2023-04-26T12:00:00.000Z',
      consumption: 95.9
    },
    {
      date: '2023-04-26T12:30:00.000Z',
      consumption: 82.5
    },
    {
      date: '2023-04-26T13:00:00.000Z',
      consumption: 95.1
    },
    {
      date: '2023-04-26T13:30:00.000Z',
      consumption: 91
    },
    {
      date: '2023-04-26T14:00:00.000Z',
      consumption: 85.5
    },
    {
      date: '2023-04-26T14:30:00.000Z',
      consumption: 97.2
    },
    {
      date: '2023-04-26T15:00:00.000Z',
      consumption: 85.7
    },
    {
      date: '2023-04-26T15:30:00.000Z',
      consumption: 80.6
    },
    {
      date: '2023-04-26T16:00:00.000Z',
      consumption: 81
    },
    {
      date: '2023-04-26T16:30:00.000Z',
      consumption: 35.6
    },
    {
      date: '2023-04-26T17:00:00.000Z',
      consumption: 32.5
    },
    {
      date: '2023-04-26T17:30:00.000Z',
      consumption: 28.9
    },
    {
      date: '2023-04-26T18:00:00.000Z',
      consumption: 26.5
    },
    {
      date: '2023-04-26T18:30:00.000Z',
      consumption: 25
    },
    {
      date: '2023-04-26T19:00:00.000Z',
      consumption: 22.9
    },
    {
      date: '2023-04-26T19:30:00.000Z',
      consumption: 23.1
    },
    {
      date: '2023-04-26T20:00:00.000Z',
      consumption: 21.9
    },
    {
      date: '2023-04-26T20:30:00.000Z',
      consumption: 20.3
    },
    {
      date: '2023-04-26T21:00:00.000Z',
      consumption: 18.6
    },
    {
      date: '2023-04-26T21:30:00.000Z',
      consumption: 18.2
    },
    {
      date: '2023-04-26T22:00:00.000Z',
      consumption: 18.4
    },
    {
      date: '2023-04-26T22:30:00.000Z',
      consumption: 18.1
    },
    {
      date: '2023-04-26T23:00:00.000Z',
      consumption: 18.3
    },
    {
      date: '2023-04-26T23:30:00.000Z',
      consumption: 18.3
    },
    {
      date: '2023-04-27T00:00:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-27T00:30:00.000Z',
      consumption: 37.2
    },
    {
      date: '2023-04-27T01:00:00.000Z',
      consumption: 33.1
    },
    {
      date: '2023-04-27T01:30:00.000Z',
      consumption: 33.7
    },
    {
      date: '2023-04-27T02:00:00.000Z',
      consumption: 29.8
    },
    {
      date: '2023-04-27T02:30:00.000Z',
      consumption: 31.3
    },
    {
      date: '2023-04-27T03:00:00.000Z',
      consumption: 30.4
    },
    {
      date: '2023-04-27T03:30:00.000Z',
      consumption: 32.7
    },
    {
      date: '2023-04-27T04:00:00.000Z',
      consumption: 30.7
    },
    {
      date: '2023-04-27T04:30:00.000Z',
      consumption: 81
    },
    {
      date: '2023-04-27T05:00:00.000Z',
      consumption: 36.4
    },
    {
      date: '2023-04-27T05:30:00.000Z',
      consumption: 47.2
    },
    {
      date: '2023-04-27T06:00:00.000Z',
      consumption: 67.3
    },
    {
      date: '2023-04-27T06:30:00.000Z',
      consumption: 89.3
    },
    {
      date: '2023-04-27T07:00:00.000Z',
      consumption: 82.9
    },
    {
      date: '2023-04-27T07:30:00.000Z',
      consumption: 107.1
    },
    {
      date: '2023-04-27T08:00:00.000Z',
      consumption: 121.1
    },
    {
      date: '2023-04-27T08:30:00.000Z',
      consumption: 103.7
    },
    {
      date: '2023-04-27T09:00:00.000Z',
      consumption: 89.7
    },
    {
      date: '2023-04-27T09:30:00.000Z',
      consumption: 99.2
    },
    {
      date: '2023-04-27T10:00:00.000Z',
      consumption: 89.7
    },
    {
      date: '2023-04-27T10:30:00.000Z',
      consumption: 90.8
    },
    {
      date: '2023-04-27T11:00:00.000Z',
      consumption: 96.5
    },
    {
      date: '2023-04-27T11:30:00.000Z',
      consumption: 91.6
    },
    {
      date: '2023-04-27T12:00:00.000Z',
      consumption: 98
    },
    {
      date: '2023-04-27T12:30:00.000Z',
      consumption: 81.3
    },
    {
      date: '2023-04-27T13:00:00.000Z',
      consumption: 82.3
    },
    {
      date: '2023-04-27T13:30:00.000Z',
      consumption: 87.5
    },
    {
      date: '2023-04-27T14:00:00.000Z',
      consumption: 79.3
    },
    {
      date: '2023-04-27T14:30:00.000Z',
      consumption: 83
    },
    {
      date: '2023-04-27T15:00:00.000Z',
      consumption: 87.5
    },
    {
      date: '2023-04-27T15:30:00.000Z',
      consumption: 73.9
    },
    {
      date: '2023-04-27T16:00:00.000Z',
      consumption: 65.7
    },
    {
      date: '2023-04-27T16:30:00.000Z',
      consumption: 35.3
    },
    {
      date: '2023-04-27T17:00:00.000Z',
      consumption: 32.7
    },
    {
      date: '2023-04-27T17:30:00.000Z',
      consumption: 27.1
    },
    {
      date: '2023-04-27T18:00:00.000Z',
      consumption: 27.7
    },
    {
      date: '2023-04-27T18:30:00.000Z',
      consumption: 24.6
    },
    {
      date: '2023-04-27T19:00:00.000Z',
      consumption: 20.8
    },
    {
      date: '2023-04-27T19:30:00.000Z',
      consumption: 18.9
    },
    {
      date: '2023-04-27T20:00:00.000Z',
      consumption: 19.2
    },
    {
      date: '2023-04-27T20:30:00.000Z',
      consumption: 19.5
    },
    {
      date: '2023-04-27T21:00:00.000Z',
      consumption: 19.4
    },
    {
      date: '2023-04-27T21:30:00.000Z',
      consumption: 19.2
    },
    {
      date: '2023-04-27T22:00:00.000Z',
      consumption: 18.9
    },
    {
      date: '2023-04-27T22:30:00.000Z',
      consumption: 18.6
    },
    {
      date: '2023-04-27T23:00:00.000Z',
      consumption: 18.4
    },
    {
      date: '2023-04-27T23:30:00.000Z',
      consumption: 18.5
    },
    {
      date: '2023-04-28T00:00:00.000Z',
      consumption: 18.6
    },
    {
      date: '2023-04-28T00:30:00.000Z',
      consumption: 36.2
    },
    {
      date: '2023-04-28T01:00:00.000Z',
      consumption: 29.1
    },
    {
      date: '2023-04-28T01:30:00.000Z',
      consumption: 30.6
    },
    {
      date: '2023-04-28T02:00:00.000Z',
      consumption: 27.4
    },
    {
      date: '2023-04-28T02:30:00.000Z',
      consumption: 26.4
    },
    {
      date: '2023-04-28T03:00:00.000Z',
      consumption: 29
    },
    {
      date: '2023-04-28T03:30:00.000Z',
      consumption: 29.8
    },
    {
      date: '2023-04-28T04:00:00.000Z',
      consumption: 29.9
    },
    {
      date: '2023-04-28T04:30:00.000Z',
      consumption: 71.1
    },
    {
      date: '2023-04-28T05:00:00.000Z',
      consumption: 30.5
    },
    {
      date: '2023-04-28T05:30:00.000Z',
      consumption: 34.5
    },
    {
      date: '2023-04-28T06:00:00.000Z',
      consumption: 60.8
    },
    {
      date: '2023-04-28T06:30:00.000Z',
      consumption: 82.4
    },
    {
      date: '2023-04-28T07:00:00.000Z',
      consumption: 83.4
    },
    {
      date: '2023-04-28T07:30:00.000Z',
      consumption: 89.6
    },
    {
      date: '2023-04-28T08:00:00.000Z',
      consumption: 96.9
    },
    {
      date: '2023-04-28T08:30:00.000Z',
      consumption: 83.8
    },
    {
      date: '2023-04-28T09:00:00.000Z',
      consumption: 86.5
    },
    {
      date: '2023-04-28T09:30:00.000Z',
      consumption: 93.2
    },
    {
      date: '2023-04-28T10:00:00.000Z',
      consumption: 85.8
    },
    {
      date: '2023-04-28T10:30:00.000Z',
      consumption: 80.4
    },
    {
      date: '2023-04-28T11:00:00.000Z',
      consumption: 87.3
    },
    {
      date: '2023-04-28T11:30:00.000Z',
      consumption: 83.6
    },
    {
      date: '2023-04-28T12:00:00.000Z',
      consumption: 81.9
    },
    {
      date: '2023-04-28T12:30:00.000Z',
      consumption: 78.2
    },
    {
      date: '2023-04-28T13:00:00.000Z',
      consumption: 76.7
    },
    {
      date: '2023-04-28T13:30:00.000Z',
      consumption: 81.9
    },
    {
      date: '2023-04-28T14:00:00.000Z',
      consumption: 73.2
    },
    {
      date: '2023-04-28T14:30:00.000Z',
      consumption: 70
    },
    {
      date: '2023-04-28T15:00:00.000Z',
      consumption: 82.5
    },
    {
      date: '2023-04-28T15:30:00.000Z',
      consumption: 65.4
    },
    {
      date: '2023-04-28T16:00:00.000Z',
      consumption: 66.7
    },
    {
      date: '2023-04-28T16:30:00.000Z',
      consumption: 30.9
    },
    {
      date: '2023-04-28T17:00:00.000Z',
      consumption: 26.2
    },
    {
      date: '2023-04-28T17:30:00.000Z',
      consumption: 25.5
    },
    {
      date: '2023-04-28T18:00:00.000Z',
      consumption: 22.3
    },
    {
      date: '2023-04-28T18:30:00.000Z',
      consumption: 21.7
    },
    {
      date: '2023-04-28T19:00:00.000Z',
      consumption: 19.8
    },
    {
      date: '2023-04-28T19:30:00.000Z',
      consumption: 19.2
    },
    {
      date: '2023-04-28T20:00:00.000Z',
      consumption: 18.4
    },
    {
      date: '2023-04-28T20:30:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-28T21:00:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-28T21:30:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-28T22:00:00.000Z',
      consumption: 18.2
    },
    {
      date: '2023-04-28T22:30:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-28T23:00:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-28T23:30:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-29T00:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-29T00:30:00.000Z',
      consumption: 17.5
    },
    {
      date: '2023-04-29T01:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-29T01:30:00.000Z',
      consumption: 17.3
    },
    {
      date: '2023-04-29T02:00:00.000Z',
      consumption: 18.1
    },
    {
      date: '2023-04-29T02:30:00.000Z',
      consumption: 18.2
    },
    {
      date: '2023-04-29T03:00:00.000Z',
      consumption: 17.3
    },
    {
      date: '2023-04-29T03:30:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-29T04:00:00.000Z',
      consumption: 17.5
    },
    {
      date: '2023-04-29T04:30:00.000Z',
      consumption: 17.2
    },
    {
      date: '2023-04-29T05:00:00.000Z',
      consumption: 17.1
    },
    {
      date: '2023-04-29T05:30:00.000Z',
      consumption: 18.8
    },
    {
      date: '2023-04-29T06:00:00.000Z',
      consumption: 19.6
    },
    {
      date: '2023-04-29T06:30:00.000Z',
      consumption: 19.7
    },
    {
      date: '2023-04-29T07:00:00.000Z',
      consumption: 20.9
    },
    {
      date: '2023-04-29T07:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2023-04-29T08:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2023-04-29T08:30:00.000Z',
      consumption: 22.6
    },
    {
      date: '2023-04-29T09:00:00.000Z',
      consumption: 22.2
    },
    {
      date: '2023-04-29T09:30:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-29T10:00:00.000Z',
      consumption: 21
    },
    {
      date: '2023-04-29T10:30:00.000Z',
      consumption: 20.6
    },
    {
      date: '2023-04-29T11:00:00.000Z',
      consumption: 21.3
    },
    {
      date: '2023-04-29T11:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2023-04-29T12:00:00.000Z',
      consumption: 20.1
    },
    {
      date: '2023-04-29T12:30:00.000Z',
      consumption: 19.5
    },
    {
      date: '2023-04-29T13:00:00.000Z',
      consumption: 18.5
    },
    {
      date: '2023-04-29T13:30:00.000Z',
      consumption: 17.7
    },
    {
      date: '2023-04-29T14:00:00.000Z',
      consumption: 18.2
    },
    {
      date: '2023-04-29T14:30:00.000Z',
      consumption: 17.1
    },
    {
      date: '2023-04-29T15:00:00.000Z',
      consumption: 16.3
    },
    {
      date: '2023-04-29T15:30:00.000Z',
      consumption: 16.3
    },
    {
      date: '2023-04-29T16:00:00.000Z',
      consumption: 16.4
    },
    {
      date: '2023-04-29T16:30:00.000Z',
      consumption: 16.5
    },
    {
      date: '2023-04-29T17:00:00.000Z',
      consumption: 16.6
    },
    {
      date: '2023-04-29T17:30:00.000Z',
      consumption: 17
    },
    {
      date: '2023-04-29T18:00:00.000Z',
      consumption: 17.2
    },
    {
      date: '2023-04-29T18:30:00.000Z',
      consumption: 17.2
    },
    {
      date: '2023-04-29T19:00:00.000Z',
      consumption: 17.5
    },
    {
      date: '2023-04-29T19:30:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-29T20:00:00.000Z',
      consumption: 17.2
    },
    {
      date: '2023-04-29T20:30:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-29T21:00:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-29T21:30:00.000Z',
      consumption: 18.1
    },
    {
      date: '2023-04-29T22:00:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-29T22:30:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-29T23:00:00.000Z',
      consumption: 17.5
    },
    {
      date: '2023-04-29T23:30:00.000Z',
      consumption: 17.5
    },
    {
      date: '2023-04-30T00:00:00.000Z',
      consumption: 18.2
    },
    {
      date: '2023-04-30T00:30:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-30T01:00:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-30T01:30:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-30T02:00:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-30T02:30:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-30T03:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-30T03:30:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-30T04:00:00.000Z',
      consumption: 17.4
    },
    {
      date: '2023-04-30T04:30:00.000Z',
      consumption: 17.7
    },
    {
      date: '2023-04-30T05:00:00.000Z',
      consumption: 17.3
    },
    {
      date: '2023-04-30T05:30:00.000Z',
      consumption: 17.3
    },
    {
      date: '2023-04-30T06:00:00.000Z',
      consumption: 17.4
    },
    {
      date: '2023-04-30T06:30:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-30T07:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-30T07:30:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-30T08:00:00.000Z',
      consumption: 17.7
    },
    {
      date: '2023-04-30T08:30:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-30T09:00:00.000Z',
      consumption: 17.7
    },
    {
      date: '2023-04-30T09:30:00.000Z',
      consumption: 17.1
    },
    {
      date: '2023-04-30T10:00:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-30T10:30:00.000Z',
      consumption: 18.2
    },
    {
      date: '2023-04-30T11:00:00.000Z',
      consumption: 17.7
    },
    {
      date: '2023-04-30T11:30:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-30T12:00:00.000Z',
      consumption: 18.3
    },
    {
      date: '2023-04-30T12:30:00.000Z',
      consumption: 18
    },
    {
      date: '2023-04-30T13:00:00.000Z',
      consumption: 18.3
    },
    {
      date: '2023-04-30T13:30:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-30T14:00:00.000Z',
      consumption: 18.1
    },
    {
      date: '2023-04-30T14:30:00.000Z',
      consumption: 18.7
    },
    {
      date: '2023-04-30T15:00:00.000Z',
      consumption: 19.2
    },
    {
      date: '2023-04-30T15:30:00.000Z',
      consumption: 19.8
    },
    {
      date: '2023-04-30T16:00:00.000Z',
      consumption: 19.6
    },
    {
      date: '2023-04-30T16:30:00.000Z',
      consumption: 17.5
    },
    {
      date: '2023-04-30T17:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2023-04-30T17:30:00.000Z',
      consumption: 18.2
    },
    {
      date: '2023-04-30T18:00:00.000Z',
      consumption: 17.4
    },
    {
      date: '2023-04-30T18:30:00.000Z',
      consumption: 18.6
    },
    {
      date: '2023-04-30T19:00:00.000Z',
      consumption: 19.2
    },
    {
      date: '2023-04-30T19:30:00.000Z',
      consumption: 18.9
    },
    {
      date: '2023-04-30T20:00:00.000Z',
      consumption: 18.9
    },
    {
      date: '2023-04-30T20:30:00.000Z',
      consumption: 18.6
    },
    {
      date: '2023-04-30T21:00:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-30T21:30:00.000Z',
      consumption: 17.7
    },
    {
      date: '2023-04-30T22:00:00.000Z',
      consumption: 18.2
    },
    {
      date: '2023-04-30T22:30:00.000Z',
      consumption: 17.6
    },
    {
      date: '2023-04-30T23:00:00.000Z',
      consumption: 17.9
    },
    {
      date: '2023-04-30T23:30:00.000Z',
      consumption: 17.9
    }
  ],
  nightConsumption: 15601.399999999998,
  dayConsumption: 46659.99999999999,
  maxDemand: 242.2,
  peakLoad: 36.93477889461545
};

const halfHourlyConsumptionPrev = {
  accountId: '655fcf07b7d392001404536f',
  totalConsumption: 30283.283999999923,
  granularity: 'halfhourly',
  startDate: '2022-04-01T00:00:00.000Z',
  endDate: '2022-04-30T23:59:59.999Z',
  data: [
    {
      date: '2022-04-01T00:00:00.000Z',
      consumption: 29.9
    },
    {
      date: '2022-04-01T00:30:00.000Z',
      consumption: 32.4
    },
    {
      date: '2022-04-01T01:00:00.000Z',
      consumption: 32
    },
    {
      date: '2022-04-01T01:30:00.000Z',
      consumption: 46.7
    },
    {
      date: '2022-04-01T02:00:00.000Z',
      consumption: 62.3
    },
    {
      date: '2022-04-01T02:30:00.000Z',
      consumption: 73.2
    },
    {
      date: '2022-04-01T03:00:00.000Z',
      consumption: 73.7
    },
    {
      date: '2022-04-01T03:30:00.000Z',
      consumption: 83.5
    },
    {
      date: '2022-04-01T04:00:00.000Z',
      consumption: 77.2
    },
    {
      date: '2022-04-01T04:30:00.000Z',
      consumption: 69.4
    },
    {
      date: '2022-04-01T05:00:00.000Z',
      consumption: 86.1
    },
    {
      date: '2022-04-01T05:30:00.000Z',
      consumption: 73.4
    },
    {
      date: '2022-04-01T06:00:00.000Z',
      consumption: 69
    },
    {
      date: '2022-04-01T06:30:00.000Z',
      consumption: 32.2
    },
    {
      date: '2022-04-01T07:00:00.000Z',
      consumption: 23.1
    },
    {
      date: '2022-04-01T07:30:00.000Z',
      consumption: 22.6
    },
    {
      date: '2022-04-01T08:00:00.000Z',
      consumption: 32.4
    },
    {
      date: '2022-04-01T08:30:00.000Z',
      consumption: 20.5
    },
    {
      date: '2022-04-01T09:00:00.000Z',
      consumption: 19.3
    },
    {
      date: '2022-04-01T09:30:00.000Z',
      consumption: 30.2
    },
    {
      date: '2022-04-01T10:00:00.000Z',
      consumption: 18.7
    },
    {
      date: '2022-04-01T10:30:00.000Z',
      consumption: 19.7
    },
    {
      date: '2022-04-01T11:00:00.000Z',
      consumption: 28.6
    },
    {
      date: '2022-04-01T11:30:00.000Z',
      consumption: 18.9
    },
    {
      date: '2022-04-01T12:00:00.000Z',
      consumption: 18
    },
    {
      date: '2022-04-01T12:30:00.000Z',
      consumption: 28.6
    },
    {
      date: '2022-04-01T13:00:00.000Z',
      consumption: 17.3
    },
    {
      date: '2022-04-01T13:30:00.000Z',
      consumption: 10.9
    },
    {
      date: '2022-04-01T14:00:00.000Z',
      consumption: 22.7
    },
    {
      date: '2022-04-01T14:30:00.000Z',
      consumption: 12
    },
    {
      date: '2022-04-01T15:00:00.000Z',
      consumption: 12
    },
    {
      date: '2022-04-01T15:30:00.000Z',
      consumption: 21.1
    },
    {
      date: '2022-04-01T16:00:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-01T16:30:00.000Z',
      consumption: 10.3
    },
    {
      date: '2022-04-01T17:00:00.000Z',
      consumption: 20.7
    },
    {
      date: '2022-04-01T17:30:00.000Z',
      consumption: 10.1
    },
    {
      date: '2022-04-01T18:00:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-01T18:30:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-01T19:00:00.000Z',
      consumption: 21.9
    },
    {
      date: '2022-04-01T19:30:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-01T20:00:00.000Z',
      consumption: 10.7
    },
    {
      date: '2022-04-01T20:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2022-04-01T21:00:00.000Z',
      consumption: 11.6
    },
    {
      date: '2022-04-01T21:30:00.000Z',
      consumption: 12.5
    },
    {
      date: '2022-04-01T22:00:00.000Z',
      consumption: 22.7
    },
    {
      date: '2022-04-01T22:30:00.000Z',
      consumption: 11.1
    },
    {
      date: '2022-04-01T23:00:00.000Z',
      consumption: 11.7
    },
    {
      date: '2022-04-01T23:30:00.000Z',
      consumption: 22.5
    },
    {
      date: '2022-04-02T00:00:00.000Z',
      consumption: 11.9
    },
    {
      date: '2022-04-02T00:30:00.000Z',
      consumption: 11
    },
    {
      date: '2022-04-02T01:00:00.000Z',
      consumption: 23.4
    },
    {
      date: '2022-04-02T01:30:00.000Z',
      consumption: 12.1
    },
    {
      date: '2022-04-02T02:00:00.000Z',
      consumption: 11.5
    },
    {
      date: '2022-04-02T02:30:00.000Z',
      consumption: 20.6
    },
    {
      date: '2022-04-02T03:00:00.000Z',
      consumption: 13.1
    },
    {
      date: '2022-04-02T03:30:00.000Z',
      consumption: 11.7
    },
    {
      date: '2022-04-02T04:00:00.000Z',
      consumption: 19.2
    },
    {
      date: '2022-04-02T04:30:00.000Z',
      consumption: 14.9
    },
    {
      date: '2022-04-02T05:00:00.000Z',
      consumption: 11.5
    },
    {
      date: '2022-04-02T05:30:00.000Z',
      consumption: 16.3
    },
    {
      date: '2022-04-02T06:00:00.000Z',
      consumption: 18.2
    },
    {
      date: '2022-04-02T06:30:00.000Z',
      consumption: 12.2
    },
    {
      date: '2022-04-02T07:00:00.000Z',
      consumption: 24.1
    },
    {
      date: '2022-04-02T07:30:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-02T08:00:00.000Z',
      consumption: 12
    },
    {
      date: '2022-04-02T08:30:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-02T09:00:00.000Z',
      consumption: 19.9
    },
    {
      date: '2022-04-02T09:30:00.000Z',
      consumption: 11.1
    },
    {
      date: '2022-04-02T10:00:00.000Z',
      consumption: 10.8
    },
    {
      date: '2022-04-02T10:30:00.000Z',
      consumption: 20
    },
    {
      date: '2022-04-02T11:00:00.000Z',
      consumption: 11
    },
    {
      date: '2022-04-02T11:30:00.000Z',
      consumption: 9.9
    },
    {
      date: '2022-04-02T12:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2022-04-02T12:30:00.000Z',
      consumption: 11.1
    },
    {
      date: '2022-04-02T13:00:00.000Z',
      consumption: 9.4
    },
    {
      date: '2022-04-02T13:30:00.000Z',
      consumption: 18.8
    },
    {
      date: '2022-04-02T14:00:00.000Z',
      consumption: 9.9
    },
    {
      date: '2022-04-02T14:30:00.000Z',
      consumption: 11.4
    },
    {
      date: '2022-04-02T15:00:00.000Z',
      consumption: 20.2
    },
    {
      date: '2022-04-02T15:30:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-02T16:00:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-02T16:30:00.000Z',
      consumption: 19.6
    },
    {
      date: '2022-04-02T17:00:00.000Z',
      consumption: 9.9
    },
    {
      date: '2022-04-02T17:30:00.000Z',
      consumption: 9.8
    },
    {
      date: '2022-04-02T18:00:00.000Z',
      consumption: 9.4
    },
    {
      date: '2022-04-02T18:30:00.000Z',
      consumption: 19.3
    },
    {
      date: '2022-04-02T19:00:00.000Z',
      consumption: 9.5
    },
    {
      date: '2022-04-02T19:30:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-02T20:00:00.000Z',
      consumption: 19.7
    },
    {
      date: '2022-04-02T20:30:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-02T21:00:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-02T21:30:00.000Z',
      consumption: 19.8
    },
    {
      date: '2022-04-02T22:00:00.000Z',
      consumption: 10.8
    },
    {
      date: '2022-04-02T22:30:00.000Z',
      consumption: 10.3
    },
    {
      date: '2022-04-02T23:00:00.000Z',
      consumption: 20
    },
    {
      date: '2022-04-02T23:30:00.000Z',
      consumption: 9.8
    },
    {
      date: '2022-04-03T00:00:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-03T00:30:00.000Z',
      consumption: 19.7
    },
    {
      date: '2022-04-03T01:00:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-03T01:30:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-03T02:00:00.000Z',
      consumption: 25.1
    },
    {
      date: '2022-04-03T02:30:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-03T03:00:00.000Z',
      consumption: 14.5
    },
    {
      date: '2022-04-03T03:30:00.000Z',
      consumption: 21.9
    },
    {
      date: '2022-04-03T04:00:00.000Z',
      consumption: 13.1
    },
    {
      date: '2022-04-03T04:30:00.000Z',
      consumption: 11.8
    },
    {
      date: '2022-04-03T05:00:00.000Z',
      consumption: 23.5
    },
    {
      date: '2022-04-03T05:30:00.000Z',
      consumption: 11.7
    },
    {
      date: '2022-04-03T06:00:00.000Z',
      consumption: 12.8
    },
    {
      date: '2022-04-03T06:30:00.000Z',
      consumption: 23.1
    },
    {
      date: '2022-04-03T07:00:00.000Z',
      consumption: 12
    },
    {
      date: '2022-04-03T07:30:00.000Z',
      consumption: 12.4
    },
    {
      date: '2022-04-03T08:00:00.000Z',
      consumption: 23.7
    },
    {
      date: '2022-04-03T08:30:00.000Z',
      consumption: 11.7
    },
    {
      date: '2022-04-03T09:00:00.000Z',
      consumption: 12.5
    },
    {
      date: '2022-04-03T09:30:00.000Z',
      consumption: 21.3
    },
    {
      date: '2022-04-03T10:00:00.000Z',
      consumption: 11.2
    },
    {
      date: '2022-04-03T10:30:00.000Z',
      consumption: 12.3
    },
    {
      date: '2022-04-03T11:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2022-04-03T11:30:00.000Z',
      consumption: 10.9
    },
    {
      date: '2022-04-03T12:00:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-03T12:30:00.000Z',
      consumption: 20.9
    },
    {
      date: '2022-04-03T13:00:00.000Z',
      consumption: 10.6
    },
    {
      date: '2022-04-03T13:30:00.000Z',
      consumption: 11.4
    },
    {
      date: '2022-04-03T14:00:00.000Z',
      consumption: 20.8
    },
    {
      date: '2022-04-03T14:30:00.000Z',
      consumption: 10.1
    },
    {
      date: '2022-04-03T15:00:00.000Z',
      consumption: 11.5
    },
    {
      date: '2022-04-03T15:30:00.000Z',
      consumption: 15.5
    },
    {
      date: '2022-04-03T16:00:00.000Z',
      consumption: 17
    },
    {
      date: '2022-04-03T16:30:00.000Z',
      consumption: 11
    },
    {
      date: '2022-04-03T17:00:00.000Z',
      consumption: 11.4
    },
    {
      date: '2022-04-03T17:30:00.000Z',
      consumption: 19.8
    },
    {
      date: '2022-04-03T18:00:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-03T18:30:00.000Z',
      consumption: 10.6
    },
    {
      date: '2022-04-03T19:00:00.000Z',
      consumption: 22.4
    },
    {
      date: '2022-04-03T19:30:00.000Z',
      consumption: 10.9
    },
    {
      date: '2022-04-03T20:00:00.000Z',
      consumption: 10.5
    },
    {
      date: '2022-04-03T20:30:00.000Z',
      consumption: 21.7
    },
    {
      date: '2022-04-03T21:00:00.000Z',
      consumption: 12.5
    },
    {
      date: '2022-04-03T21:30:00.000Z',
      consumption: 11.1
    },
    {
      date: '2022-04-03T22:00:00.000Z',
      consumption: 23.4
    },
    {
      date: '2022-04-03T22:30:00.000Z',
      consumption: 10.8
    },
    {
      date: '2022-04-03T23:00:00.000Z',
      consumption: 10.8
    },
    {
      date: '2022-04-03T23:30:00.000Z',
      consumption: 40.9
    },
    {
      date: '2022-04-04T00:00:00.000Z',
      consumption: 36.6
    },
    {
      date: '2022-04-04T00:30:00.000Z',
      consumption: 35.3
    },
    {
      date: '2022-04-04T01:00:00.000Z',
      consumption: 51.4
    },
    {
      date: '2022-04-04T01:30:00.000Z',
      consumption: 37.6
    },
    {
      date: '2022-04-04T02:00:00.000Z',
      consumption: 33.9
    },
    {
      date: '2022-04-04T02:30:00.000Z',
      consumption: 66.6
    },
    {
      date: '2022-04-04T03:00:00.000Z',
      consumption: 78.7
    },
    {
      date: '2022-04-04T03:30:00.000Z',
      consumption: 79.1
    },
    {
      date: '2022-04-04T04:00:00.000Z',
      consumption: 69.7
    },
    {
      date: '2022-04-04T04:30:00.000Z',
      consumption: 65.1
    },
    {
      date: '2022-04-04T05:00:00.000Z',
      consumption: 63.5
    },
    {
      date: '2022-04-04T05:30:00.000Z',
      consumption: 86.3
    },
    {
      date: '2022-04-04T06:00:00.000Z',
      consumption: 70.5
    },
    {
      date: '2022-04-04T06:30:00.000Z',
      consumption: 18.6
    },
    {
      date: '2022-04-04T07:00:00.000Z',
      consumption: 31.5
    },
    {
      date: '2022-04-04T07:30:00.000Z',
      consumption: 19.4
    },
    {
      date: '2022-04-04T08:00:00.000Z',
      consumption: 16.3
    },
    {
      date: '2022-04-04T08:30:00.000Z',
      consumption: 31.4
    },
    {
      date: '2022-04-04T09:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2022-04-04T09:30:00.000Z',
      consumption: 18.9
    },
    {
      date: '2022-04-04T10:00:00.000Z',
      consumption: 30.4
    },
    {
      date: '2022-04-04T10:30:00.000Z',
      consumption: 18.8
    },
    {
      date: '2022-04-04T11:00:00.000Z',
      consumption: 18.6
    },
    {
      date: '2022-04-04T11:30:00.000Z',
      consumption: 30.3
    },
    {
      date: '2022-04-04T12:00:00.000Z',
      consumption: 20.4
    },
    {
      date: '2022-04-04T12:30:00.000Z',
      consumption: 19.9
    },
    {
      date: '2022-04-04T13:00:00.000Z',
      consumption: 30.6
    },
    {
      date: '2022-04-04T13:30:00.000Z',
      consumption: 15
    },
    {
      date: '2022-04-04T14:00:00.000Z',
      consumption: 15.5
    },
    {
      date: '2022-04-04T14:30:00.000Z',
      consumption: 24.8
    },
    {
      date: '2022-04-04T15:00:00.000Z',
      consumption: 14.2
    },
    {
      date: '2022-04-04T15:30:00.000Z',
      consumption: 12.3
    },
    {
      date: '2022-04-04T16:00:00.000Z',
      consumption: 22.6
    },
    {
      date: '2022-04-04T16:30:00.000Z',
      consumption: 12.1
    },
    {
      date: '2022-04-04T17:00:00.000Z',
      consumption: 11
    },
    {
      date: '2022-04-04T17:30:00.000Z',
      consumption: 12.7
    },
    {
      date: '2022-04-04T18:00:00.000Z',
      consumption: 19.7
    },
    {
      date: '2022-04-04T18:30:00.000Z',
      consumption: 11.4
    },
    {
      date: '2022-04-04T19:00:00.000Z',
      consumption: 11.2
    },
    {
      date: '2022-04-04T19:30:00.000Z',
      consumption: 21.4
    },
    {
      date: '2022-04-04T20:00:00.000Z',
      consumption: 12.1
    },
    {
      date: '2022-04-04T20:30:00.000Z',
      consumption: 10.7
    },
    {
      date: '2022-04-04T21:00:00.000Z',
      consumption: 21
    },
    {
      date: '2022-04-04T21:30:00.000Z',
      consumption: 10.8
    },
    {
      date: '2022-04-04T22:00:00.000Z',
      consumption: 13.2
    },
    {
      date: '2022-04-04T22:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2022-04-04T23:00:00.000Z',
      consumption: 11.2
    },
    {
      date: '2022-04-04T23:30:00.000Z',
      consumption: 28.4
    },
    {
      date: '2022-04-05T00:00:00.000Z',
      consumption: 44.1
    },
    {
      date: '2022-04-05T00:30:00.000Z',
      consumption: 41.9
    },
    {
      date: '2022-04-05T01:00:00.000Z',
      consumption: 43.9
    },
    {
      date: '2022-04-05T01:30:00.000Z',
      consumption: 65.6
    },
    {
      date: '2022-04-05T02:00:00.000Z',
      consumption: 60.3
    },
    {
      date: '2022-04-05T02:30:00.000Z',
      consumption: 62.7
    },
    {
      date: '2022-04-05T03:00:00.000Z',
      consumption: 68
    },
    {
      date: '2022-04-05T03:30:00.000Z',
      consumption: 56.7
    },
    {
      date: '2022-04-05T04:00:00.000Z',
      consumption: 68.9
    },
    {
      date: '2022-04-05T04:30:00.000Z',
      consumption: 83.6
    },
    {
      date: '2022-04-05T05:00:00.000Z',
      consumption: 67.3
    },
    {
      date: '2022-04-05T05:30:00.000Z',
      consumption: 70.9
    },
    {
      date: '2022-04-05T06:00:00.000Z',
      consumption: 73.2
    },
    {
      date: '2022-04-05T06:30:00.000Z',
      consumption: 30.3
    },
    {
      date: '2022-04-05T07:00:00.000Z',
      consumption: 18.3
    },
    {
      date: '2022-04-05T07:30:00.000Z',
      consumption: 23.5
    },
    {
      date: '2022-04-05T08:00:00.000Z',
      consumption: 26
    },
    {
      date: '2022-04-05T08:30:00.000Z',
      consumption: 18.7
    },
    {
      date: '2022-04-05T09:00:00.000Z',
      consumption: 25.1
    },
    {
      date: '2022-04-05T09:30:00.000Z',
      consumption: 25
    },
    {
      date: '2022-04-05T10:00:00.000Z',
      consumption: 18.8
    },
    {
      date: '2022-04-05T10:30:00.000Z',
      consumption: 21.1
    },
    {
      date: '2022-04-05T11:00:00.000Z',
      consumption: 28.1
    },
    {
      date: '2022-04-05T11:30:00.000Z',
      consumption: 20.7
    },
    {
      date: '2022-04-05T12:00:00.000Z',
      consumption: 18.1
    },
    {
      date: '2022-04-05T12:30:00.000Z',
      consumption: 30.6
    },
    {
      date: '2022-04-05T13:00:00.000Z',
      consumption: 20.9
    },
    {
      date: '2022-04-05T13:30:00.000Z',
      consumption: 15.1
    },
    {
      date: '2022-04-05T14:00:00.000Z',
      consumption: 26.3
    },
    {
      date: '2022-04-05T14:30:00.000Z',
      consumption: 13.9
    },
    {
      date: '2022-04-05T15:00:00.000Z',
      consumption: 12.4
    },
    {
      date: '2022-04-05T15:30:00.000Z',
      consumption: 22.9
    },
    {
      date: '2022-04-05T16:00:00.000Z',
      consumption: 14.5
    },
    {
      date: '2022-04-05T16:30:00.000Z',
      consumption: 10.7
    },
    {
      date: '2022-04-05T17:00:00.000Z',
      consumption: 22
    },
    {
      date: '2022-04-05T17:30:00.000Z',
      consumption: 10.7
    },
    {
      date: '2022-04-05T18:00:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-05T18:30:00.000Z',
      consumption: 21.4
    },
    {
      date: '2022-04-05T19:00:00.000Z',
      consumption: 11.7
    },
    {
      date: '2022-04-05T19:30:00.000Z',
      consumption: 10.2
    },
    {
      date: '2022-04-05T20:00:00.000Z',
      consumption: 16
    },
    {
      date: '2022-04-05T20:30:00.000Z',
      consumption: 15.1
    },
    {
      date: '2022-04-05T21:00:00.000Z',
      consumption: 11.2
    },
    {
      date: '2022-04-05T21:30:00.000Z',
      consumption: 10.4
    },
    {
      date: '2022-04-05T22:00:00.000Z',
      consumption: 20.7
    },
    {
      date: '2022-04-05T22:30:00.000Z',
      consumption: 10.4
    },
    {
      date: '2022-04-05T23:00:00.000Z',
      consumption: 10.5
    },
    {
      date: '2022-04-05T23:30:00.000Z',
      consumption: 45.2
    },
    {
      date: '2022-04-06T00:00:00.000Z',
      consumption: 35.2
    },
    {
      date: '2022-04-06T00:30:00.000Z',
      consumption: 49.1
    },
    {
      date: '2022-04-06T01:00:00.000Z',
      consumption: 61.1
    },
    {
      date: '2022-04-06T01:30:00.000Z',
      consumption: 49.2
    },
    {
      date: '2022-04-06T02:00:00.000Z',
      consumption: 62.5
    },
    {
      date: '2022-04-06T02:30:00.000Z',
      consumption: 65.3
    },
    {
      date: '2022-04-06T03:00:00.000Z',
      consumption: 63.2
    },
    {
      date: '2022-04-06T03:30:00.000Z',
      consumption: 61
    },
    {
      date: '2022-04-06T04:00:00.000Z',
      consumption: 68.5
    },
    {
      date: '2022-04-06T04:30:00.000Z',
      consumption: 67
    },
    {
      date: '2022-04-06T05:00:00.000Z',
      consumption: 58.4
    },
    {
      date: '2022-04-06T05:30:00.000Z',
      consumption: 77.9
    },
    {
      date: '2022-04-06T06:00:00.000Z',
      consumption: 63
    },
    {
      date: '2022-04-06T06:30:00.000Z',
      consumption: 22.1
    },
    {
      date: '2022-04-06T07:00:00.000Z',
      consumption: 32.2
    },
    {
      date: '2022-04-06T07:30:00.000Z',
      consumption: 21.4
    },
    {
      date: '2022-04-06T08:00:00.000Z',
      consumption: 19.1
    },
    {
      date: '2022-04-06T08:30:00.000Z',
      consumption: 30.9
    },
    {
      date: '2022-04-06T09:00:00.000Z',
      consumption: 19.9
    },
    {
      date: '2022-04-06T09:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2022-04-06T10:00:00.000Z',
      consumption: 31.1
    },
    {
      date: '2022-04-06T10:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2022-04-06T11:00:00.000Z',
      consumption: 18.5
    },
    {
      date: '2022-04-06T11:30:00.000Z',
      consumption: 22
    },
    {
      date: '2022-04-06T12:00:00.000Z',
      consumption: 23.7
    },
    {
      date: '2022-04-06T12:30:00.000Z',
      consumption: 18.8
    },
    {
      date: '2022-04-06T13:00:00.000Z',
      consumption: 21.6
    },
    {
      date: '2022-04-06T13:30:00.000Z',
      consumption: 23.4
    },
    {
      date: '2022-04-06T14:00:00.000Z',
      consumption: 13.1
    },
    {
      date: '2022-04-06T14:30:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-06T15:00:00.000Z',
      consumption: 29.4
    },
    {
      date: '2022-04-06T15:30:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-06T16:00:00.000Z',
      consumption: 10.9
    },
    {
      date: '2022-04-06T16:30:00.000Z',
      consumption: 23
    },
    {
      date: '2022-04-06T17:00:00.000Z',
      consumption: 10.6
    },
    {
      date: '2022-04-06T17:30:00.000Z',
      consumption: 10.4
    },
    {
      date: '2022-04-06T18:00:00.000Z',
      consumption: 16.5
    },
    {
      date: '2022-04-06T18:30:00.000Z',
      consumption: 16
    },
    {
      date: '2022-04-06T19:00:00.000Z',
      consumption: 10.2
    },
    {
      date: '2022-04-06T19:30:00.000Z',
      consumption: 14.1
    },
    {
      date: '2022-04-06T20:00:00.000Z',
      consumption: 17.6
    },
    {
      date: '2022-04-06T20:30:00.000Z',
      consumption: 10.1
    },
    {
      date: '2022-04-06T21:00:00.000Z',
      consumption: 11
    },
    {
      date: '2022-04-06T21:30:00.000Z',
      consumption: 21.1
    },
    {
      date: '2022-04-06T22:00:00.000Z',
      consumption: 11.1
    },
    {
      date: '2022-04-06T22:30:00.000Z',
      consumption: 10.5
    },
    {
      date: '2022-04-06T23:00:00.000Z',
      consumption: 22.5
    },
    {
      date: '2022-04-06T23:30:00.000Z',
      consumption: 34.4
    },
    {
      date: '2022-04-07T00:00:00.000Z',
      consumption: 37.8
    },
    {
      date: '2022-04-07T00:30:00.000Z',
      consumption: 53
    },
    {
      date: '2022-04-07T01:00:00.000Z',
      consumption: 48.6
    },
    {
      date: '2022-04-07T01:30:00.000Z',
      consumption: 52.6
    },
    {
      date: '2022-04-07T02:00:00.000Z',
      consumption: 71.9
    },
    {
      date: '2022-04-07T02:30:00.000Z',
      consumption: 62.9
    },
    {
      date: '2022-04-07T03:00:00.000Z',
      consumption: 60.1
    },
    {
      date: '2022-04-07T03:30:00.000Z',
      consumption: 71.6
    },
    {
      date: '2022-04-07T04:00:00.000Z',
      consumption: 65.9
    },
    {
      date: '2022-04-07T04:30:00.000Z',
      consumption: 61.5
    },
    {
      date: '2022-04-07T05:00:00.000Z',
      consumption: 64.7
    },
    {
      date: '2022-04-07T05:30:00.000Z',
      consumption: 83.4
    },
    {
      date: '2022-04-07T06:00:00.000Z',
      consumption: 65.8
    },
    {
      date: '2022-04-07T06:30:00.000Z',
      consumption: 34.1
    },
    {
      date: '2022-04-07T07:00:00.000Z',
      consumption: 23.6
    },
    {
      date: '2022-04-07T07:30:00.000Z',
      consumption: 26.4
    },
    {
      date: '2022-04-07T08:00:00.000Z',
      consumption: 35.8
    },
    {
      date: '2022-04-07T08:30:00.000Z',
      consumption: 23.7
    },
    {
      date: '2022-04-07T09:00:00.000Z',
      consumption: 20
    },
    {
      date: '2022-04-07T09:30:00.000Z',
      consumption: 30.6
    },
    {
      date: '2022-04-07T10:00:00.000Z',
      consumption: 20
    },
    {
      date: '2022-04-07T10:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2022-04-07T11:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2022-04-07T11:30:00.000Z',
      consumption: 30.7
    },
    {
      date: '2022-04-07T12:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2022-04-07T12:30:00.000Z',
      consumption: 22.7
    },
    {
      date: '2022-04-07T13:00:00.000Z',
      consumption: 30.8
    },
    {
      date: '2022-04-07T13:30:00.000Z',
      consumption: 17.7
    },
    {
      date: '2022-04-07T14:00:00.000Z',
      consumption: 17.5
    },
    {
      date: '2022-04-07T14:30:00.000Z',
      consumption: 30.9
    },
    {
      date: '2022-04-07T15:00:00.000Z',
      consumption: 22.4
    },
    {
      date: '2022-04-07T15:30:00.000Z',
      consumption: 16.8
    },
    {
      date: '2022-04-07T16:00:00.000Z',
      consumption: 28
    },
    {
      date: '2022-04-07T16:30:00.000Z',
      consumption: 14.5
    },
    {
      date: '2022-04-07T17:00:00.000Z',
      consumption: 15.1
    },
    {
      date: '2022-04-07T17:30:00.000Z',
      consumption: 18
    },
    {
      date: '2022-04-07T18:00:00.000Z',
      consumption: 18.9
    },
    {
      date: '2022-04-07T18:30:00.000Z',
      consumption: 12.1
    },
    {
      date: '2022-04-07T19:00:00.000Z',
      consumption: 15.6
    },
    {
      date: '2022-04-07T19:30:00.000Z',
      consumption: 23.9
    },
    {
      date: '2022-04-07T20:00:00.000Z',
      consumption: 13.1
    },
    {
      date: '2022-04-07T20:30:00.000Z',
      consumption: 12.8
    },
    {
      date: '2022-04-07T21:00:00.000Z',
      consumption: 24.4
    },
    {
      date: '2022-04-07T21:30:00.000Z',
      consumption: 13.2
    },
    {
      date: '2022-04-07T22:00:00.000Z',
      consumption: 13.9
    },
    {
      date: '2022-04-07T22:30:00.000Z',
      consumption: 0
    },
    {
      date: '2022-04-07T23:00:00.000Z',
      consumption: 0
    },
    {
      date: '2022-04-07T23:30:00.000Z',
      consumption: 32.5
    },
    {
      date: '2022-04-08T00:00:00.000Z',
      consumption: 43.5
    },
    {
      date: '2022-04-08T00:30:00.000Z',
      consumption: 33.8
    },
    {
      date: '2022-04-08T01:00:00.000Z',
      consumption: 38.3
    },
    {
      date: '2022-04-08T01:30:00.000Z',
      consumption: 69.6
    },
    {
      date: '2022-04-08T02:00:00.000Z',
      consumption: 66.8
    },
    {
      date: '2022-04-08T02:30:00.000Z',
      consumption: 70.2
    },
    {
      date: '2022-04-08T03:00:00.000Z',
      consumption: 85.3
    },
    {
      date: '2022-04-08T03:30:00.000Z',
      consumption: 74.8
    },
    {
      date: '2022-04-08T04:00:00.000Z',
      consumption: 73.9
    },
    {
      date: '2022-04-08T04:30:00.000Z',
      consumption: 86.2
    },
    {
      date: '2022-04-08T05:00:00.000Z',
      consumption: 71.4
    },
    {
      date: '2022-04-08T05:30:00.000Z',
      consumption: 77.6
    },
    {
      date: '2022-04-08T06:00:00.000Z',
      consumption: 81.1
    },
    {
      date: '2022-04-08T06:30:00.000Z',
      consumption: 26.4
    },
    {
      date: '2022-04-08T07:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2022-04-08T07:30:00.000Z',
      consumption: 33.9
    },
    {
      date: '2022-04-08T08:00:00.000Z',
      consumption: 20.4
    },
    {
      date: '2022-04-08T08:30:00.000Z',
      consumption: 19.6
    },
    {
      date: '2022-04-08T09:00:00.000Z',
      consumption: 31.1
    },
    {
      date: '2022-04-08T09:30:00.000Z',
      consumption: 19.7
    },
    {
      date: '2022-04-08T10:00:00.000Z',
      consumption: 17.7
    },
    {
      date: '2022-04-08T10:30:00.000Z',
      consumption: 29
    },
    {
      date: '2022-04-08T11:00:00.000Z',
      consumption: 16.9
    },
    {
      date: '2022-04-08T11:30:00.000Z',
      consumption: 18.7
    },
    {
      date: '2022-04-08T12:00:00.000Z',
      consumption: 27.1
    },
    {
      date: '2022-04-08T12:30:00.000Z',
      consumption: 17.7
    },
    {
      date: '2022-04-08T13:00:00.000Z',
      consumption: 16.8
    },
    {
      date: '2022-04-08T13:30:00.000Z',
      consumption: 24.2
    },
    {
      date: '2022-04-08T14:00:00.000Z',
      consumption: 13.1
    },
    {
      date: '2022-04-08T14:30:00.000Z',
      consumption: 13.2
    },
    {
      date: '2022-04-08T15:00:00.000Z',
      consumption: 23.5
    },
    {
      date: '2022-04-08T15:30:00.000Z',
      consumption: 13.2
    },
    {
      date: '2022-04-08T16:00:00.000Z',
      consumption: 15.3
    },
    {
      date: '2022-04-08T16:30:00.000Z',
      consumption: 24.6
    },
    {
      date: '2022-04-08T17:00:00.000Z',
      consumption: 11.7
    },
    {
      date: '2022-04-08T17:30:00.000Z',
      consumption: 12.9
    },
    {
      date: '2022-04-08T18:00:00.000Z',
      consumption: 14
    },
    {
      date: '2022-04-08T18:30:00.000Z',
      consumption: 23.6
    },
    {
      date: '2022-04-08T19:00:00.000Z',
      consumption: 12
    },
    {
      date: '2022-04-08T19:30:00.000Z',
      consumption: 12.4
    },
    {
      date: '2022-04-08T20:00:00.000Z',
      consumption: 23.5
    },
    {
      date: '2022-04-08T20:30:00.000Z',
      consumption: 14.1
    },
    {
      date: '2022-04-08T21:00:00.000Z',
      consumption: 14.7
    },
    {
      date: '2022-04-08T21:30:00.000Z',
      consumption: 23.6
    },
    {
      date: '2022-04-08T22:00:00.000Z',
      consumption: 14.4
    },
    {
      date: '2022-04-08T22:30:00.000Z',
      consumption: 13.1
    },
    {
      date: '2022-04-08T23:00:00.000Z',
      consumption: 23.7
    },
    {
      date: '2022-04-08T23:30:00.000Z',
      consumption: 14.5
    },
    {
      date: '2022-04-09T00:00:00.000Z',
      consumption: 13.3
    },
    {
      date: '2022-04-09T00:30:00.000Z',
      consumption: 24.3
    },
    {
      date: '2022-04-09T01:00:00.000Z',
      consumption: 13.6
    },
    {
      date: '2022-04-09T01:30:00.000Z',
      consumption: 15.2
    },
    {
      date: '2022-04-09T02:00:00.000Z',
      consumption: 23.9
    },
    {
      date: '2022-04-09T02:30:00.000Z',
      consumption: 15
    },
    {
      date: '2022-04-09T03:00:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-09T03:30:00.000Z',
      consumption: 24.7
    },
    {
      date: '2022-04-09T04:00:00.000Z',
      consumption: 13.7
    },
    {
      date: '2022-04-09T04:30:00.000Z',
      consumption: 14.2
    },
    {
      date: '2022-04-09T05:00:00.000Z',
      consumption: 24.6
    },
    {
      date: '2022-04-09T05:30:00.000Z',
      consumption: 13
    },
    {
      date: '2022-04-09T06:00:00.000Z',
      consumption: 16
    },
    {
      date: '2022-04-09T06:30:00.000Z',
      consumption: 24.3
    },
    {
      date: '2022-04-09T07:00:00.000Z',
      consumption: 13
    },
    {
      date: '2022-04-09T07:30:00.000Z',
      consumption: 13.4
    },
    {
      date: '2022-04-09T08:00:00.000Z',
      consumption: 23.8
    },
    {
      date: '2022-04-09T08:30:00.000Z',
      consumption: 12.5
    },
    {
      date: '2022-04-09T09:00:00.000Z',
      consumption: 11.6
    },
    {
      date: '2022-04-09T09:30:00.000Z',
      consumption: 21.1
    },
    {
      date: '2022-04-09T10:00:00.000Z',
      consumption: 11.8
    },
    {
      date: '2022-04-09T10:30:00.000Z',
      consumption: 11.5
    },
    {
      date: '2022-04-09T11:00:00.000Z',
      consumption: 21.9
    },
    {
      date: '2022-04-09T11:30:00.000Z',
      consumption: 11.6
    },
    {
      date: '2022-04-09T12:00:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-09T12:30:00.000Z',
      consumption: 22
    },
    {
      date: '2022-04-09T13:00:00.000Z',
      consumption: 11.7
    },
    {
      date: '2022-04-09T13:30:00.000Z',
      consumption: 11.1
    },
    {
      date: '2022-04-09T14:00:00.000Z',
      consumption: 22.1
    },
    {
      date: '2022-04-09T14:30:00.000Z',
      consumption: 12
    },
    {
      date: '2022-04-09T15:00:00.000Z',
      consumption: 11.4
    },
    {
      date: '2022-04-09T15:30:00.000Z',
      consumption: 16.9
    },
    {
      date: '2022-04-09T16:00:00.000Z',
      consumption: 15.5
    },
    {
      date: '2022-04-09T16:30:00.000Z',
      consumption: 11.6
    },
    {
      date: '2022-04-09T17:00:00.000Z',
      consumption: 17.4
    },
    {
      date: '2022-04-09T17:30:00.000Z',
      consumption: 16.3
    },
    {
      date: '2022-04-09T18:00:00.000Z',
      consumption: 11.5
    },
    {
      date: '2022-04-09T18:30:00.000Z',
      consumption: 11
    },
    {
      date: '2022-04-09T19:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2022-04-09T19:30:00.000Z',
      consumption: 12.6
    },
    {
      date: '2022-04-09T20:00:00.000Z',
      consumption: 12.4
    },
    {
      date: '2022-04-09T20:30:00.000Z',
      consumption: 23.7
    },
    {
      date: '2022-04-09T21:00:00.000Z',
      consumption: 12.8
    },
    {
      date: '2022-04-09T21:30:00.000Z',
      consumption: 12.9
    },
    {
      date: '2022-04-09T22:00:00.000Z',
      consumption: 26.8
    },
    {
      date: '2022-04-09T22:30:00.000Z',
      consumption: 14.2
    },
    {
      date: '2022-04-09T23:00:00.000Z',
      consumption: 14.2
    },
    {
      date: '2022-04-09T23:30:00.000Z',
      consumption: 22.8
    },
    {
      date: '2022-04-10T00:00:00.000Z',
      consumption: 14.3
    },
    {
      date: '2022-04-10T00:30:00.000Z',
      consumption: 12.6
    },
    {
      date: '2022-04-10T01:00:00.000Z',
      consumption: 26.9
    },
    {
      date: '2022-04-10T01:30:00.000Z',
      consumption: 13.4
    },
    {
      date: '2022-04-10T02:00:00.000Z',
      consumption: 16.8
    },
    {
      date: '2022-04-10T02:30:00.000Z',
      consumption: 19
    },
    {
      date: '2022-04-10T03:00:00.000Z',
      consumption: 21.4
    },
    {
      date: '2022-04-10T03:30:00.000Z',
      consumption: 14.1
    },
    {
      date: '2022-04-10T04:00:00.000Z',
      consumption: 15.9
    },
    {
      date: '2022-04-10T04:30:00.000Z',
      consumption: 22.3
    },
    {
      date: '2022-04-10T05:00:00.000Z',
      consumption: 13.6
    },
    {
      date: '2022-04-10T05:30:00.000Z',
      consumption: 16.6
    },
    {
      date: '2022-04-10T06:00:00.000Z',
      consumption: 23.9
    },
    {
      date: '2022-04-10T06:30:00.000Z',
      consumption: 14.4
    },
    {
      date: '2022-04-10T07:00:00.000Z',
      consumption: 23.4
    },
    {
      date: '2022-04-10T07:30:00.000Z',
      consumption: 14.7
    },
    {
      date: '2022-04-10T08:00:00.000Z',
      consumption: 11.8
    },
    {
      date: '2022-04-10T08:30:00.000Z',
      consumption: 20.3
    },
    {
      date: '2022-04-10T09:00:00.000Z',
      consumption: 15.1
    },
    {
      date: '2022-04-10T09:30:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-10T10:00:00.000Z',
      consumption: 12.9
    },
    {
      date: '2022-04-10T10:30:00.000Z',
      consumption: 19.9
    },
    {
      date: '2022-04-10T11:00:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-10T11:30:00.000Z',
      consumption: 10.6
    },
    {
      date: '2022-04-10T12:00:00.000Z',
      consumption: 21.1
    },
    {
      date: '2022-04-10T12:30:00.000Z',
      consumption: 10.3
    },
    {
      date: '2022-04-10T13:00:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-10T13:30:00.000Z',
      consumption: 20.5
    },
    {
      date: '2022-04-10T14:00:00.000Z',
      consumption: 10.2
    },
    {
      date: '2022-04-10T14:30:00.000Z',
      consumption: 10.5
    },
    {
      date: '2022-04-10T15:00:00.000Z',
      consumption: 20.7
    },
    {
      date: '2022-04-10T15:30:00.000Z',
      consumption: 10.2
    },
    {
      date: '2022-04-10T16:00:00.000Z',
      consumption: 10.8
    },
    {
      date: '2022-04-10T16:30:00.000Z',
      consumption: 21.9
    },
    {
      date: '2022-04-10T17:00:00.000Z',
      consumption: 11.4
    },
    {
      date: '2022-04-10T17:30:00.000Z',
      consumption: 10.7
    },
    {
      date: '2022-04-10T18:00:00.000Z',
      consumption: 21.9
    },
    {
      date: '2022-04-10T18:30:00.000Z',
      consumption: 12.1
    },
    {
      date: '2022-04-10T19:00:00.000Z',
      consumption: 11.6
    },
    {
      date: '2022-04-10T19:30:00.000Z',
      consumption: 17
    },
    {
      date: '2022-04-10T20:00:00.000Z',
      consumption: 17.2
    },
    {
      date: '2022-04-10T20:30:00.000Z',
      consumption: 13.5
    },
    {
      date: '2022-04-10T21:00:00.000Z',
      consumption: 14
    },
    {
      date: '2022-04-10T21:30:00.000Z',
      consumption: 21.6
    },
    {
      date: '2022-04-10T22:00:00.000Z',
      consumption: 12.1
    },
    {
      date: '2022-04-10T22:30:00.000Z',
      consumption: 12.9
    },
    {
      date: '2022-04-10T23:00:00.000Z',
      consumption: 22.9
    },
    {
      date: '2022-04-10T23:30:00.000Z',
      consumption: 30.3
    },
    {
      date: '2022-04-11T00:00:00.000Z',
      consumption: 26.7
    },
    {
      date: '2022-04-11T00:30:00.000Z',
      consumption: 36.8
    },
    {
      date: '2022-04-11T01:00:00.000Z',
      consumption: 30.9
    },
    {
      date: '2022-04-11T01:30:00.000Z',
      consumption: 41.9
    },
    {
      date: '2022-04-11T02:00:00.000Z',
      consumption: 60.6
    },
    {
      date: '2022-04-11T02:30:00.000Z',
      consumption: 50.6
    },
    {
      date: '2022-04-11T03:00:00.000Z',
      consumption: 46.9
    },
    {
      date: '2022-04-11T03:30:00.000Z',
      consumption: 71.7
    },
    {
      date: '2022-04-11T04:00:00.000Z',
      consumption: 61.8
    },
    {
      date: '2022-04-11T04:30:00.000Z',
      consumption: 66.5
    },
    {
      date: '2022-04-11T05:00:00.000Z',
      consumption: 80
    },
    {
      date: '2022-04-11T05:30:00.000Z',
      consumption: 70.1
    },
    {
      date: '2022-04-11T06:00:00.000Z',
      consumption: 77.1
    },
    {
      date: '2022-04-11T06:30:00.000Z',
      consumption: 34.3
    },
    {
      date: '2022-04-11T07:00:00.000Z',
      consumption: 19.6
    },
    {
      date: '2022-04-11T07:30:00.000Z',
      consumption: 24.2
    },
    {
      date: '2022-04-11T08:00:00.000Z',
      consumption: 23.9
    },
    {
      date: '2022-04-11T08:30:00.000Z',
      consumption: 22.3
    },
    {
      date: '2022-04-11T09:00:00.000Z',
      consumption: 20.7
    },
    {
      date: '2022-04-11T09:30:00.000Z',
      consumption: 27.4
    },
    {
      date: '2022-04-11T10:00:00.000Z',
      consumption: 17.3
    },
    {
      date: '2022-04-11T10:30:00.000Z',
      consumption: 17.3
    },
    {
      date: '2022-04-11T11:00:00.000Z',
      consumption: 27
    },
    {
      date: '2022-04-11T11:30:00.000Z',
      consumption: 17
    },
    {
      date: '2022-04-11T12:00:00.000Z',
      consumption: 15.8
    },
    {
      date: '2022-04-11T12:30:00.000Z',
      consumption: 27
    },
    {
      date: '2022-04-11T13:00:00.000Z',
      consumption: 15.6
    },
    {
      date: '2022-04-11T13:30:00.000Z',
      consumption: 16.2
    },
    {
      date: '2022-04-11T14:00:00.000Z',
      consumption: 24.1
    },
    {
      date: '2022-04-11T14:30:00.000Z',
      consumption: 23.4
    },
    {
      date: '2022-04-11T15:00:00.000Z',
      consumption: 12
    },
    {
      date: '2022-04-11T15:30:00.000Z',
      consumption: 10.2
    },
    {
      date: '2022-04-11T16:00:00.000Z',
      consumption: 16.3
    },
    {
      date: '2022-04-11T16:30:00.000Z',
      consumption: 13.9
    },
    {
      date: '2022-04-11T17:00:00.000Z',
      consumption: 10.3
    },
    {
      date: '2022-04-11T17:30:00.000Z',
      consumption: 10.4
    },
    {
      date: '2022-04-11T18:00:00.000Z',
      consumption: 20.8
    },
    {
      date: '2022-04-11T18:30:00.000Z',
      consumption: 10.7
    },
    {
      date: '2022-04-11T19:00:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-11T19:30:00.000Z',
      consumption: 20.6
    },
    {
      date: '2022-04-11T20:00:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-11T20:30:00.000Z',
      consumption: 11.8
    },
    {
      date: '2022-04-11T21:00:00.000Z',
      consumption: 22
    },
    {
      date: '2022-04-11T21:30:00.000Z',
      consumption: 11.8
    },
    {
      date: '2022-04-11T22:00:00.000Z',
      consumption: 11.8
    },
    {
      date: '2022-04-11T22:30:00.000Z',
      consumption: 22
    },
    {
      date: '2022-04-11T23:00:00.000Z',
      consumption: 11.7
    },
    {
      date: '2022-04-11T23:30:00.000Z',
      consumption: 31.1
    },
    {
      date: '2022-04-12T00:00:00.000Z',
      consumption: 49.2
    },
    {
      date: '2022-04-12T00:30:00.000Z',
      consumption: 43.4
    },
    {
      date: '2022-04-12T01:00:00.000Z',
      consumption: 38.4
    },
    {
      date: '2022-04-12T01:30:00.000Z',
      consumption: 50.9
    },
    {
      date: '2022-04-12T02:00:00.000Z',
      consumption: 49.8
    },
    {
      date: '2022-04-12T02:30:00.000Z',
      consumption: 50.6
    },
    {
      date: '2022-04-12T03:00:00.000Z',
      consumption: 59.2
    },
    {
      date: '2022-04-12T03:30:00.000Z',
      consumption: 71.7
    },
    {
      date: '2022-04-12T04:00:00.000Z',
      consumption: 59
    },
    {
      date: '2022-04-12T04:30:00.000Z',
      consumption: 64.9
    },
    {
      date: '2022-04-12T05:00:00.000Z',
      consumption: 73.9
    },
    {
      date: '2022-04-12T05:30:00.000Z',
      consumption: 60.1
    },
    {
      date: '2022-04-12T06:00:00.000Z',
      consumption: 59.5
    },
    {
      date: '2022-04-12T06:30:00.000Z',
      consumption: 30.2
    },
    {
      date: '2022-04-12T07:00:00.000Z',
      consumption: 18.2
    },
    {
      date: '2022-04-12T07:30:00.000Z',
      consumption: 16.2
    },
    {
      date: '2022-04-12T08:00:00.000Z',
      consumption: 29.2
    },
    {
      date: '2022-04-12T08:30:00.000Z',
      consumption: 18.4
    },
    {
      date: '2022-04-12T09:00:00.000Z',
      consumption: 20.7
    },
    {
      date: '2022-04-12T09:30:00.000Z',
      consumption: 29.3
    },
    {
      date: '2022-04-12T10:00:00.000Z',
      consumption: 19.5
    },
    {
      date: '2022-04-12T10:30:00.000Z',
      consumption: 18
    },
    {
      date: '2022-04-12T11:00:00.000Z',
      consumption: 28.4
    },
    {
      date: '2022-04-12T11:30:00.000Z',
      consumption: 18
    },
    {
      date: '2022-04-12T12:00:00.000Z',
      consumption: 16
    },
    {
      date: '2022-04-12T12:30:00.000Z',
      consumption: 27.3
    },
    {
      date: '2022-04-12T13:00:00.000Z',
      consumption: 18
    },
    {
      date: '2022-04-12T13:30:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-12T14:00:00.000Z',
      consumption: 26.6
    },
    {
      date: '2022-04-12T14:30:00.000Z',
      consumption: 14.5
    },
    {
      date: '2022-04-12T15:00:00.000Z',
      consumption: 13.5
    },
    {
      date: '2022-04-12T15:30:00.000Z',
      consumption: 12.7
    },
    {
      date: '2022-04-12T16:00:00.000Z',
      consumption: 24.5
    },
    {
      date: '2022-04-12T16:30:00.000Z',
      consumption: 12.3
    },
    {
      date: '2022-04-12T17:00:00.000Z',
      consumption: 12
    },
    {
      date: '2022-04-12T17:30:00.000Z',
      consumption: 22.8
    },
    {
      date: '2022-04-12T18:00:00.000Z',
      consumption: 10.5
    },
    {
      date: '2022-04-12T18:30:00.000Z',
      consumption: 12
    },
    {
      date: '2022-04-12T19:00:00.000Z',
      consumption: 21.2
    },
    {
      date: '2022-04-12T19:30:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-12T20:00:00.000Z',
      consumption: 11.2
    },
    {
      date: '2022-04-12T20:30:00.000Z',
      consumption: 16
    },
    {
      date: '2022-04-12T21:00:00.000Z',
      consumption: 19.6
    },
    {
      date: '2022-04-12T21:30:00.000Z',
      consumption: 11.6
    },
    {
      date: '2022-04-12T22:00:00.000Z',
      consumption: 12.6
    },
    {
      date: '2022-04-12T22:30:00.000Z',
      consumption: 22.7
    },
    {
      date: '2022-04-12T23:00:00.000Z',
      consumption: 11.5
    },
    {
      date: '2022-04-12T23:30:00.000Z',
      consumption: 38.6
    },
    {
      date: '2022-04-13T00:00:00.000Z',
      consumption: 40
    },
    {
      date: '2022-04-13T00:30:00.000Z',
      consumption: 28.4
    },
    {
      date: '2022-04-13T01:00:00.000Z',
      consumption: 38.5
    },
    {
      date: '2022-04-13T01:30:00.000Z',
      consumption: 59.5
    },
    {
      date: '2022-04-13T02:00:00.000Z',
      consumption: 52.3
    },
    {
      date: '2022-04-13T02:30:00.000Z',
      consumption: 41.2
    },
    {
      date: '2022-04-13T03:00:00.000Z',
      consumption: 61.5
    },
    {
      date: '2022-04-13T03:30:00.000Z',
      consumption: 51
    },
    {
      date: '2022-04-13T04:00:00.000Z',
      consumption: 48.4
    },
    {
      date: '2022-04-13T04:30:00.000Z',
      consumption: 46.3
    },
    {
      date: '2022-04-13T05:00:00.000Z',
      consumption: 65.9
    },
    {
      date: '2022-04-13T05:30:00.000Z',
      consumption: 53.9
    },
    {
      date: '2022-04-13T06:00:00.000Z',
      consumption: 47.6
    },
    {
      date: '2022-04-13T06:30:00.000Z',
      consumption: 29.6
    },
    {
      date: '2022-04-13T07:00:00.000Z',
      consumption: 19.2
    },
    {
      date: '2022-04-13T07:30:00.000Z',
      consumption: 17.6
    },
    {
      date: '2022-04-13T08:00:00.000Z',
      consumption: 28.6
    },
    {
      date: '2022-04-13T08:30:00.000Z',
      consumption: 16.8
    },
    {
      date: '2022-04-13T09:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2022-04-13T09:30:00.000Z',
      consumption: 26.6
    },
    {
      date: '2022-04-13T10:00:00.000Z',
      consumption: 17
    },
    {
      date: '2022-04-13T10:30:00.000Z',
      consumption: 16.2
    },
    {
      date: '2022-04-13T11:00:00.000Z',
      consumption: 27.6
    },
    {
      date: '2022-04-13T11:30:00.000Z',
      consumption: 15.4
    },
    {
      date: '2022-04-13T12:00:00.000Z',
      consumption: 17.4
    },
    {
      date: '2022-04-13T12:30:00.000Z',
      consumption: 26.7
    },
    {
      date: '2022-04-13T13:00:00.000Z',
      consumption: 16.2
    },
    {
      date: '2022-04-13T13:30:00.000Z',
      consumption: 24.6
    },
    {
      date: '2022-04-13T14:00:00.000Z',
      consumption: 19.3
    },
    {
      date: '2022-04-13T14:30:00.000Z',
      consumption: 20
    },
    {
      date: '2022-04-13T15:00:00.000Z',
      consumption: 13
    },
    {
      date: '2022-04-13T15:30:00.000Z',
      consumption: 11.8
    },
    {
      date: '2022-04-13T16:00:00.000Z',
      consumption: 22.5
    },
    {
      date: '2022-04-13T16:30:00.000Z',
      consumption: 14.1
    },
    {
      date: '2022-04-13T17:00:00.000Z',
      consumption: 11.1
    },
    {
      date: '2022-04-13T17:30:00.000Z',
      consumption: 21.8
    },
    {
      date: '2022-04-13T18:00:00.000Z',
      consumption: 10.6
    },
    {
      date: '2022-04-13T18:30:00.000Z',
      consumption: 10.3
    },
    {
      date: '2022-04-13T19:00:00.000Z',
      consumption: 15.8
    },
    {
      date: '2022-04-13T19:30:00.000Z',
      consumption: 15.5
    },
    {
      date: '2022-04-13T20:00:00.000Z',
      consumption: 9.9
    },
    {
      date: '2022-04-13T20:30:00.000Z',
      consumption: 10.4
    },
    {
      date: '2022-04-13T21:00:00.000Z',
      consumption: 20.9
    },
    {
      date: '2022-04-13T21:30:00.000Z',
      consumption: 10.9
    },
    {
      date: '2022-04-13T22:00:00.000Z',
      consumption: 10.6
    },
    {
      date: '2022-04-13T22:30:00.000Z',
      consumption: 21.4
    },
    {
      date: '2022-04-13T23:00:00.000Z',
      consumption: 12.5
    },
    {
      date: '2022-04-13T23:30:00.000Z',
      consumption: 31.6
    },
    {
      date: '2022-04-14T00:00:00.000Z',
      consumption: 38.2
    },
    {
      date: '2022-04-14T00:30:00.000Z',
      consumption: 44.7
    },
    {
      date: '2022-04-14T01:00:00.000Z',
      consumption: 35.5
    },
    {
      date: '2022-04-14T01:30:00.000Z',
      consumption: 56
    },
    {
      date: '2022-04-14T02:00:00.000Z',
      consumption: 40
    },
    {
      date: '2022-04-14T02:30:00.000Z',
      consumption: 39
    },
    {
      date: '2022-04-14T03:00:00.000Z',
      consumption: 44.7
    },
    {
      date: '2022-04-14T03:30:00.000Z',
      consumption: 49.2
    },
    {
      date: '2022-04-14T04:00:00.000Z',
      consumption: 42.6
    },
    {
      date: '2022-04-14T04:30:00.000Z',
      consumption: 49.2
    },
    {
      date: '2022-04-14T05:00:00.000Z',
      consumption: 58.8
    },
    {
      date: '2022-04-14T05:30:00.000Z',
      consumption: 45.5
    },
    {
      date: '2022-04-14T06:00:00.000Z',
      consumption: 43.1
    },
    {
      date: '2022-04-14T06:30:00.000Z',
      consumption: 29.4
    },
    {
      date: '2022-04-14T07:00:00.000Z',
      consumption: 20
    },
    {
      date: '2022-04-14T07:30:00.000Z',
      consumption: 15.8
    },
    {
      date: '2022-04-14T08:00:00.000Z',
      consumption: 28.4
    },
    {
      date: '2022-04-14T08:30:00.000Z',
      consumption: 16.6
    },
    {
      date: '2022-04-14T09:00:00.000Z',
      consumption: 14.9
    },
    {
      date: '2022-04-14T09:30:00.000Z',
      consumption: 24.3
    },
    {
      date: '2022-04-14T10:00:00.000Z',
      consumption: 13.2
    },
    {
      date: '2022-04-14T10:30:00.000Z',
      consumption: 11.8
    },
    {
      date: '2022-04-14T11:00:00.000Z',
      consumption: 22.6
    },
    {
      date: '2022-04-14T11:30:00.000Z',
      consumption: 13.6
    },
    {
      date: '2022-04-14T12:00:00.000Z',
      consumption: 16.5
    },
    {
      date: '2022-04-14T12:30:00.000Z',
      consumption: 22.7
    },
    {
      date: '2022-04-14T13:00:00.000Z',
      consumption: 12.6
    },
    {
      date: '2022-04-14T13:30:00.000Z',
      consumption: 13.6
    },
    {
      date: '2022-04-14T14:00:00.000Z',
      consumption: 15.7
    },
    {
      date: '2022-04-14T14:30:00.000Z',
      consumption: 15.2
    },
    {
      date: '2022-04-14T15:00:00.000Z',
      consumption: 10.6
    },
    {
      date: '2022-04-14T15:30:00.000Z',
      consumption: 13.1
    },
    {
      date: '2022-04-14T16:00:00.000Z',
      consumption: 21.6
    },
    {
      date: '2022-04-14T16:30:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-14T17:00:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-14T17:30:00.000Z',
      consumption: 13
    },
    {
      date: '2022-04-14T18:00:00.000Z',
      consumption: 13
    },
    {
      date: '2022-04-14T18:30:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-14T19:00:00.000Z',
      consumption: 8.3
    },
    {
      date: '2022-04-14T19:30:00.000Z',
      consumption: 18.3
    },
    {
      date: '2022-04-14T20:00:00.000Z',
      consumption: 7.8
    },
    {
      date: '2022-04-14T20:30:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-14T21:00:00.000Z',
      consumption: 19
    },
    {
      date: '2022-04-14T21:30:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-14T22:00:00.000Z',
      consumption: 9.8
    },
    {
      date: '2022-04-14T22:30:00.000Z',
      consumption: 20.6
    },
    {
      date: '2022-04-14T23:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-14T23:30:00.000Z',
      consumption: 9.1
    },
    {
      date: '2022-04-15T00:00:00.000Z',
      consumption: 12.9
    },
    {
      date: '2022-04-15T00:30:00.000Z',
      consumption: 17.8
    },
    {
      date: '2022-04-15T01:00:00.000Z',
      consumption: 10.3
    },
    {
      date: '2022-04-15T01:30:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-15T02:00:00.000Z',
      consumption: 20.6
    },
    {
      date: '2022-04-15T02:30:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-15T03:00:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-15T03:30:00.000Z',
      consumption: 20.3
    },
    {
      date: '2022-04-15T04:00:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-15T04:30:00.000Z',
      consumption: 10.2
    },
    {
      date: '2022-04-15T05:00:00.000Z',
      consumption: 20.2
    },
    {
      date: '2022-04-15T05:30:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-15T06:00:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-15T06:30:00.000Z',
      consumption: 19.8
    },
    {
      date: '2022-04-15T07:00:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-15T07:30:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-15T08:00:00.000Z',
      consumption: 10.3
    },
    {
      date: '2022-04-15T08:30:00.000Z',
      consumption: 20.4
    },
    {
      date: '2022-04-15T09:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-15T09:30:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-15T10:00:00.000Z',
      consumption: 19
    },
    {
      date: '2022-04-15T10:30:00.000Z',
      consumption: 8
    },
    {
      date: '2022-04-15T11:00:00.000Z',
      consumption: 7.5
    },
    {
      date: '2022-04-15T11:30:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-15T12:00:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-15T12:30:00.000Z',
      consumption: 7
    },
    {
      date: '2022-04-15T13:00:00.000Z',
      consumption: 7.3
    },
    {
      date: '2022-04-15T13:30:00.000Z',
      consumption: 18.5
    },
    {
      date: '2022-04-15T14:00:00.000Z',
      consumption: 8.4
    },
    {
      date: '2022-04-15T14:30:00.000Z',
      consumption: 8.3
    },
    {
      date: '2022-04-15T15:00:00.000Z',
      consumption: 18.6
    },
    {
      date: '2022-04-15T15:30:00.000Z',
      consumption: 7.3
    },
    {
      date: '2022-04-15T16:00:00.000Z',
      consumption: 7
    },
    {
      date: '2022-04-15T16:30:00.000Z',
      consumption: 7.4
    },
    {
      date: '2022-04-15T17:00:00.000Z',
      consumption: 18.8
    },
    {
      date: '2022-04-15T17:30:00.000Z',
      consumption: 7.6
    },
    {
      date: '2022-04-15T18:00:00.000Z',
      consumption: 7.3
    },
    {
      date: '2022-04-15T18:30:00.000Z',
      consumption: 17.5
    },
    {
      date: '2022-04-15T19:00:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-15T19:30:00.000Z',
      consumption: 7.9
    },
    {
      date: '2022-04-15T20:00:00.000Z',
      consumption: 13.7
    },
    {
      date: '2022-04-15T20:30:00.000Z',
      consumption: 13.5
    },
    {
      date: '2022-04-15T21:00:00.000Z',
      consumption: 8.4
    },
    {
      date: '2022-04-15T21:30:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-15T22:00:00.000Z',
      consumption: 18.5
    },
    {
      date: '2022-04-15T22:30:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-15T23:00:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-15T23:30:00.000Z',
      consumption: 19.7
    },
    {
      date: '2022-04-16T00:00:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-16T00:30:00.000Z',
      consumption: 10.1
    },
    {
      date: '2022-04-16T01:00:00.000Z',
      consumption: 21.4
    },
    {
      date: '2022-04-16T01:30:00.000Z',
      consumption: 10.2
    },
    {
      date: '2022-04-16T02:00:00.000Z',
      consumption: 10.6
    },
    {
      date: '2022-04-16T02:30:00.000Z',
      consumption: 10.2
    },
    {
      date: '2022-04-16T03:00:00.000Z',
      consumption: 20.3
    },
    {
      date: '2022-04-16T03:30:00.000Z',
      consumption: 10.4
    },
    {
      date: '2022-04-16T04:00:00.000Z',
      consumption: 10.6
    },
    {
      date: '2022-04-16T04:30:00.000Z',
      consumption: 19.7
    },
    {
      date: '2022-04-16T05:00:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-16T05:30:00.000Z',
      consumption: 9.5
    },
    {
      date: '2022-04-16T06:00:00.000Z',
      consumption: 20.9
    },
    {
      date: '2022-04-16T06:30:00.000Z',
      consumption: 10.5
    },
    {
      date: '2022-04-16T07:00:00.000Z',
      consumption: 9.7
    },
    {
      date: '2022-04-16T07:30:00.000Z',
      consumption: 17.2
    },
    {
      date: '2022-04-16T08:00:00.000Z',
      consumption: 9.9
    },
    {
      date: '2022-04-16T08:30:00.000Z',
      consumption: 8.1
    },
    {
      date: '2022-04-16T09:00:00.000Z',
      consumption: 8.1
    },
    {
      date: '2022-04-16T09:30:00.000Z',
      consumption: 18.6
    },
    {
      date: '2022-04-16T10:00:00.000Z',
      consumption: 7.2
    },
    {
      date: '2022-04-16T10:30:00.000Z',
      consumption: 7.3
    },
    {
      date: '2022-04-16T11:00:00.000Z',
      consumption: 17.6
    },
    {
      date: '2022-04-16T11:30:00.000Z',
      consumption: 7.3
    },
    {
      date: '2022-04-16T12:00:00.000Z',
      consumption: 7.1
    },
    {
      date: '2022-04-16T12:30:00.000Z',
      consumption: 7.2
    },
    {
      date: '2022-04-16T13:00:00.000Z',
      consumption: 18.1
    },
    {
      date: '2022-04-16T13:30:00.000Z',
      consumption: 7.2
    },
    {
      date: '2022-04-16T14:00:00.000Z',
      consumption: 7.9
    },
    {
      date: '2022-04-16T14:30:00.000Z',
      consumption: 18.4
    },
    {
      date: '2022-04-16T15:00:00.000Z',
      consumption: 7.3
    },
    {
      date: '2022-04-16T15:30:00.000Z',
      consumption: 7.1
    },
    {
      date: '2022-04-16T16:00:00.000Z',
      consumption: 7.1
    },
    {
      date: '2022-04-16T16:30:00.000Z',
      consumption: 17.6
    },
    {
      date: '2022-04-16T17:00:00.000Z',
      consumption: 7.5
    },
    {
      date: '2022-04-16T17:30:00.000Z',
      consumption: 7.9
    },
    {
      date: '2022-04-16T18:00:00.000Z',
      consumption: 7.8
    },
    {
      date: '2022-04-16T18:30:00.000Z',
      consumption: 17.6
    },
    {
      date: '2022-04-16T19:00:00.000Z',
      consumption: 7.6
    },
    {
      date: '2022-04-16T19:30:00.000Z',
      consumption: 7.7
    },
    {
      date: '2022-04-16T20:00:00.000Z',
      consumption: 18.4
    },
    {
      date: '2022-04-16T20:30:00.000Z',
      consumption: 7.4
    },
    {
      date: '2022-04-16T21:00:00.000Z',
      consumption: 7.4
    },
    {
      date: '2022-04-16T21:30:00.000Z',
      consumption: 15.6
    },
    {
      date: '2022-04-16T22:00:00.000Z',
      consumption: 9.9
    },
    {
      date: '2022-04-16T22:30:00.000Z',
      consumption: 7.4
    },
    {
      date: '2022-04-16T23:00:00.000Z',
      consumption: 7.6
    },
    {
      date: '2022-04-16T23:30:00.000Z',
      consumption: 18.1
    },
    {
      date: '2022-04-17T00:00:00.000Z',
      consumption: 8.1
    },
    {
      date: '2022-04-17T00:30:00.000Z',
      consumption: 7.6
    },
    {
      date: '2022-04-17T01:00:00.000Z',
      consumption: 18.5
    },
    {
      date: '2022-04-17T01:30:00.000Z',
      consumption: 8.3
    },
    {
      date: '2022-04-17T02:00:00.000Z',
      consumption: 8
    },
    {
      date: '2022-04-17T02:30:00.000Z',
      consumption: 19.2
    },
    {
      date: '2022-04-17T03:00:00.000Z',
      consumption: 8.2
    },
    {
      date: '2022-04-17T03:30:00.000Z',
      consumption: 8.4
    },
    {
      date: '2022-04-17T04:00:00.000Z',
      consumption: 8.4
    },
    {
      date: '2022-04-17T04:30:00.000Z',
      consumption: 19.1
    },
    {
      date: '2022-04-17T05:00:00.000Z',
      consumption: 8.3
    },
    {
      date: '2022-04-17T05:30:00.000Z',
      consumption: 8.6
    },
    {
      date: '2022-04-17T06:00:00.000Z',
      consumption: 19.5
    },
    {
      date: '2022-04-17T06:30:00.000Z',
      consumption: 9.5
    },
    {
      date: '2022-04-17T07:00:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-17T07:30:00.000Z',
      consumption: 14.8
    },
    {
      date: '2022-04-17T08:00:00.000Z',
      consumption: 12.7
    },
    {
      date: '2022-04-17T08:30:00.000Z',
      consumption: 8.1
    },
    {
      date: '2022-04-17T09:00:00.000Z',
      consumption: 7.7
    },
    {
      date: '2022-04-17T09:30:00.000Z',
      consumption: 17.9
    },
    {
      date: '2022-04-17T10:00:00.000Z',
      consumption: 7.1
    },
    {
      date: '2022-04-17T10:30:00.000Z',
      consumption: 7.1
    },
    {
      date: '2022-04-17T11:00:00.000Z',
      consumption: 13.6
    },
    {
      date: '2022-04-17T11:30:00.000Z',
      consumption: 11.3
    },
    {
      date: '2022-04-17T12:00:00.000Z',
      consumption: 7.1
    },
    {
      date: '2022-04-17T12:30:00.000Z',
      consumption: 7.2
    },
    {
      date: '2022-04-17T13:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2022-04-17T13:30:00.000Z',
      consumption: 7.3
    },
    {
      date: '2022-04-17T14:00:00.000Z',
      consumption: 7.4
    },
    {
      date: '2022-04-17T14:30:00.000Z',
      consumption: 11.6
    },
    {
      date: '2022-04-17T15:00:00.000Z',
      consumption: 14
    },
    {
      date: '2022-04-17T15:30:00.000Z',
      consumption: 7.2
    },
    {
      date: '2022-04-17T16:00:00.000Z',
      consumption: 7
    },
    {
      date: '2022-04-17T16:30:00.000Z',
      consumption: 18
    },
    {
      date: '2022-04-17T17:00:00.000Z',
      consumption: 8
    },
    {
      date: '2022-04-17T17:30:00.000Z',
      consumption: 7.9
    },
    {
      date: '2022-04-17T18:00:00.000Z',
      consumption: 7.5
    },
    {
      date: '2022-04-17T18:30:00.000Z',
      consumption: 17.7
    },
    {
      date: '2022-04-17T19:00:00.000Z',
      consumption: 7.6
    },
    {
      date: '2022-04-17T19:30:00.000Z',
      consumption: 8
    },
    {
      date: '2022-04-17T20:00:00.000Z',
      consumption: 18.3
    },
    {
      date: '2022-04-17T20:30:00.000Z',
      consumption: 7.8
    },
    {
      date: '2022-04-17T21:00:00.000Z',
      consumption: 8.2
    },
    {
      date: '2022-04-17T21:30:00.000Z',
      consumption: 15.5
    },
    {
      date: '2022-04-17T22:00:00.000Z',
      consumption: 10.9
    },
    {
      date: '2022-04-17T22:30:00.000Z',
      consumption: 8.4
    },
    {
      date: '2022-04-17T23:00:00.000Z',
      consumption: 9.1
    },
    {
      date: '2022-04-17T23:30:00.000Z',
      consumption: 26.8
    },
    {
      date: '2022-04-18T00:00:00.000Z',
      consumption: 13.6
    },
    {
      date: '2022-04-18T00:30:00.000Z',
      consumption: 12.8
    },
    {
      date: '2022-04-18T01:00:00.000Z',
      consumption: 24.7
    },
    {
      date: '2022-04-18T01:30:00.000Z',
      consumption: 13.3
    },
    {
      date: '2022-04-18T02:00:00.000Z',
      consumption: 13.1
    },
    {
      date: '2022-04-18T02:30:00.000Z',
      consumption: 14.3
    },
    {
      date: '2022-04-18T03:00:00.000Z',
      consumption: 23.1
    },
    {
      date: '2022-04-18T03:30:00.000Z',
      consumption: 12.9
    },
    {
      date: '2022-04-18T04:00:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-18T04:30:00.000Z',
      consumption: 23.5
    },
    {
      date: '2022-04-18T05:00:00.000Z',
      consumption: 12.4
    },
    {
      date: '2022-04-18T05:30:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-18T06:00:00.000Z',
      consumption: 19.6
    },
    {
      date: '2022-04-18T06:30:00.000Z',
      consumption: 16.4
    },
    {
      date: '2022-04-18T07:00:00.000Z',
      consumption: 12.9
    },
    {
      date: '2022-04-18T07:30:00.000Z',
      consumption: 13.5
    },
    {
      date: '2022-04-18T08:00:00.000Z',
      consumption: 24.1
    },
    {
      date: '2022-04-18T08:30:00.000Z',
      consumption: 13.1
    },
    {
      date: '2022-04-18T09:00:00.000Z',
      consumption: 12.4
    },
    {
      date: '2022-04-18T09:30:00.000Z',
      consumption: 23.4
    },
    {
      date: '2022-04-18T10:00:00.000Z',
      consumption: 12
    },
    {
      date: '2022-04-18T10:30:00.000Z',
      consumption: 11
    },
    {
      date: '2022-04-18T11:00:00.000Z',
      consumption: 14.7
    },
    {
      date: '2022-04-18T11:30:00.000Z',
      consumption: 19.3
    },
    {
      date: '2022-04-18T12:00:00.000Z',
      consumption: 12.3
    },
    {
      date: '2022-04-18T12:30:00.000Z',
      consumption: 11.5
    },
    {
      date: '2022-04-18T13:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2022-04-18T13:30:00.000Z',
      consumption: 8.4
    },
    {
      date: '2022-04-18T14:00:00.000Z',
      consumption: 8.5
    },
    {
      date: '2022-04-18T14:30:00.000Z',
      consumption: 15.8
    },
    {
      date: '2022-04-18T15:00:00.000Z',
      consumption: 12.5
    },
    {
      date: '2022-04-18T15:30:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-18T16:00:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-18T16:30:00.000Z',
      consumption: 20.3
    },
    {
      date: '2022-04-18T17:00:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-18T17:30:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-18T18:00:00.000Z',
      consumption: 9.5
    },
    {
      date: '2022-04-18T18:30:00.000Z',
      consumption: 19.2
    },
    {
      date: '2022-04-18T19:00:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-18T19:30:00.000Z',
      consumption: 8.5
    },
    {
      date: '2022-04-18T20:00:00.000Z',
      consumption: 19.2
    },
    {
      date: '2022-04-18T20:30:00.000Z',
      consumption: 8.6
    },
    {
      date: '2022-04-18T21:00:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-18T21:30:00.000Z',
      consumption: 19.6
    },
    {
      date: '2022-04-18T22:00:00.000Z',
      consumption: 9.9
    },
    {
      date: '2022-04-18T22:30:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-18T23:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-18T23:30:00.000Z',
      consumption: 29.6
    },
    {
      date: '2022-04-19T00:00:00.000Z',
      consumption: 17
    },
    {
      date: '2022-04-19T00:30:00.000Z',
      consumption: 20.9
    },
    {
      date: '2022-04-19T01:00:00.000Z',
      consumption: 42.6
    },
    {
      date: '2022-04-19T01:30:00.000Z',
      consumption: 32.5
    },
    {
      date: '2022-04-19T02:00:00.000Z',
      consumption: 29.9
    },
    {
      date: '2022-04-19T02:30:00.000Z',
      consumption: 36.5
    },
    {
      date: '2022-04-19T03:00:00.000Z',
      consumption: 28.9
    },
    {
      date: '2022-04-19T03:30:00.000Z',
      consumption: 26.5
    },
    {
      date: '2022-04-19T04:00:00.000Z',
      consumption: 25.1
    },
    {
      date: '2022-04-19T04:30:00.000Z',
      consumption: 38.9
    },
    {
      date: '2022-04-19T05:00:00.000Z',
      consumption: 29.6
    },
    {
      date: '2022-04-19T05:30:00.000Z',
      consumption: 52.8
    },
    {
      date: '2022-04-19T06:00:00.000Z',
      consumption: 48
    },
    {
      date: '2022-04-19T06:30:00.000Z',
      consumption: 18.7
    },
    {
      date: '2022-04-19T07:00:00.000Z',
      consumption: 16.8
    },
    {
      date: '2022-04-19T07:30:00.000Z',
      consumption: 26.1
    },
    {
      date: '2022-04-19T08:00:00.000Z',
      consumption: 15.5
    },
    {
      date: '2022-04-19T08:30:00.000Z',
      consumption: 15.7
    },
    {
      date: '2022-04-19T09:00:00.000Z',
      consumption: 25.3
    },
    {
      date: '2022-04-19T09:30:00.000Z',
      consumption: 17.1
    },
    {
      date: '2022-04-19T10:00:00.000Z',
      consumption: 15.8
    },
    {
      date: '2022-04-19T10:30:00.000Z',
      consumption: 20.8
    },
    {
      date: '2022-04-19T11:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2022-04-19T11:30:00.000Z',
      consumption: 15.4
    },
    {
      date: '2022-04-19T12:00:00.000Z',
      consumption: 13.5
    },
    {
      date: '2022-04-19T12:30:00.000Z',
      consumption: 25.3
    },
    {
      date: '2022-04-19T13:00:00.000Z',
      consumption: 14.8
    },
    {
      date: '2022-04-19T13:30:00.000Z',
      consumption: 12.5
    },
    {
      date: '2022-04-19T14:00:00.000Z',
      consumption: 23
    },
    {
      date: '2022-04-19T14:30:00.000Z',
      consumption: 10.8
    },
    {
      date: '2022-04-19T15:00:00.000Z',
      consumption: 10.9
    },
    {
      date: '2022-04-19T15:30:00.000Z',
      consumption: 9.8
    },
    {
      date: '2022-04-19T16:00:00.000Z',
      consumption: 19
    },
    {
      date: '2022-04-19T16:30:00.000Z',
      consumption: 8.6
    },
    {
      date: '2022-04-19T17:00:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-19T17:30:00.000Z',
      consumption: 18.6
    },
    {
      date: '2022-04-19T18:00:00.000Z',
      consumption: 8.1
    },
    {
      date: '2022-04-19T18:30:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-19T19:00:00.000Z',
      consumption: 19
    },
    {
      date: '2022-04-19T19:30:00.000Z',
      consumption: 9.1
    },
    {
      date: '2022-04-19T20:00:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-19T20:30:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-19T21:00:00.000Z',
      consumption: 19.3
    },
    {
      date: '2022-04-19T21:30:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-19T22:00:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-19T22:30:00.000Z',
      consumption: 19.5
    },
    {
      date: '2022-04-19T23:00:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-19T23:30:00.000Z',
      consumption: 28.5
    },
    {
      date: '2022-04-20T00:00:00.000Z',
      consumption: 34.2
    },
    {
      date: '2022-04-20T00:30:00.000Z',
      consumption: 21.7
    },
    {
      date: '2022-04-20T01:00:00.000Z',
      consumption: 25.7
    },
    {
      date: '2022-04-20T01:30:00.000Z',
      consumption: 25.1
    },
    {
      date: '2022-04-20T02:00:00.000Z',
      consumption: 41.3
    },
    {
      date: '2022-04-20T02:30:00.000Z',
      consumption: 27.2
    },
    {
      date: '2022-04-20T03:00:00.000Z',
      consumption: 28.5
    },
    {
      date: '2022-04-20T03:30:00.000Z',
      consumption: 40.6
    },
    {
      date: '2022-04-20T04:00:00.000Z',
      consumption: 29.8
    },
    {
      date: '2022-04-20T04:30:00.000Z',
      consumption: 30.3
    },
    {
      date: '2022-04-20T05:00:00.000Z',
      consumption: 38.2
    },
    {
      date: '2022-04-20T05:30:00.000Z',
      consumption: 52.5
    },
    {
      date: '2022-04-20T06:00:00.000Z',
      consumption: 47.2
    },
    {
      date: '2022-04-20T06:30:00.000Z',
      consumption: 30
    },
    {
      date: '2022-04-20T07:00:00.000Z',
      consumption: 32
    },
    {
      date: '2022-04-20T07:30:00.000Z',
      consumption: 35.3
    },
    {
      date: '2022-04-20T08:00:00.000Z',
      consumption: 17.9
    },
    {
      date: '2022-04-20T08:30:00.000Z',
      consumption: 13.7
    },
    {
      date: '2022-04-20T09:00:00.000Z',
      consumption: 15
    },
    {
      date: '2022-04-20T09:30:00.000Z',
      consumption: 25.7
    },
    {
      date: '2022-04-20T10:00:00.000Z',
      consumption: 15.1
    },
    {
      date: '2022-04-20T10:30:00.000Z',
      consumption: 14.5
    },
    {
      date: '2022-04-20T11:00:00.000Z',
      consumption: 25
    },
    {
      date: '2022-04-20T11:30:00.000Z',
      consumption: 13.7
    },
    {
      date: '2022-04-20T12:00:00.000Z',
      consumption: 13.2
    },
    {
      date: '2022-04-20T12:30:00.000Z',
      consumption: 22.3
    },
    {
      date: '2022-04-20T13:00:00.000Z',
      consumption: 16.2
    },
    {
      date: '2022-04-20T13:30:00.000Z',
      consumption: 12.7
    },
    {
      date: '2022-04-20T14:00:00.000Z',
      consumption: 12.1
    },
    {
      date: '2022-04-20T14:30:00.000Z',
      consumption: 20.5
    },
    {
      date: '2022-04-20T15:00:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-20T15:30:00.000Z',
      consumption: 10.4
    },
    {
      date: '2022-04-20T16:00:00.000Z',
      consumption: 20.6
    },
    {
      date: '2022-04-20T16:30:00.000Z',
      consumption: 9.5
    },
    {
      date: '2022-04-20T17:00:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-20T17:30:00.000Z',
      consumption: 8.6
    },
    {
      date: '2022-04-20T18:00:00.000Z',
      consumption: 19.7
    },
    {
      date: '2022-04-20T18:30:00.000Z',
      consumption: 8.1
    },
    {
      date: '2022-04-20T19:00:00.000Z',
      consumption: 8.5
    },
    {
      date: '2022-04-20T19:30:00.000Z',
      consumption: 9.8
    },
    {
      date: '2022-04-20T20:00:00.000Z',
      consumption: 19.9
    },
    {
      date: '2022-04-20T20:30:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-20T21:00:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-20T21:30:00.000Z',
      consumption: 20.1
    },
    {
      date: '2022-04-20T22:00:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-20T22:30:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-20T23:00:00.000Z',
      consumption: 19.7
    },
    {
      date: '2022-04-20T23:30:00.000Z',
      consumption: 21.1
    },
    {
      date: '2022-04-21T00:00:00.000Z',
      consumption: 16.6
    },
    {
      date: '2022-04-21T00:30:00.000Z',
      consumption: 24.8
    },
    {
      date: '2022-04-21T01:00:00.000Z',
      consumption: 37.3
    },
    {
      date: '2022-04-21T01:30:00.000Z',
      consumption: 27.8
    },
    {
      date: '2022-04-21T02:00:00.000Z',
      consumption: 30.1
    },
    {
      date: '2022-04-21T02:30:00.000Z',
      consumption: 40.2
    },
    {
      date: '2022-04-21T03:00:00.000Z',
      consumption: 29.2
    },
    {
      date: '2022-04-21T03:30:00.000Z',
      consumption: 27.2
    },
    {
      date: '2022-04-21T04:00:00.000Z',
      consumption: 35.7
    },
    {
      date: '2022-04-21T04:30:00.000Z',
      consumption: 32.1
    },
    {
      date: '2022-04-21T05:00:00.000Z',
      consumption: 27.9
    },
    {
      date: '2022-04-21T05:30:00.000Z',
      consumption: 52.5
    },
    {
      date: '2022-04-21T06:00:00.000Z',
      consumption: 52.6
    },
    {
      date: '2022-04-21T06:30:00.000Z',
      consumption: 20.5
    },
    {
      date: '2022-04-21T07:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2022-04-21T07:30:00.000Z',
      consumption: 28.5
    },
    {
      date: '2022-04-21T08:00:00.000Z',
      consumption: 20.2
    },
    {
      date: '2022-04-21T08:30:00.000Z',
      consumption: 18.5
    },
    {
      date: '2022-04-21T09:00:00.000Z',
      consumption: 30.3
    },
    {
      date: '2022-04-21T09:30:00.000Z',
      consumption: 19.4
    },
    {
      date: '2022-04-21T10:00:00.000Z',
      consumption: 16.7
    },
    {
      date: '2022-04-21T10:30:00.000Z',
      consumption: 25.4
    },
    {
      date: '2022-04-21T11:00:00.000Z',
      consumption: 14.2
    },
    {
      date: '2022-04-21T11:30:00.000Z',
      consumption: 24.1
    },
    {
      date: '2022-04-21T12:00:00.000Z',
      consumption: 14.3
    },
    {
      date: '2022-04-21T12:30:00.000Z',
      consumption: 14.4
    },
    {
      date: '2022-04-21T13:00:00.000Z',
      consumption: 20.4
    },
    {
      date: '2022-04-21T13:30:00.000Z',
      consumption: 16.8
    },
    {
      date: '2022-04-21T14:00:00.000Z',
      consumption: 12.9
    },
    {
      date: '2022-04-21T14:30:00.000Z',
      consumption: 11.7
    },
    {
      date: '2022-04-21T15:00:00.000Z',
      consumption: 21.7
    },
    {
      date: '2022-04-21T15:30:00.000Z',
      consumption: 11.9
    },
    {
      date: '2022-04-21T16:00:00.000Z',
      consumption: 11
    },
    {
      date: '2022-04-21T16:30:00.000Z',
      consumption: 19.6
    },
    {
      date: '2022-04-21T17:00:00.000Z',
      consumption: 8.2
    },
    {
      date: '2022-04-21T17:30:00.000Z',
      consumption: 8.1
    },
    {
      date: '2022-04-21T18:00:00.000Z',
      consumption: 9.1
    },
    {
      date: '2022-04-21T18:30:00.000Z',
      consumption: 19
    },
    {
      date: '2022-04-21T19:00:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-21T19:30:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-21T20:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2022-04-21T20:30:00.000Z',
      consumption: 10.5
    },
    {
      date: '2022-04-21T21:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-21T21:30:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-21T22:00:00.000Z',
      consumption: 20
    },
    {
      date: '2022-04-21T22:30:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-21T23:00:00.000Z',
      consumption: 9.4
    },
    {
      date: '2022-04-21T23:30:00.000Z',
      consumption: 26
    },
    {
      date: '2022-04-22T00:00:00.000Z',
      consumption: 16.2
    },
    {
      date: '2022-04-22T00:30:00.000Z',
      consumption: 19.2
    },
    {
      date: '2022-04-22T01:00:00.000Z',
      consumption: 27.3
    },
    {
      date: '2022-04-22T01:30:00.000Z',
      consumption: 17.7
    },
    {
      date: '2022-04-22T02:00:00.000Z',
      consumption: 20.8
    },
    {
      date: '2022-04-22T02:30:00.000Z',
      consumption: 23.4
    },
    {
      date: '2022-04-22T03:00:00.000Z',
      consumption: 34.8
    },
    {
      date: '2022-04-22T03:30:00.000Z',
      consumption: 20.9
    },
    {
      date: '2022-04-22T04:00:00.000Z',
      consumption: 25.1
    },
    {
      date: '2022-04-22T04:30:00.000Z',
      consumption: 32.1
    },
    {
      date: '2022-04-22T05:00:00.000Z',
      consumption: 17.8
    },
    {
      date: '2022-04-22T05:30:00.000Z',
      consumption: 43.5
    },
    {
      date: '2022-04-22T06:00:00.000Z',
      consumption: 56.1
    },
    {
      date: '2022-04-22T06:30:00.000Z',
      consumption: 14.9
    },
    {
      date: '2022-04-22T07:00:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-22T07:30:00.000Z',
      consumption: 22.7
    },
    {
      date: '2022-04-22T08:00:00.000Z',
      consumption: 12.4
    },
    {
      date: '2022-04-22T08:30:00.000Z',
      consumption: 13
    },
    {
      date: '2022-04-22T09:00:00.000Z',
      consumption: 22.1
    },
    {
      date: '2022-04-22T09:30:00.000Z',
      consumption: 13.4
    },
    {
      date: '2022-04-22T10:00:00.000Z',
      consumption: 12.4
    },
    {
      date: '2022-04-22T10:30:00.000Z',
      consumption: 12.3
    },
    {
      date: '2022-04-22T11:00:00.000Z',
      consumption: 21.8
    },
    {
      date: '2022-04-22T11:30:00.000Z',
      consumption: 11
    },
    {
      date: '2022-04-22T12:00:00.000Z',
      consumption: 11.6
    },
    {
      date: '2022-04-22T12:30:00.000Z',
      consumption: 21.8
    },
    {
      date: '2022-04-22T13:00:00.000Z',
      consumption: 10.5
    },
    {
      date: '2022-04-22T13:30:00.000Z',
      consumption: 10.5
    },
    {
      date: '2022-04-22T14:00:00.000Z',
      consumption: 9.5
    },
    {
      date: '2022-04-22T14:30:00.000Z',
      consumption: 19.4
    },
    {
      date: '2022-04-22T15:00:00.000Z',
      consumption: 9.8
    },
    {
      date: '2022-04-22T15:30:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-22T16:00:00.000Z',
      consumption: 19.1
    },
    {
      date: '2022-04-22T16:30:00.000Z',
      consumption: 8.2
    },
    {
      date: '2022-04-22T17:00:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-22T17:30:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-22T18:00:00.000Z',
      consumption: 19.3
    },
    {
      date: '2022-04-22T18:30:00.000Z',
      consumption: 9.5
    },
    {
      date: '2022-04-22T19:00:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-22T19:30:00.000Z',
      consumption: 12.3
    },
    {
      date: '2022-04-22T20:00:00.000Z',
      consumption: 17.1
    },
    {
      date: '2022-04-22T20:30:00.000Z',
      consumption: 9.4
    },
    {
      date: '2022-04-22T21:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-22T21:30:00.000Z',
      consumption: 19.3
    },
    {
      date: '2022-04-22T22:00:00.000Z',
      consumption: 9.4
    },
    {
      date: '2022-04-22T22:30:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-22T23:00:00.000Z',
      consumption: 19.6
    },
    {
      date: '2022-04-22T23:30:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-23T00:00:00.000Z',
      consumption: 9.1
    },
    {
      date: '2022-04-23T00:30:00.000Z',
      consumption: 19.8
    },
    {
      date: '2022-04-23T01:00:00.000Z',
      consumption: 9.1
    },
    {
      date: '2022-04-23T01:30:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-23T02:00:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-23T02:30:00.000Z',
      consumption: 19.8
    },
    {
      date: '2022-04-23T03:00:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-23T03:30:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-23T04:00:00.000Z',
      consumption: 19.2
    },
    {
      date: '2022-04-23T04:30:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-23T05:00:00.000Z',
      consumption: 8.4
    },
    {
      date: '2022-04-23T05:30:00.000Z',
      consumption: 19.9
    },
    {
      date: '2022-04-23T06:00:00.000Z',
      consumption: 9.8
    },
    {
      date: '2022-04-23T06:30:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-23T07:00:00.000Z',
      consumption: 17.5
    },
    {
      date: '2022-04-23T07:30:00.000Z',
      consumption: 13.1
    },
    {
      date: '2022-04-23T08:00:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-23T08:30:00.000Z',
      consumption: 9.5
    },
    {
      date: '2022-04-23T09:00:00.000Z',
      consumption: 19
    },
    {
      date: '2022-04-23T09:30:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-23T10:00:00.000Z',
      consumption: 8.4
    },
    {
      date: '2022-04-23T10:30:00.000Z',
      consumption: 18.7
    },
    {
      date: '2022-04-23T11:00:00.000Z',
      consumption: 7.9
    },
    {
      date: '2022-04-23T11:30:00.000Z',
      consumption: 8.3
    },
    {
      date: '2022-04-23T12:00:00.000Z',
      consumption: 7.9
    },
    {
      date: '2022-04-23T12:30:00.000Z',
      consumption: 18.5
    },
    {
      date: '2022-04-23T13:00:00.000Z',
      consumption: 7.8
    },
    {
      date: '2022-04-23T13:30:00.000Z',
      consumption: 8.2
    },
    {
      date: '2022-04-23T14:00:00.000Z',
      consumption: 18.1
    },
    {
      date: '2022-04-23T14:30:00.000Z',
      consumption: 8
    },
    {
      date: '2022-04-23T15:00:00.000Z',
      consumption: 7.9
    },
    {
      date: '2022-04-23T15:30:00.000Z',
      consumption: 12.6
    },
    {
      date: '2022-04-23T16:00:00.000Z',
      consumption: 14.5
    },
    {
      date: '2022-04-23T16:30:00.000Z',
      consumption: 8.4
    },
    {
      date: '2022-04-23T17:00:00.000Z',
      consumption: 8
    },
    {
      date: '2022-04-23T17:30:00.000Z',
      consumption: 19.1
    },
    {
      date: '2022-04-23T18:00:00.000Z',
      consumption: 9.4
    },
    {
      date: '2022-04-23T18:30:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-23T19:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-23T19:30:00.000Z',
      consumption: 19.3
    },
    {
      date: '2022-04-23T20:00:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-23T20:30:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-23T21:00:00.000Z',
      consumption: 19.5
    },
    {
      date: '2022-04-23T21:30:00.000Z',
      consumption: 8.6
    },
    {
      date: '2022-04-23T22:00:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-23T22:30:00.000Z',
      consumption: 17.5
    },
    {
      date: '2022-04-23T23:00:00.000Z',
      consumption: 10.5
    },
    {
      date: '2022-04-23T23:30:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-24T00:00:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-24T00:30:00.000Z',
      consumption: 19.4
    },
    {
      date: '2022-04-24T01:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-24T01:30:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-24T02:00:00.000Z',
      consumption: 19.2
    },
    {
      date: '2022-04-24T02:30:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-24T03:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-24T03:30:00.000Z',
      consumption: 19.8
    },
    {
      date: '2022-04-24T04:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-24T04:30:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-24T05:00:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-24T05:30:00.000Z',
      consumption: 20.1
    },
    {
      date: '2022-04-24T06:00:00.000Z',
      consumption: 9.8
    },
    {
      date: '2022-04-24T06:30:00.000Z',
      consumption: 9.9
    },
    {
      date: '2022-04-24T07:00:00.000Z',
      consumption: 19.7
    },
    {
      date: '2022-04-24T07:30:00.000Z',
      consumption: 9.6
    },
    {
      date: '2022-04-24T08:00:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-24T08:30:00.000Z',
      consumption: 19.6
    },
    {
      date: '2022-04-24T09:00:00.000Z',
      consumption: 9.5
    },
    {
      date: '2022-04-24T09:30:00.000Z',
      consumption: 9.1
    },
    {
      date: '2022-04-24T10:00:00.000Z',
      consumption: 19.8
    },
    {
      date: '2022-04-24T10:30:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-24T11:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-24T11:30:00.000Z',
      consumption: 9.4
    },
    {
      date: '2022-04-24T12:00:00.000Z',
      consumption: 20.2
    },
    {
      date: '2022-04-24T12:30:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-24T13:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-24T13:30:00.000Z',
      consumption: 18.7
    },
    {
      date: '2022-04-24T14:00:00.000Z',
      consumption: 7.8
    },
    {
      date: '2022-04-24T14:30:00.000Z',
      consumption: 7.6
    },
    {
      date: '2022-04-24T15:00:00.000Z',
      consumption: 8
    },
    {
      date: '2022-04-24T15:30:00.000Z',
      consumption: 18.2
    },
    {
      date: '2022-04-24T16:00:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-24T16:30:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-24T17:00:00.000Z',
      consumption: 19.4
    },
    {
      date: '2022-04-24T17:30:00.000Z',
      consumption: 8.5
    },
    {
      date: '2022-04-24T18:00:00.000Z',
      consumption: 9.3
    },
    {
      date: '2022-04-24T18:30:00.000Z',
      consumption: 8.6
    },
    {
      date: '2022-04-24T19:00:00.000Z',
      consumption: 19.2
    },
    {
      date: '2022-04-24T19:30:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-24T20:00:00.000Z',
      consumption: 8.6
    },
    {
      date: '2022-04-24T20:30:00.000Z',
      consumption: 17.9
    },
    {
      date: '2022-04-24T21:00:00.000Z',
      consumption: 10.8
    },
    {
      date: '2022-04-24T21:30:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-24T22:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-24T22:30:00.000Z',
      consumption: 19.3
    },
    {
      date: '2022-04-24T23:00:00.000Z',
      consumption: 8.8
    },
    {
      date: '2022-04-24T23:30:00.000Z',
      consumption: 21.3
    },
    {
      date: '2022-04-25T00:00:00.000Z',
      consumption: 29.4
    },
    {
      date: '2022-04-25T00:30:00.000Z',
      consumption: 28.1
    },
    {
      date: '2022-04-25T01:00:00.000Z',
      consumption: 21.2
    },
    {
      date: '2022-04-25T01:30:00.000Z',
      consumption: 41
    },
    {
      date: '2022-04-25T02:00:00.000Z',
      consumption: 26.4
    },
    {
      date: '2022-04-25T02:30:00.000Z',
      consumption: 32.6
    },
    {
      date: '2022-04-25T03:00:00.000Z',
      consumption: 38
    },
    {
      date: '2022-04-25T03:30:00.000Z',
      consumption: 46.7
    },
    {
      date: '2022-04-25T04:00:00.000Z',
      consumption: 30.3
    },
    {
      date: '2022-04-25T04:30:00.000Z',
      consumption: 29.9
    },
    {
      date: '2022-04-25T05:00:00.000Z',
      consumption: 43.8
    },
    {
      date: '2022-04-25T05:30:00.000Z',
      consumption: 59.9
    },
    {
      date: '2022-04-25T06:00:00.000Z',
      consumption: 46.8
    },
    {
      date: '2022-04-25T06:30:00.000Z',
      consumption: 34.9
    },
    {
      date: '2022-04-25T07:00:00.000Z',
      consumption: 16.2
    },
    {
      date: '2022-04-25T07:30:00.000Z',
      consumption: 16.6
    },
    {
      date: '2022-04-25T08:00:00.000Z',
      consumption: 25.8
    },
    {
      date: '2022-04-25T08:30:00.000Z',
      consumption: 17.1
    },
    {
      date: '2022-04-25T09:00:00.000Z',
      consumption: 15.7
    },
    {
      date: '2022-04-25T09:30:00.000Z',
      consumption: 26.8
    },
    {
      date: '2022-04-25T10:00:00.000Z',
      consumption: 15.1
    },
    {
      date: '2022-04-25T10:30:00.000Z',
      consumption: 15.5
    },
    {
      date: '2022-04-25T11:00:00.000Z',
      consumption: 15.6
    },
    {
      date: '2022-04-25T11:30:00.000Z',
      consumption: 25.8
    },
    {
      date: '2022-04-25T12:00:00.000Z',
      consumption: 17.6
    },
    {
      date: '2022-04-25T12:30:00.000Z',
      consumption: 23.9
    },
    {
      date: '2022-04-25T13:00:00.000Z',
      consumption: 16.4
    },
    {
      date: '2022-04-25T13:30:00.000Z',
      consumption: 11.8
    },
    {
      date: '2022-04-25T14:00:00.000Z',
      consumption: 23.2
    },
    {
      date: '2022-04-25T14:30:00.000Z',
      consumption: 11
    },
    {
      date: '2022-04-25T15:00:00.000Z',
      consumption: 10.7
    },
    {
      date: '2022-04-25T15:30:00.000Z',
      consumption: 22.3
    },
    {
      date: '2022-04-25T16:00:00.000Z',
      consumption: 11.1
    },
    {
      date: '2022-04-25T16:30:00.000Z',
      consumption: 11.4
    },
    {
      date: '2022-04-25T17:00:00.000Z',
      consumption: 19.3
    },
    {
      date: '2022-04-25T17:30:00.000Z',
      consumption: 12.6
    },
    {
      date: '2022-04-25T18:00:00.000Z',
      consumption: 10.1
    },
    {
      date: '2022-04-25T18:30:00.000Z',
      consumption: 10.3
    },
    {
      date: '2022-04-25T19:00:00.000Z',
      consumption: 21.9
    },
    {
      date: '2022-04-25T19:30:00.000Z',
      consumption: 10
    },
    {
      date: '2022-04-25T20:00:00.000Z',
      consumption: 9.2
    },
    {
      date: '2022-04-25T20:30:00.000Z',
      consumption: 19.1
    },
    {
      date: '2022-04-25T21:00:00.000Z',
      consumption: 8.6
    },
    {
      date: '2022-04-25T21:30:00.000Z',
      consumption: 8.9
    },
    {
      date: '2022-04-25T22:00:00.000Z',
      consumption: 11.7
    },
    {
      date: '2022-04-25T22:30:00.000Z',
      consumption: 17
    },
    {
      date: '2022-04-25T23:00:00.000Z',
      consumption: 9.4
    },
    {
      date: '2022-04-25T23:30:00.000Z',
      consumption: 29.964
    },
    {
      date: '2022-04-26T00:00:00.000Z',
      consumption: 34.14
    },
    {
      date: '2022-04-26T00:30:00.000Z',
      consumption: 22.296
    },
    {
      date: '2022-04-26T01:00:00.000Z',
      consumption: 29.22
    },
    {
      date: '2022-04-26T01:30:00.000Z',
      consumption: 46.548
    },
    {
      date: '2022-04-26T02:00:00.000Z',
      consumption: 43.356
    },
    {
      date: '2022-04-26T02:30:00.000Z',
      consumption: 41.076
    },
    {
      date: '2022-04-26T03:00:00.000Z',
      consumption: 43.188
    },
    {
      date: '2022-04-26T03:30:00.000Z',
      consumption: 49.512
    },
    {
      date: '2022-04-26T04:00:00.000Z',
      consumption: 40.104
    },
    {
      date: '2022-04-26T04:30:00.000Z',
      consumption: 40.572
    },
    {
      date: '2022-04-26T05:00:00.000Z',
      consumption: 45.228
    },
    {
      date: '2022-04-26T05:30:00.000Z',
      consumption: 59.42
    },
    {
      date: '2022-04-26T06:00:00.000Z',
      consumption: 47.608
    },
    {
      date: '2022-04-26T06:30:00.000Z',
      consumption: 31.26
    },
    {
      date: '2022-04-26T07:00:00.000Z',
      consumption: 17.304
    },
    {
      date: '2022-04-26T07:30:00.000Z',
      consumption: 15.516
    },
    {
      date: '2022-04-26T08:00:00.000Z',
      consumption: 25.224
    },
    {
      date: '2022-04-26T08:30:00.000Z',
      consumption: 14.76
    },
    {
      date: '2022-04-26T09:00:00.000Z',
      consumption: 14.772
    },
    {
      date: '2022-04-26T09:30:00.000Z',
      consumption: 25.68
    },
    {
      date: '2022-04-26T10:00:00.000Z',
      consumption: 15.144
    },
    {
      date: '2022-04-26T10:30:00.000Z',
      consumption: 13.8
    },
    {
      date: '2022-04-26T11:00:00.000Z',
      consumption: 13.068
    },
    {
      date: '2022-04-26T11:30:00.000Z',
      consumption: 24.036
    },
    {
      date: '2022-04-26T12:00:00.000Z',
      consumption: 13.764
    },
    {
      date: '2022-04-26T12:30:00.000Z',
      consumption: 13.848
    },
    {
      date: '2022-04-26T13:00:00.000Z',
      consumption: 23.724
    },
    {
      date: '2022-04-26T13:30:00.000Z',
      consumption: 10.908
    },
    {
      date: '2022-04-26T14:00:00.000Z',
      consumption: 10.08
    },
    {
      date: '2022-04-26T14:30:00.000Z',
      consumption: 20.424
    },
    {
      date: '2022-04-26T15:00:00.000Z',
      consumption: 9.96
    },
    {
      date: '2022-04-26T15:30:00.000Z',
      consumption: 9.744
    },
    {
      date: '2022-04-26T16:00:00.000Z',
      consumption: 10.308
    },
    {
      date: '2022-04-26T16:30:00.000Z',
      consumption: 19.584
    },
    {
      date: '2022-04-26T17:00:00.000Z',
      consumption: 8.688
    },
    {
      date: '2022-04-26T17:30:00.000Z',
      consumption: 8.904
    },
    {
      date: '2022-04-26T18:00:00.000Z',
      consumption: 20.28
    },
    {
      date: '2022-04-26T18:30:00.000Z',
      consumption: 8.952
    },
    {
      date: '2022-04-26T19:00:00.000Z',
      consumption: 8.904
    },
    {
      date: '2022-04-26T19:30:00.000Z',
      consumption: 9.168
    },
    {
      date: '2022-04-26T20:00:00.000Z',
      consumption: 18.996
    },
    {
      date: '2022-04-26T20:30:00.000Z',
      consumption: 8.904
    },
    {
      date: '2022-04-26T21:00:00.000Z',
      consumption: 8.904
    },
    {
      date: '2022-04-26T21:30:00.000Z',
      consumption: 19.728
    },
    {
      date: '2022-04-26T22:00:00.000Z',
      consumption: 10.248
    },
    {
      date: '2022-04-26T22:30:00.000Z',
      consumption: 9.648
    },
    {
      date: '2022-04-26T23:00:00.000Z',
      consumption: 19.716
    },
    {
      date: '2022-04-26T23:30:00.000Z',
      consumption: 26.244
    },
    {
      date: '2022-04-27T00:00:00.000Z',
      consumption: 21.036
    },
    {
      date: '2022-04-27T00:30:00.000Z',
      consumption: 32.46
    },
    {
      date: '2022-04-27T01:00:00.000Z',
      consumption: 29.748
    },
    {
      date: '2022-04-27T01:30:00.000Z',
      consumption: 33.3
    },
    {
      date: '2022-04-27T02:00:00.000Z',
      consumption: 38.988
    },
    {
      date: '2022-04-27T02:30:00.000Z',
      consumption: 49.476
    },
    {
      date: '2022-04-27T03:00:00.000Z',
      consumption: 35.268
    },
    {
      date: '2022-04-27T03:30:00.000Z',
      consumption: 36.372
    },
    {
      date: '2022-04-27T04:00:00.000Z',
      consumption: 47.712
    },
    {
      date: '2022-04-27T04:30:00.000Z',
      consumption: 37.548
    },
    {
      date: '2022-04-27T05:00:00.000Z',
      consumption: 38.256
    },
    {
      date: '2022-04-27T05:30:00.000Z',
      consumption: 59.615
    },
    {
      date: '2022-04-27T06:00:00.000Z',
      consumption: 45.937
    },
    {
      date: '2022-04-27T06:30:00.000Z',
      consumption: 21.852
    },
    {
      date: '2022-04-27T07:00:00.000Z',
      consumption: 26.304
    },
    {
      date: '2022-04-27T07:30:00.000Z',
      consumption: 16.068
    },
    {
      date: '2022-04-27T08:00:00.000Z',
      consumption: 16.08
    },
    {
      date: '2022-04-27T08:30:00.000Z',
      consumption: 24.456
    },
    {
      date: '2022-04-27T09:00:00.000Z',
      consumption: 18.528
    },
    {
      date: '2022-04-27T09:30:00.000Z',
      consumption: 16.224
    },
    {
      date: '2022-04-27T10:00:00.000Z',
      consumption: 25.008
    },
    {
      date: '2022-04-27T10:30:00.000Z',
      consumption: 20.316
    },
    {
      date: '2022-04-27T11:00:00.000Z',
      consumption: 18.6
    },
    {
      date: '2022-04-27T11:30:00.000Z',
      consumption: 14.568
    },
    {
      date: '2022-04-27T12:00:00.000Z',
      consumption: 24.84
    },
    {
      date: '2022-04-27T12:30:00.000Z',
      consumption: 14.388
    },
    {
      date: '2022-04-27T13:00:00.000Z',
      consumption: 14.16
    },
    {
      date: '2022-04-27T13:30:00.000Z',
      consumption: 22.668
    },
    {
      date: '2022-04-27T14:00:00.000Z',
      consumption: 14.748
    },
    {
      date: '2022-04-27T14:30:00.000Z',
      consumption: 11.52
    },
    {
      date: '2022-04-27T15:00:00.000Z',
      consumption: 22.476
    },
    {
      date: '2022-04-27T15:30:00.000Z',
      consumption: 11.64
    },
    {
      date: '2022-04-27T16:00:00.000Z',
      consumption: 10.56
    },
    {
      date: '2022-04-27T16:30:00.000Z',
      consumption: 8.916
    },
    {
      date: '2022-04-27T17:00:00.000Z',
      consumption: 19.572
    },
    {
      date: '2022-04-27T17:30:00.000Z',
      consumption: 8.592
    },
    {
      date: '2022-04-27T18:00:00.000Z',
      consumption: 9.828
    },
    {
      date: '2022-04-27T18:30:00.000Z',
      consumption: 16.248
    },
    {
      date: '2022-04-27T19:00:00.000Z',
      consumption: 12.108
    },
    {
      date: '2022-04-27T19:30:00.000Z',
      consumption: 8.7
    },
    {
      date: '2022-04-27T20:00:00.000Z',
      consumption: 8.88
    },
    {
      date: '2022-04-27T20:30:00.000Z',
      consumption: 19.068
    },
    {
      date: '2022-04-27T21:00:00.000Z',
      consumption: 9.024
    },
    {
      date: '2022-04-27T21:30:00.000Z',
      consumption: 9.36
    },
    {
      date: '2022-04-27T22:00:00.000Z',
      consumption: 19.68
    },
    {
      date: '2022-04-27T22:30:00.000Z',
      consumption: 9.132
    },
    {
      date: '2022-04-27T23:00:00.000Z',
      consumption: 9.528
    },
    {
      date: '2022-04-27T23:30:00.000Z',
      consumption: 37.644
    },
    {
      date: '2022-04-28T00:00:00.000Z',
      consumption: 20.892
    },
    {
      date: '2022-04-28T00:30:00.000Z',
      consumption: 21.432
    },
    {
      date: '2022-04-28T01:00:00.000Z',
      consumption: 41.664
    },
    {
      date: '2022-04-28T01:30:00.000Z',
      consumption: 31.764
    },
    {
      date: '2022-04-28T02:00:00.000Z',
      consumption: 33.312
    },
    {
      date: '2022-04-28T02:30:00.000Z',
      consumption: 46.44
    },
    {
      date: '2022-04-28T03:00:00.000Z',
      consumption: 46.224
    },
    {
      date: '2022-04-28T03:30:00.000Z',
      consumption: 33.372
    },
    {
      date: '2022-04-28T04:00:00.000Z',
      consumption: 41.256
    },
    {
      date: '2022-04-28T04:30:00.000Z',
      consumption: 49.812
    },
    {
      date: '2022-04-28T05:00:00.000Z',
      consumption: 38.88
    },
    {
      date: '2022-04-28T05:30:00.000Z',
      consumption: 50.375
    },
    {
      date: '2022-04-28T06:00:00.000Z',
      consumption: 55.8
    },
    {
      date: '2022-04-28T06:30:00.000Z',
      consumption: 21.229
    },
    {
      date: '2022-04-28T07:00:00.000Z',
      consumption: 18.288
    },
    {
      date: '2022-04-28T07:30:00.000Z',
      consumption: 28.164
    },
    {
      date: '2022-04-28T08:00:00.000Z',
      consumption: 29.016
    },
    {
      date: '2022-04-28T08:30:00.000Z',
      consumption: 19.26
    },
    {
      date: '2022-04-28T09:00:00.000Z',
      consumption: 27.996
    },
    {
      date: '2022-04-28T09:30:00.000Z',
      consumption: 17.124
    },
    {
      date: '2022-04-28T10:00:00.000Z',
      consumption: 15.876
    },
    {
      date: '2022-04-28T10:30:00.000Z',
      consumption: 23.784
    },
    {
      date: '2022-04-28T11:00:00.000Z',
      consumption: 20.448
    },
    {
      date: '2022-04-28T11:30:00.000Z',
      consumption: 15.816
    },
    {
      date: '2022-04-28T12:00:00.000Z',
      consumption: 17.58
    },
    {
      date: '2022-04-28T12:30:00.000Z',
      consumption: 26.82
    },
    {
      date: '2022-04-28T13:00:00.000Z',
      consumption: 17.88
    },
    {
      date: '2022-04-28T13:30:00.000Z',
      consumption: 11.712
    },
    {
      date: '2022-04-28T14:00:00.000Z',
      consumption: 22.452
    },
    {
      date: '2022-04-28T14:30:00.000Z',
      consumption: 11.508
    },
    {
      date: '2022-04-28T15:00:00.000Z',
      consumption: 13.728
    },
    {
      date: '2022-04-28T15:30:00.000Z',
      consumption: 22.404
    },
    {
      date: '2022-04-28T16:00:00.000Z',
      consumption: 11.652
    },
    {
      date: '2022-04-28T16:30:00.000Z',
      consumption: 9.372
    },
    {
      date: '2022-04-28T17:00:00.000Z',
      consumption: 10.728
    },
    {
      date: '2022-04-28T17:30:00.000Z',
      consumption: 19.272
    },
    {
      date: '2022-04-28T18:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-28T18:30:00.000Z',
      consumption: 9.876
    },
    {
      date: '2022-04-28T19:00:00.000Z',
      consumption: 20.664
    },
    {
      date: '2022-04-28T19:30:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-28T20:00:00.000Z',
      consumption: 10.272
    },
    {
      date: '2022-04-28T20:30:00.000Z',
      consumption: 21.372
    },
    {
      date: '2022-04-28T21:00:00.000Z',
      consumption: 10.344
    },
    {
      date: '2022-04-28T21:30:00.000Z',
      consumption: 9.984
    },
    {
      date: '2022-04-28T22:00:00.000Z',
      consumption: 10.884
    },
    {
      date: '2022-04-28T22:30:00.000Z',
      consumption: 18.528
    },
    {
      date: '2022-04-28T23:00:00.000Z',
      consumption: 10.116
    },
    {
      date: '2022-04-28T23:30:00.000Z',
      consumption: 25.536
    },
    {
      date: '2022-04-29T00:00:00.000Z',
      consumption: 38.676
    },
    {
      date: '2022-04-29T00:30:00.000Z',
      consumption: 22.164
    },
    {
      date: '2022-04-29T01:00:00.000Z',
      consumption: 31.836
    },
    {
      date: '2022-04-29T01:30:00.000Z',
      consumption: 48.972
    },
    {
      date: '2022-04-29T02:00:00.000Z',
      consumption: 41.904
    },
    {
      date: '2022-04-29T02:30:00.000Z',
      consumption: 53.24
    },
    {
      date: '2022-04-29T03:00:00.000Z',
      consumption: 57.145
    },
    {
      date: '2022-04-29T03:30:00.000Z',
      consumption: 46.575
    },
    {
      date: '2022-04-29T04:00:00.000Z',
      consumption: 55.09
    },
    {
      date: '2022-04-29T04:30:00.000Z',
      consumption: 62.735
    },
    {
      date: '2022-04-29T05:00:00.000Z',
      consumption: 54.53
    },
    {
      date: '2022-04-29T05:30:00.000Z',
      consumption: 54.405
    },
    {
      date: '2022-04-29T06:00:00.000Z',
      consumption: 52.105
    },
    {
      date: '2022-04-29T06:30:00.000Z',
      consumption: 30.135
    },
    {
      date: '2022-04-29T07:00:00.000Z',
      consumption: 16.44
    },
    {
      date: '2022-04-29T07:30:00.000Z',
      consumption: 15.888
    },
    {
      date: '2022-04-29T08:00:00.000Z',
      consumption: 26.916
    },
    {
      date: '2022-04-29T08:30:00.000Z',
      consumption: 21.312
    },
    {
      date: '2022-04-29T09:00:00.000Z',
      consumption: 16.02
    },
    {
      date: '2022-04-29T09:30:00.000Z',
      consumption: 25.2
    },
    {
      date: '2022-04-29T10:00:00.000Z',
      consumption: 14.82
    },
    {
      date: '2022-04-29T10:30:00.000Z',
      consumption: 14.316
    },
    {
      date: '2022-04-29T11:00:00.000Z',
      consumption: 24.084
    },
    {
      date: '2022-04-29T11:30:00.000Z',
      consumption: 12.144
    },
    {
      date: '2022-04-29T12:00:00.000Z',
      consumption: 12.228
    },
    {
      date: '2022-04-29T12:30:00.000Z',
      consumption: 15.696
    },
    {
      date: '2022-04-29T13:00:00.000Z',
      consumption: 20.112
    },
    {
      date: '2022-04-29T13:30:00.000Z',
      consumption: 9.528
    },
    {
      date: '2022-04-29T14:00:00.000Z',
      consumption: 11.028
    },
    {
      date: '2022-04-29T14:30:00.000Z',
      consumption: 24.912
    },
    {
      date: '2022-04-29T15:00:00.000Z',
      consumption: 11.28
    },
    {
      date: '2022-04-29T15:30:00.000Z',
      consumption: 8.604
    },
    {
      date: '2022-04-29T16:00:00.000Z',
      consumption: 13.752
    },
    {
      date: '2022-04-29T16:30:00.000Z',
      consumption: 14.136
    },
    {
      date: '2022-04-29T17:00:00.000Z',
      consumption: 8.472
    },
    {
      date: '2022-04-29T17:30:00.000Z',
      consumption: 8.4
    },
    {
      date: '2022-04-29T18:00:00.000Z',
      consumption: 19.512
    },
    {
      date: '2022-04-29T18:30:00.000Z',
      consumption: 8.76
    },
    {
      date: '2022-04-29T19:00:00.000Z',
      consumption: 8.016
    },
    {
      date: '2022-04-29T19:30:00.000Z',
      consumption: 9.72
    },
    {
      date: '2022-04-29T20:00:00.000Z',
      consumption: 17.364
    },
    {
      date: '2022-04-29T20:30:00.000Z',
      consumption: 8.604
    },
    {
      date: '2022-04-29T21:00:00.000Z',
      consumption: 8.292
    },
    {
      date: '2022-04-29T21:30:00.000Z',
      consumption: 19.524
    },
    {
      date: '2022-04-29T22:00:00.000Z',
      consumption: 8.712
    },
    {
      date: '2022-04-29T22:30:00.000Z',
      consumption: 8.676
    },
    {
      date: '2022-04-29T23:00:00.000Z',
      consumption: 19.584
    },
    {
      date: '2022-04-29T23:30:00.000Z',
      consumption: 8.532
    },
    {
      date: '2022-04-30T00:00:00.000Z',
      consumption: 8.928
    },
    {
      date: '2022-04-30T00:30:00.000Z',
      consumption: 12.12
    },
    {
      date: '2022-04-30T01:00:00.000Z',
      consumption: 16.608
    },
    {
      date: '2022-04-30T01:30:00.000Z',
      consumption: 8.916
    },
    {
      date: '2022-04-30T02:00:00.000Z',
      consumption: 8.772
    },
    {
      date: '2022-04-30T02:30:00.000Z',
      consumption: 19.704
    },
    {
      date: '2022-04-30T03:00:00.000Z',
      consumption: 8.748
    },
    {
      date: '2022-04-30T03:30:00.000Z',
      consumption: 9.192
    },
    {
      date: '2022-04-30T04:00:00.000Z',
      consumption: 19.944
    },
    {
      date: '2022-04-30T04:30:00.000Z',
      consumption: 8.868
    },
    {
      date: '2022-04-30T05:00:00.000Z',
      consumption: 9.912
    },
    {
      date: '2022-04-30T05:30:00.000Z',
      consumption: 19.992
    },
    {
      date: '2022-04-30T06:00:00.000Z',
      consumption: 8.748
    },
    {
      date: '2022-04-30T06:30:00.000Z',
      consumption: 8.664
    },
    {
      date: '2022-04-30T07:00:00.000Z',
      consumption: 19.008
    },
    {
      date: '2022-04-30T07:30:00.000Z',
      consumption: 8.832
    },
    {
      date: '2022-04-30T08:00:00.000Z',
      consumption: 8.676
    },
    {
      date: '2022-04-30T08:30:00.000Z',
      consumption: 9.66
    },
    {
      date: '2022-04-30T09:00:00.000Z',
      consumption: 17.148
    },
    {
      date: '2022-04-30T09:30:00.000Z',
      consumption: 8.496
    },
    {
      date: '2022-04-30T10:00:00.000Z',
      consumption: 8.256
    },
    {
      date: '2022-04-30T10:30:00.000Z',
      consumption: 17.76
    },
    {
      date: '2022-04-30T11:00:00.000Z',
      consumption: 7.452
    },
    {
      date: '2022-04-30T11:30:00.000Z',
      consumption: 7.38
    },
    {
      date: '2022-04-30T12:00:00.000Z',
      consumption: 18.612
    },
    {
      date: '2022-04-30T12:30:00.000Z',
      consumption: 8.04
    },
    {
      date: '2022-04-30T13:00:00.000Z',
      consumption: 7.428
    },
    {
      date: '2022-04-30T13:30:00.000Z',
      consumption: 17.556
    },
    {
      date: '2022-04-30T14:00:00.000Z',
      consumption: 7.104
    },
    {
      date: '2022-04-30T14:30:00.000Z',
      consumption: 7.332
    },
    {
      date: '2022-04-30T15:00:00.000Z',
      consumption: 7.692
    },
    {
      date: '2022-04-30T15:30:00.000Z',
      consumption: 19.512
    },
    {
      date: '2022-04-30T16:00:00.000Z',
      consumption: 8.856
    },
    {
      date: '2022-04-30T16:30:00.000Z',
      consumption: 8.328
    },
    {
      date: '2022-04-30T17:00:00.000Z',
      consumption: 18.372
    },
    {
      date: '2022-04-30T17:30:00.000Z',
      consumption: 7.8
    },
    {
      date: '2022-04-30T18:00:00.000Z',
      consumption: 7.584
    },
    {
      date: '2022-04-30T18:30:00.000Z',
      consumption: 8.148
    },
    {
      date: '2022-04-30T19:00:00.000Z',
      consumption: 18.876
    },
    {
      date: '2022-04-30T19:30:00.000Z',
      consumption: 8.868
    },
    {
      date: '2022-04-30T20:00:00.000Z',
      consumption: 8.88
    },
    {
      date: '2022-04-30T20:30:00.000Z',
      consumption: 19.764
    },
    {
      date: '2022-04-30T21:00:00.000Z',
      consumption: 9.276
    },
    {
      date: '2022-04-30T21:30:00.000Z',
      consumption: 8.964
    },
    {
      date: '2022-04-30T22:00:00.000Z',
      consumption: 19.524
    },
    {
      date: '2022-04-30T22:30:00.000Z',
      consumption: 8.688
    },
    {
      date: '2022-04-30T23:00:00.000Z',
      consumption: 9
    },
    {
      date: '2022-04-30T23:30:00.000Z',
      consumption: 10.068
    }
  ],
  nightConsumption: 14535.97599999999,
  dayConsumption: 15747.307999999986,
  maxDemand: 172.6,
  peakLoad: 25.208844447996103
};

const monthlyConsumption = {
  accountId: '655fcf07b7d392001404536f',
  totalConsumption: 814897.5701157862,
  granularity: 'monthly',
  startDate: '2023-01-01T00:00:00.000Z',
  endDate: '2023-12-31T23:59:59.999Z',
  data: [
    {
      date: '2023-01-01T00:00:00.000Z',
      consumption: 63341.5,
      simulatedConsumption: 0,
      cost: 20450.76,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-02-01T00:00:00.000Z',
      consumption: 49266.7,
      simulatedConsumption: 0,
      cost: 16262.61,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-03-01T00:00:00.000Z',
      consumption: 76841,
      simulatedConsumption: 0,
      cost: 24799.92,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-04-01T00:00:00.000Z',
      consumption: 62277.5,
      simulatedConsumption: 0,
      cost: 21541.81,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-05-01T00:00:00.000Z',
      consumption: 40020.93921568627,
      simulatedConsumption: 0,
      cost: 13938.615098039214,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-06-01T00:00:00.000Z',
      consumption: 95149.43090009985,
      simulatedConsumption: 0,
      cost: 33087.34449379539,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-07-01T00:00:00.000Z',
      consumption: 67251,
      simulatedConsumption: 0,
      cost: 23355.17,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-08-01T00:00:00.000Z',
      consumption: 65820.3,
      simulatedConsumption: 0,
      cost: 22952.63,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-09-01T00:00:00.000Z',
      consumption: 66554.9,
      simulatedConsumption: 0,
      cost: 23233.03,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-10-01T00:00:00.000Z',
      consumption: 73355.1,
      simulatedConsumption: 0,
      cost: 25586.06,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-11-01T00:00:00.000Z',
      consumption: 81290.3,
      simulatedConsumption: 0,
      cost: 28228.67,
      rates: {
        byName: {},
        byType: {}
      }
    },
    {
      date: '2023-12-01T00:00:00.000Z',
      consumption: 73728.9,
      simulatedConsumption: 0,
      cost: 25860.45,
      rates: {
        byName: {
          'Climate Change Levy': 571.4,
          'Supply Standing Charge': 458.45,
          'Supply Capacity Charge': 914.11,
          'UK Standard Rate': 5172.09,
          'Reactive Power': 25.7,
          'Energy Rate - Day; 07:00-24:00': 21141.13,
          'Energy Rate - Night; 00:00-07:00': 2749.66
        },
        byType: {
          levy: 597.1,
          daily: 458.45,
          kva: 914.11,
          tax: 5172.09,
          unitRate: 23890.79
        }
      }
    }
  ],
  totalCost: 279297.0695918346,
  totalRates: {
    byName: {
      'Climate Change Levy': 571.4,
      'Supply Standing Charge': 458.45,
      'Supply Capacity Charge': 914.11,
      'UK Standard Rate': 5172.09,
      'Reactive Power': 25.7,
      'Energy Rate - Day; 07:00-24:00': 21141.13,
      'Energy Rate - Night; 00:00-07:00': 2749.66
    },
    byType: {
      levy: 597.1,
      daily: 458.45,
      kva: 914.11,
      tax: 5172.09,
      unitRate: 23890.79
    }
  }
};

const monthlyPrevConsumption = {
  accountId: '655fcf07b7d392001404536f',
  totalConsumption: 360061.13199999987,
  granularity: 'monthly',
  startDate: '2022-01-01T00:00:00.000Z',
  endDate: '2022-12-31T23:59:59.999Z',
  data: [
    {
      date: '2022-01-01T00:00:00.000Z',
      consumption: 54050.98799999992,
      emissions: {
        total: 12162.553319759982,
        scope1: 0,
        scope2: 11193.959614799984,
        scope3: 968.5937049599985
      }
    },
    {
      date: '2022-02-01T00:00:00.000Z',
      consumption: 45577.42799999997,
      emissions: {
        total: 10255.832848559994,
        scope1: 0,
        scope2: 9439.085338799994,
        scope3: 816.7475097599994
      }
    },
    {
      date: '2022-03-01T00:00:00.000Z',
      consumption: 40345.02400000002,
      emissions: {
        total: 9078.437300480004,
        scope1: 0,
        scope2: 8355.454470400004,
        scope3: 722.9828300800003
      }
    },
    {
      date: '2022-04-01T00:00:00.000Z',
      consumption: 30283.283999999923,
      emissions: {
        total: 6814.344565679983,
        scope1: 0,
        scope2: 6271.668116399985,
        scope3: 542.6764492799986
      }
    },
    {
      date: '2022-05-01T00:00:00.000Z',
      consumption: 23668.067999999985,
      emissions: {
        total: 5325.788661359997,
        scope1: 0,
        scope2: 4901.656882799997,
        scope3: 424.1317785599997
      }
    },
    {
      date: '2022-06-01T00:00:00.000Z',
      consumption: 20124.93599999998,
      emissions: {
        total: 4528.513098719996,
        scope1: 0,
        scope2: 4167.874245599996,
        scope3: 360.6388531199996
      }
    },
    {
      date: '2022-07-01T00:00:00.000Z',
      consumption: 18751.991999999984,
      emissions: {
        total: 4219.573239839997,
        scope1: 0,
        scope2: 3883.537543199997,
        scope3: 336.0356966399997
      }
    },
    {
      date: '2022-08-01T00:00:00.000Z',
      consumption: 18739.888000000003,
      emissions: {
        total: 4216.8495977600005,
        scope1: 0,
        scope2: 3881.0308048000006,
        scope3: 335.81879296
      }
    },
    {
      date: '2022-09-01T00:00:00.000Z',
      consumption: 19677.844000000012,
      emissions: {
        total: 4427.908456880003,
        scope1: 0,
        scope2: 4075.2814924000027,
        scope3: 352.6269644800002
      }
    },
    {
      date: '2022-10-01T00:00:00.000Z',
      consumption: 23414.12400000001,
      emissions: {
        total: 5268.646182480003,
        scope1: 0,
        scope2: 4849.065080400002,
        scope3: 419.58110208000016
      }
    },
    {
      date: '2022-11-01T00:00:00.000Z',
      consumption: 27379.848000000035,
      emissions: {
        total: 6161.013396960008,
        scope1: 0,
        scope2: 5670.3665208000075,
        scope3: 490.6468761600006
      }
    },
    {
      date: '2022-12-01T00:00:00.000Z',
      consumption: 38047.708000000035,
      emissions: {
        total: 8561.495254160009,
        scope1: 0,
        scope2: 7879.680326800008,
        scope3: 681.8149273600005
      }
    }
  ],
  totalEmissions: {
    total: 81020.95592263999,
    scope1: 0,
    scope2: 74568.66043719999,
    scope3: 6452.295485439998
  }
};

export default {
  tables: {
    HHCapacityInformation: {
      data: halfHourlyConsumption,
      prevData: halfHourlyConsumptionPrev,
      period: { startDate: moment.utc('2024-03-01T00:00:00.000Z'), startDateMinusYear: moment('2023-03-01T00:00:00.000Z') },
      props: {}
    }
  },
  charts: {
    AccountDailyConsumption: {
      data: halfHourlyConsumption.data,
      period: { startDate: moment.utc('2023-03-01T00:00:00.000Z'), endDate: moment.utc('2023-03-31T23:59:59.999Z') },
      props: {}
    },
    AccountHourlyConsumptionIntervals: {
      data: halfHourlyConsumption.data,
      period: { startDate: moment.utc('2023-04-01T00:00:00.000Z'), endDate: moment.utc('2023-04-30T23:59:59.999Z') },
      height: '750px',
      props: { showWeekdayAvg: true }
    },
    AccountHourlyDemand: {
      data: halfHourlyConsumption.data,
      period: { startDate: moment.utc('2023-01-01T00:00:00.000Z'), endDate: moment.utc('2023-12-31T23:59:59.999Z') },
      props: {}
    },
    AccountMonthlyConsumption: {
      data: [...monthlyPrevConsumption.data, ...monthlyConsumption.data],
      period: { startDate: moment.utc('2023-01-01T00:00:00.000Z'), endDate: moment.utc('2023-12-31T23:59:59.999Z'), comparisonPeriods: 2 },
      props: {}
    }
  }
};
