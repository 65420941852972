var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-5 col-lg-4"
  }, [_c('div', [_c('SectionTitle', {
    attrs: {
      "icon": "fa-file-chart-pie"
    }
  }, [_vm._v("Report Builder")]), _vm._m(1)], 1), _c('div', {
    staticClass: "bg-light rounded-md p-4 mb-3"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_vm._m(2), _c('div', {
    staticClass: "col-md-3 text-right"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.titlePage.show,
      expression: "form.titlePage.show"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "id": "form-titlePage-show",
      "type": "checkbox",
      "name": "form-titlePage-show"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.titlePage.show) ? _vm._i(_vm.form.titlePage.show, null) > -1 : _vm.form.titlePage.show
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.form.titlePage.show,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form.titlePage, "show", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form.titlePage, "show", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form.titlePage, "show", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "form-titlePage-show"
    }
  }, [_vm._v("Include")])])])]), _vm.form.titlePage.show ? _c('div', {
    staticClass: "row border-top pt-3 mt-3"
  }, [_c('div', {
    staticClass: "col-xl-9"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "title",
      "label": "Title",
      "type": "text"
    },
    model: {
      value: _vm.form.titlePage.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form.titlePage, "title", $$v);
      },
      expression: "form.titlePage.title"
    }
  }), _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "subTitle",
      "label": "Sub-title",
      "type": "text"
    },
    model: {
      value: _vm.form.titlePage.subTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.form.titlePage, "subTitle", $$v);
      },
      expression: "form.titlePage.subTitle"
    }
  })], 1)]) : _vm._e()]), _vm._l(_vm.form.pages, function (page, pageIdx) {
    return _c('div', {
      key: pageIdx,
      staticClass: "page-form rounded-md py-2 px-3 mb-3"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_c('h5', {
      staticClass: "mb-3"
    }, [_vm._v("Page " + _vm._s(pageIdx + 1))])]), _c('div', {
      staticClass: "col-md-3 text-right"
    }, [_c('button', {
      staticClass: "btn btn-sm btn-link text-danger font-w600",
      on: {
        "click": function click($event) {
          _vm.modals.deletePage = pageIdx;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times fa-lg"
    })])])]), _c('ReportBuilderPageForm', {
      attrs: {
        "value": page,
        "index": pageIdx
      },
      on: {
        "input": function input(data) {
          return _vm.onUpdatePage(pageIdx, data);
        }
      }
    })], 1);
  }), _c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary btn-block",
    on: {
      "click": _vm.onAddPage
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-1"
  }), _vm._v(" Add Page")])])]), _c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col"
  }, [!_vm.generating ? _c('button', {
    staticClass: "btn btn-primary btn-block",
    on: {
      "click": _vm.onClickSave
    }
  }, [_c('i', {
    staticClass: "fa fa-save mr-1"
  }), _vm._v(" Save Report")]) : _c('button', {
    staticClass: "btn btn-primary btn-block"
  }, [_c('i', {
    staticClass: "fa fa-gear mr-1 fa-spin"
  }), _vm._v(" Saving...")])])])], 2), _c('div', {
    staticClass: "col-xl-7 col-lg-8"
  }, [_c('div', {
    staticClass: "rounded-md bg-lighter py-4 px-5"
  }, [_vm._m(3), _c('ReportBuilderPreview', {
    attrs: {
      "form": _vm.form
    }
  })], 1)])]), _c('ConfirmModal', {
    attrs: {
      "open": _vm.modals.deletePage !== false,
      "title": "Delete PageonDeletePage",
      "text": "Are you sure you want to delete this page?"
    },
    on: {
      "submit": _vm.onDeletePage,
      "close": function close($event) {
        _vm.modals.deletePage = false;
      }
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert alert-info"
  }, [_c('strong', [_c('i', {
    staticClass: "fa fa-flask mr-1"
  }), _vm._v("EXPERIMENTAL")]), _vm._v(" This feature was recently added and may have limited functionality. Report any issues or feedback using the question mark at the bottom right. ")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mb-3"
  }, [_vm._v(" The "), _c('strong', [_vm._v("Report Builder")]), _vm._v(" is where you can create professional, re-usable PDF reports containing graphs, tables and text. Once a report is designed, it is possible to schedule reports to be generated and sent out at selected intervals, as well as on-demand. ")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Title Page")]), _c('p', {
    staticClass: "font-size-sm mb-0"
  }, [_vm._v("A nice looking title page for your report. This is optional.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('h4', [_c('i', {
    staticClass: "fa fa-search text-primary ml-1"
  }), _vm._v(" Preview")])]);
}]

export { render, staticRenderFns }