<template>
  <div class="bg-lighter rounded-md py-3 px-4">
    <h5 v-if="$slots.title" class="mb-1"><slot name="title"></slot></h5>
    <p style="white-space: pre-line" class="white-space mb-0"><slot></slot></p>
  </div>
</template>
<script>
export default {
  name: 'TextBox'
};
</script>
