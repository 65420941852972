<template>
  <div>
    <FormGroup id="type" v-model="form.type" label="Type" class="mb-0" type="select" :options="componentTypes" />
    <div v-if="form.type === 'text'">
      <FormGroup id="title" v-model="form.title" label="Title" class="mb-0" type="text" />
      <FormGroup id="content" v-model="form.content" label="Content" class="mb-0" type="textarea" />
    </div>
    <div v-if="form.type === 'table'">
      <FormGroup id="component" v-model="form.component" label="Component" class="mb-0" type="select" :options="tableComponents" />
    </div>
    <div v-if="form.type === 'chart'">
      <FormGroup id="component" v-model="form.component" label="Component" class="mb-0" type="select" :options="chartComponents" />
    </div>
  </div>
</template>
<script>
import FormGroup from '@/components/FormGroup';

import chartComponents from '@/views/Machine/templates/charts';
import tableComponents from '@/views/Machine/templates/tables';

export default {
  name: 'ReportBuilderComponentForm',
  components: {
    FormGroup
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      componentTypes: [
        { value: 'text', label: 'Text', form: { title: '', content: '' } },
        { value: 'table', label: 'Table', form: { component: 'Example', data: {} } },
        { value: 'chart', label: 'Chart', form: { component: 'Example', data: {} } }
      ],
      chartComponents: Object.keys(chartComponents).map(key => ({ value: key, label: key })),
      tableComponents: Object.keys(tableComponents).map(key => ({ value: key, label: key }))
    };
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>
