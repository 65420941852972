<template>
  <Chart svg :option="options" />
</template>
<script>
import { merge } from 'lodash';
import moment from 'moment';
import Chart from '@/components/echarts/Chart';

import chartOptions from './options';

export default {
  name: 'AccountHourlyConsumptionIntervals',
  components: {
    Chart
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    },
    weekdaysOnly: {
      type: Boolean,
      default: false
    },
    weekendsOnly: {
      type: Boolean,
      default: false
    },
    showAvg: {
      type: Boolean,
      default: false
    },
    showWeekdayAvg: {
      type: Boolean,
      default: false
    },
    showWeekendAvg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      const hoursArray = Array.from({ length: 48 }, (_, i) =>
        moment(this.period.startDate)
          .add(i * 30, 'minutes')
          .format('HH:mm')
      );

      const datesArray = Array.from({ length: moment(this.period.endDate).diff(this.period.startDate, 'days') + 1 }, (_, i) =>
        moment(this.period.startDate).add(i, 'days')
      );

      const series = datesArray
        .filter(date => {
          if (this.weekdaysOnly) {
            return date.isoWeekday() < 6;
          }

          if (this.weekendsOnly) {
            return date.isoWeekday() > 5;
          }

          return true;
        })
        .map((date, idx) => {
          const hourlyData = this.data.filter(item => moment(item.date).isSame(date, 'day'));

          const seriesItem = {
            type: 'line',
            name: date.format('DD/MM/YYYY (ddd)'),
            data: hourlyData.map(item => item.consumption),
            symbol: 'circle',
            symbolSize: 6
          };

          if (idx === 0) {
            seriesItem.markArea = {
              itemStyle: {
                color: 'rgba(0, 0, 0, 0.05)'
              },
              data: [
                [{ xAxis: '00:00' }, { xAxis: '07:00' }],
                [{ xAxis: '18:00' }, { xAxis: '23:30' }]
              ]
            };
          }

          return seriesItem;
        });

      if (this.showAvg) {
        const avgSeriesData = hoursArray.map((hour, idx) => {
          const hourlyData = series.map(item => item.data[idx]);

          return hourlyData.filter(val => val !== undefined && !Number.isNaN(val)).reduce((acc, val) => acc + val, 0) / hourlyData.length;
        });

        series.push({
          type: 'line',
          name: 'Average',
          data: avgSeriesData,
          symbol: 'square',
          symbolSize: 6,
          lineStyle: {
            type: 'dashed'
          }
        });
      }

      if (this.showWeekdayAvg) {
        const weekdayAvgSeriesData = hoursArray.map((hour, idx) => {
          const hourlyData = series.filter(item => moment(item.name, 'DD/MM/YYYY (ddd)').isoWeekday() < 6).map(item => item.data[idx]);

          return hourlyData.filter(val => val !== undefined && !Number.isNaN(val)).reduce((acc, val) => acc + val, 0) / hourlyData.length;
        });

        series.push({
          type: 'line',
          name: 'Weekday Average',
          data: weekdayAvgSeriesData,
          symbol: 'triangle',
          symbolSize: 6,
          lineStyle: {
            type: 'dashed'
          }
        });
      }

      if (this.showWeekendAvg) {
        const weekendAvgSeriesData = hoursArray.map((hour, idx) => {
          const hourlyData = series.filter(item => moment(item.name, 'DD/MM/YYYY (ddd)').isoWeekday() > 5).map(item => item.data[idx]);

          return hourlyData.filter(val => val !== undefined && !Number.isNaN(val)).reduce((acc, val) => acc + val, 0) / hourlyData.length;
        });

        series.push({
          type: 'line',
          name: 'Weekend Average',
          data: weekendAvgSeriesData,
          symbol: 'triangle',
          symbolSize: 6,
          lineStyle: {
            type: 'dashed'
          }
        });
      }

      const options = merge({}, chartOptions, {
        grid: {
          right: '220px'
        },
        xAxis: {
          name: 'Time',
          type: 'category',
          data: hoursArray,
          axisLabel: {
            rotate: 45
          },
          z: -1
        },
        yAxis: {
          name: this.units,
          type: 'value'
        },
        series,
        legend: {
          right: 0,
          orient: 'vertical',
          top: 'center',
          itemGap: -0.5,
          textStyle: {
            fontSize: 12
          }
        }
      });

      return options;
    }
  }
};
</script>
