<template>
  <div>
    <div class="alert alert-info">
      <strong><i class="fa fa-flask mr-1"></i>EXPERIMENTAL</strong> This feature was recently added and may have limited functionality. Report any
      issues or feedback using the question mark at the bottom right.
    </div>
    <div class="row">
      <div class="col-xl-5 col-lg-4">
        <div>
          <SectionTitle icon="fa-file-chart-pie">Report Builder</SectionTitle>
          <p class="mb-3">
            The <strong>Report Builder</strong> is where you can create professional, re-usable PDF reports containing graphs, tables and text. Once a
            report is designed, it is possible to schedule reports to be generated and sent out at selected intervals, as well as on-demand.
          </p>
        </div>

        <div class="bg-light rounded-md p-4 mb-3">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-1">Title Page</h5>
              <p class="font-size-sm mb-0">A nice looking title page for your report. This is optional.</p>
            </div>
            <div class="col-md-3 text-right">
              <div class="custom-control custom-checkbox">
                <input
                  id="form-titlePage-show"
                  v-model="form.titlePage.show"
                  type="checkbox"
                  class="custom-control-input"
                  name="form-titlePage-show"
                />
                <label class="custom-control-label" for="form-titlePage-show">Include</label>
              </div>
            </div>
          </div>
          <div v-if="form.titlePage.show" class="row border-top pt-3 mt-3">
            <div class="col-xl-9">
              <FormGroup id="title" v-model="form.titlePage.title" label="Title" class="mb-0" type="text" />
              <FormGroup id="subTitle" v-model="form.titlePage.subTitle" label="Sub-title" class="mb-0" type="text" />
            </div>
          </div>
        </div>

        <div v-for="(page, pageIdx) in form.pages" :key="pageIdx" class="page-form rounded-md py-2 px-3 mb-3">
          <div class="row">
            <div class="col">
              <h5 class="mb-3">Page {{ pageIdx + 1 }}</h5>
            </div>
            <div class="col-md-3 text-right">
              <button class="btn btn-sm btn-link text-danger font-w600" @click="modals.deletePage = pageIdx">
                <i class="fa fa-times fa-lg"></i>
              </button>
            </div>
          </div>

          <ReportBuilderPageForm :value="page" :index="pageIdx" @input="data => onUpdatePage(pageIdx, data)" />
        </div>

        <div class="row mb-3">
          <div class="col">
            <button class="btn btn-outline-primary btn-block" @click="onAddPage"><i class="fa fa-plus mr-1"></i> Add Page</button>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <button v-if="!generating" class="btn btn-primary btn-block" @click="onClickSave"><i class="fa fa-save mr-1"></i> Save Report</button>
            <button v-else class="btn btn-primary btn-block"><i class="fa fa-gear mr-1 fa-spin"></i> Saving...</button>
          </div>
        </div>
      </div>
      <div class="col-xl-7 col-lg-8">
        <div class="rounded-md bg-lighter py-4 px-5">
          <div class="text-center">
            <h4><i class="fa fa-search text-primary ml-1"></i> Preview</h4>
          </div>
          <ReportBuilderPreview :form="form" />
        </div>
      </div>
    </div>
    <ConfirmModal
      :open="modals.deletePage !== false"
      title="Delete PageonDeletePage"
      text="Are you sure you want to delete this page?"
      @submit="onDeletePage"
      @close="modals.deletePage = false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import ReportBuilderPageForm from '@/views/Report/components/ReportBuilderPageForm';
import ReportBuilderPreview from '@/views/Report/components/ReportBuilderPreview';
import SectionTitle from '@/components/base/SectionTitle';

export default {
  name: 'ReportBuilder',
  components: {
    ConfirmModal,
    FormGroup,
    ReportBuilderPreview,
    ReportBuilderPageForm,
    SectionTitle
  },
  data() {
    return {
      form: {
        titlePage: {
          show: false,
          title: '',
          subTitle: ''
        },
        pages: []
      },
      modals: {
        deletePage: false
      }
    };
  },
  computed: {
    ...mapGetters({
      accounts: 'account/accounts',
      assets: 'asset/assets',
      assetGroups: 'assetGroup/assetGroups',
      granularities: 'util/granularities',
      assetSchema: 'asset/schema',
      accountSchema: 'account/schema',
      assetGroupSchema: 'assetGroup/schema',
      readingSchema: 'reading/schema',
      consumptionSchema: 'consumption/schema',
      invoiceSchema: 'invoice/schema',
      supplierSchema: 'supplier/schema',
      templateLoadingAction: 'reportTemplate/loadingAction',
      templatesResults: 'reportTemplate/reportTemplates',
      systemTemplatesResults: 'reportTemplate/systemTemplates'
    })
  },
  async mounted() {},
  methods: {
    ...mapActions({
      listAccounts: 'account/list',
      listAssets: 'asset/list',
      listAssetsFromGroups: 'assetGroup/listAssets',
      listAssetGroups: 'assetGroup/list',
      listGranularities: 'util/listGranularities',
      getAccountSchema: 'account/schema',
      getAssetSchema: 'asset/schema',
      getAssetGroupSchema: 'assetGroup/schema',
      getReadingSchema: 'reading/schema',
      getConsumptionSchema: 'consumption/schema',
      getInvoiceSchema: 'invoice/schema',
      getSupplierSchema: 'supplier/schema',
      generateReport: 'report/generateReport2',
      createTemplate: 'reportTemplate/create',
      getTemplate: 'reportTemplate/get',
      listTemplates: 'reportTemplate/list',
      listSystemTemplates: 'reportTemplate/listSystem'
    }),
    async onAddPage() {
      this.form.pages.push({
        landscape: false,
        header: {
          show: false,
          title: '',
          subTitle: ''
        },
        components: []
      });
    },
    onUpdatePage(index, data) {
      this.$set(this.form.pages, index, data);
    },
    async onClickSave() {
      this.saving = true;
    },
    async onDeletePage() {
      this.form.pages.splice(this.modals.deletePage, 1);

      this.modals.deletePage = false;
    }
  }
};
</script>
<style scoped lang="scss">
.page-form {
  border: 2px dashed #e9ecef;
}
</style>
