<template>
  <table class="table table-sm table-borderless">
    <thead>
      <tr>
        <th></th>
        <th class="text-right">2023</th>
        <th class="text-right">2024</th>
        <th class="text-right">Variance</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="font-w600">Day Units</td>
        <td class="text-right">1,432</td>
        <td class="text-right">1,870</td>
        <td class="text-right">
          <PctDiff :value="1432" :prev-value="1870" comparison-period="year" hide-text />
        </td>
      </tr>
      <tr>
        <td class="font-w600">Night Units</td>
        <td class="text-right">1,432</td>
        <td class="text-right">1,870</td>
        <td class="text-right">
          <PctDiff :value="1432" :prev-value="1870" comparison-period="year" hide-text />
        </td>
      </tr>
      <tr>
        <td class="font-w600">Other Units</td>
        <td class="text-right">0.00</td>
        <td class="text-right">0.00</td>
        <td class="text-right">
          <PctDiff :value="0" :prev-value="0" comparison-period="year" hide-text />
        </td>
      </tr>
      <tr>
        <td class="font-w600">Available Capacity</td>
        <td class="text-right">0.00</td>
        <td class="text-right">0.00</td>
        <td class="text-right">
          <PctDiff :value="0" :prev-value="0" comparison-period="year" hide-text />
        </td>
      </tr>
      <tr>
        <td class="font-w600">Maximum Capacity</td>
        <td class="text-right">100</td>
        <td class="text-right">120</td>
        <td class="text-right">
          <PctDiff :value="100" :prev-value="120" comparison-period="year" hide-text />
        </td>
      </tr>
      <tr>
        <td class="font-w600">Load Factor</td>
        <td class="text-right">54.93%</td>
        <td class="text-right">23.49%</td>
        <td class="text-right">
          <PctDiff :value="54.93" :prev-value="23.49" comparison-period="year" hide-text />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import PctDiff from '@/components/analytics/PctDiff';

export default {
  name: 'Example',
  components: {
    PctDiff
  }
};
</script>
