var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "table table-sm table-borderless"
  }, [_vm._m(0), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Day Units")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("1,432")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("1,870")]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": 1432,
      "prev-value": 1870,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Night Units")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("1,432")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("1,870")]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": 1432,
      "prev-value": 1870,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Other Units")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("0.00")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("0.00")]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": 0,
      "prev-value": 0,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Available Capacity")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("0.00")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("0.00")]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": 0,
      "prev-value": 0,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Maximum Capacity")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("100")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("120")]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": 100,
      "prev-value": 120,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)]), _c('tr', [_c('td', {
    staticClass: "font-w600"
  }, [_vm._v("Load Factor")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("54.93%")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("23.49%")]), _c('td', {
    staticClass: "text-right"
  }, [_c('PctDiff', {
    attrs: {
      "value": 54.93,
      "prev-value": 23.49,
      "comparison-period": "year",
      "hide-text": ""
    }
  })], 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th'), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("2023")]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("2024")]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("Variance")])])]);
}]

export { render, staticRenderFns }