<template>
  <div>
    <div class="bg-light rounded-md p-4 mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-1">Page Header</h5>
          <p class="font-size-sm mb-0">A box at the top of the page with some information. Normally used when no title page is used.</p>
        </div>
        <div class="col-md-3 text-right">
          <div class="custom-control custom-checkbox">
            <input :id="`form-header-show${index}`" v-model="form.header.show" type="checkbox" class="custom-control-input" name="form-header-show" />
            <label class="custom-control-label" :for="`form-header-show${index}`">Include</label>
          </div>
        </div>
      </div>
      <div v-if="form.header.show" class="row border-top pt-3 mt-3">
        <div class="col-xl-9">
          <FormGroup :id="`form-header-title${index}`" v-model="form.header.title" label="Title" class="mb-0" type="text" />
          <FormGroup :id="`form-header-subTitle${index}`" v-model="form.header.subTitle" label="Sub-title" class="mb-0" type="text" />
        </div>
      </div>
    </div>

    <div class="bg-light rounded-md p-4 mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-1">Page Orientation</h5>
          <p class="font-size-sm mb-0">Some charts may display better in Landscape.</p>
        </div>
        <div class="col-md-3 text-right">
          <div class="custom-control custom-checkbox">
            <input :id="`form-landscape${index}`" v-model="form.landscape" type="checkbox" class="custom-control-input" name="form-landscape" />
            <label class="custom-control-label" :for="`form-landscape${index}`">Landscape</label>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(component, componentIdx) in form.components" :key="componentIdx" class="bg-light rounded-md p-4 mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-1">Component {{ componentIdx + 1 }}</h5>
          <p class="font-size-sm mb-0"></p>
        </div>
        <div class="col-md-1 text-right">
          <button class="btn btn-sm btn-link text-danger font-w600" @click="modals.deleteComponent = componentIdx">
            <i class="fa fa-times fa-lg"></i>
          </button>
        </div>
      </div>
      <div class="border-top pt-3 mt-3">
        <ComponentForm :value="component" :index="componentIdx" @input="data => onUpdateComponent(componentIdx, data)" />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <button class="btn btn-dark btn-block" @click="onAddComponent"><i class="fa fa-plus mr-1"></i> Add Component</button>
      </div>
    </div>
    <ConfirmModal
      :open="modals.deleteComponent !== false"
      title="Delete Component"
      text="Are you sure you want to delete this component?"
      @submit="onDeleteComponent"
      @close="modals.deleteComponent = false"
    />
  </div>
</template>
<script>
import FormGroup from '@/components/FormGroup';
import ConfirmModal from '@/components/ConfirmModal';
import ComponentForm from './ReportBuilderComponentForm';

export default {
  name: 'ReportBuilderPageForm',
  components: {
    ConfirmModal,
    ComponentForm,
    FormGroup
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      modals: {
        deleteComponent: false
      }
    };
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    onAddComponent() {
      this.form.components.push({
        type: 'text',
        title: '',
        content: '',
        component: 'Example',
        data: {}
      });
    },
    onDeleteComponent() {
      this.form.components.splice(this.modals.deleteComponent, 1);

      this.modals.deleteComponent = false;
    },
    onUpdateComponent(index, data) {
      this.$set(this.form.components, index, data);
    }
  }
};
</script>
