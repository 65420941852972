var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('Chart', {
    attrs: {
      "svg": "",
      "option": _vm.options
    }
  }) : _c('Spinner');
}
var staticRenderFns = []

export { render, staticRenderFns }