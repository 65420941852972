import AccountDailyConsumption from './AccountDailyConsumption.vue';
import AccountHourlyConsumptionIntervals from './AccountHourlyConsumptionIntervals.vue';
import AccountHourlyDemand from './AccountHourlyDemand.vue';
import AccountMonthlyConsumption from './AccountMonthlyConsumption.vue';

export default {
  Example: () => import('./Example.vue'),
  AccountDailyConsumption,
  AccountHourlyConsumptionIntervals,
  AccountHourlyDemand,
  AccountMonthlyConsumption
};
