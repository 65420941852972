<template>
  <div class="preview-page">
    <Pages preview>
      <Page
        v-if="form.titlePage.show"
        :title="form.titlePage.title || 'Consumption Report'"
        :sub-title="form.titlePage.subTitle || 'Cologne Retail Park'"
        description="March 2023 - March 2024"
      ></Page>
      <component :is="page.landscape ? 'PageLandscape' : 'Page'" v-for="(page, pageIdx) in form.pages" :key="pageIdx" no-header>
        <div class="etn-report">
          <div v-if="page.header.show" class="bg-lighter rounded-md mb-4 p-4">
            <div class="row align-items-middle">
              <div class="col-md-4">
                <PageHeader>
                  <template #top>March 2023 to March 2024</template>
                  <template #title>{{ page.header.title || 'Consumption Report' }}</template>
                  <template #bottom>{{ page.header.subTitle || 'Berlin Retail Park' }}</template>
                </PageHeader>
              </div>

              <div class="col-md-6">
                <div>
                  <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Company">Retail Parks Management Limited</PropertyListItem>
                  <PropertyListItem col-size="col-md-4" class="font-w600" title="Site">Berlin Retail Park</PropertyListItem>
                </div>
              </div>

              <!-- <div class="col-md-3">
                <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Period From">1st March 2023</PropertyListItem>
                <PropertyListItem col-size="col-md-4" class="font-w600" title="Period To">1st March 2024</PropertyListItem>
              </div> -->

              <div class="col-md-2 text-right">
                <img class="logo" :src="$auth.company.settings.reportLogoUrl || $auth.company.settings.logoUrl" />
              </div>
            </div>
          </div>
          <div v-for="(component, componentIdx) in page.components" :key="componentIdx" class="mb-4">
            <TextBox v-if="component.type === 'text'">
              <template #title>{{ component.title }}</template>
              <template>{{ component.content }}</template>
            </TextBox>
            <component
              :is="tableComponents[component.component]"
              v-if="component.type === 'table'"
              :data="exampleData.tables[component.component].data"
              :prev-data="exampleData.tables[component.component].prevData"
              :period="exampleData.tables[component.component].period"
              v-bind="exampleData.tables[component.component].props"
              unit="kWh"
            />
            <component
              :is="chartComponents[component.component]"
              v-if="component.type === 'chart'"
              :style="`height: ${exampleData.charts[component.component].height || '400px'}`"
              :data="exampleData.charts[component.component].data"
              :prev-data="exampleData.charts[component.component].prevData"
              :period="exampleData.charts[component.component].period"
              v-bind="exampleData.charts[component.component].props"
              unit="kWh"
            />
          </div>
        </div>
      </component>
    </Pages>
  </div>
</template>
<script>
import Page from '@/views/Machine/templates/components/Page';
import PageLandscape from '@/views/Machine/templates/components/PageLandscape';
import Pages from '@/views/Machine/templates/components/Pages';
import TextBox from '@/views/Machine/templates/components/TextBox';
import PageHeader from '@/views/Machine/templates/components/PageHeader';
import PropertyListItem from '@/components/base/PropertyListItem';

import chartComponents from '@/views/Machine/templates/charts';
import tableComponents from '@/views/Machine/templates/tables';

import exampleData from '../exampleData';

export default {
  name: 'ReportBuilderPreview',
  components: {
    Page,
    PageLandscape,
    Pages,
    PageHeader,
    PropertyListItem,
    TextBox
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chartComponents,
      tableComponents,
      exampleData
    };
  }
};
</script>
